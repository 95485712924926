<template>
  <div class="content">
    <div class="page-title">
      <h3 class="page-title" style="color:white;">Company Settings</h3>

      <!-- COMPANY META -->
      <div class="column is-full column-container">
        <div class="top-section columns">
          <div class="column is-half">
            <small class="is-pulled-left">Company Info</small>
          </div>
          <div class="column is-half">
            <button class="button is-info is-small is-pulled-right">
              Edit
            </button>
          </div>
        </div>

        <div class="columns is-12">
          <div class="column is-4">
            <small>Address:</small>
            <div class="highlight">
              {{ stored_company_meta.street_address }}
            </div>
            <small
              >{{ stored_company_meta.city }}, {{ stored_company_meta.state }}.
              {{ stored_company_meta.zip_code }}</small
            >
          </div>
          <div class="column is-4">
            <small>Company Name</small>
            <p class="highlight">{{ stored_company_meta.company_name }}</p>
            <small>Main Phone</small>
            <p class="highlight">{{ stored_company_meta.billing_phone }}</p>
          </div>
          <div class="column is-4">
            <small>NPI</small>
            <p class="highlight">{{ stored_company_meta.npi }}</p>
            <small>TIN</small>
            <p class="highlight">{{ stored_company_meta.tin }}</p>
          </div>
        </div>
      </div>

      <!-- FUNDING SOURCES -->
      <!-- <div class="column is-full column-container">
        <div class="top-section columns">
          <div class="column is-half">
            <small class="is-pulled-left">Funding Sources</small>
          </div>
          <div class="column is-half">
            <button class="button is-info is-small is-pulled-right">
              Add New
            </button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th width="15%">Name</th>
              <th width="15%">Funding Source ID</th>
              <th width="15%">Payer ID</th>
              <th>&zwnj;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in stored_funding_sources" :key="data.id">
              <td>{{ data.name }}</td>

              <td>{{ data.funding_source_id }}</td>

              <td>{{ data.payer_id }}</td>

              <td width="5%">
                <button class="button is-info is-small is-pulled-right">
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

         <!-- <div class="column is-full column-container">
        <div class="top-section columns">
          <div class="column is-half">
            <small class="is-pulled-left">Billing Codes</small>
          </div>
          <div class="column is-half">
            <button class="button is-info is-small is-pulled-right">
              Add New
            </button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th width="15%">Code</th>
              <th width="15%">Code Class</th>
              <th width="15%">Payer Desc</th>
              <th width="15%">Rate Class</th>
              <th>&zwnj;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in stored_funding_sources" :key="data.id">
              <td>{{ data.name }}</td>

              <td>{{ data.funding_source_id }}</td>

              <td>{{ data.payer_id }}</td>

              <td width="5%">
                <button class="button is-info is-small is-pulled-right">
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->


      

      <!-- INTEGRATIONS -->
      <div class="column is-full column-container">
        <div class="top-section columns">
          <div class="column is-half">
            <small class="is-pulled-left">Integrations</small>
          </div>
          <div class="column is-half">
            <!-- <button class="button is-info is-small is-pulled-right">
              Add New
            </button> -->

            <button
              @click="toggleDataVisiblity()"
              class="button is-danger is-light is-small is-pulled-right"
              style="margin-right: 5px"
            >
              Reveal Hidden
            </button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th width="15%">Name</th>
              <th width="15%">Used For</th>
              <th width="15%">Client ID</th>
              <th width="15%">Client Passphrase</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in stored_integrations" :key="data.id">
              <!-- Name -->
              <td>{{ data.name }}</td>

              <!-- Category -->
              <td v-if="data.category == 'billing'">Billing</td>
              <td v-else-if="data.category == 'accounting'">Accounting</td>
              <td v-else-if="data.category == 'payment_processing'">
                Payment Processing
              </td>
              <td v-else>Unknown Category</td>

              <!-- Client ID -->
              <td v-if="toggle_data_visible">{{ data.client_id }}</td>
              <td v-else>Hidden</td>

              <!-- Passphrase -->
              <td v-if="toggle_data_visible">{{ data.client_passphrase }}</td>
              <td v-else>Hidden</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

    <script>
import axios from "axios";
export default {
  name: "CompanySettings",

  mounted() {
    this.getCompanyMeta(); // bools to determine what fields to include in the form (example:  wait time, etc)
    this.getFundingSource();
    this.getIntegrations();
  },

  data() {
    return {
      stored_company_rate_classes: [],
      stored_company_meta: [],
      stored_funding_sources: [],
      stored_integrations: [],

      // New Entry
      rate_id: "",
      funding_source: "",
      load: 0,
      mile_charge: 0,
      wait_unit_charge: 0,

      toggle_data_visible: false,
    };
  },

  methods: {
    //

    toggleDataVisiblity() {
      console.log(this.toggle_data_visible);
      this.toggle_data_visible = !this.toggle_data_visible;
    },
    //

    async addIntegration() {
      this.$router.push("/forms/integration-form/");
    },
    //

    async createRateClass() {
      this.$router.push("/forms/rate-class-form/");
    },
    //

    async getCompanyMeta() {
      this.$store.commit("setIsLoading", true);

      axios
        .get("/api/v1/company_meta/")
        .then((response) => {
          this.stored_company_meta = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //
    async getFundingSource() {
      this.$store.commit("setIsLoading", true);

      axios
        .get("/api/v1/funding_sources/")
        .then((response) => {
          this.stored_funding_sources = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //

    async getIntegrations() {
      this.$store.commit("setIsLoading", true);

      axios
        .get("/api/v1/integrations/")
        .then((response) => {
          this.stored_integrations = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //
  },
  created() {
    document.title = "Company Settings";
  },
};
</script>


    <style scoped>
.content {
  top: 50px;
  font-size : .8em;
}

.highlight {
  font-weight: bold;
}

.column-container {
  margin: 5px;
  padding: 10px;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;

 /* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
}

select {
  text-align: center;
}

input {
  text-align: center;
  text-transform: uppercase;
}

.custom-control {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.top-section {
  height: 40px;
}

.text {
  display: block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
