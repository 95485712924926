<template>

  <div class="main-container">



  <div  v-if="user_is_manager_account === false" class="content">
    <div class="page-title">
      <div class="icon">
      </div>

    </div>

        <h4 style="color:white;">Trip Manager</h4>

        <div class="trips">

<router-link to="/forms/create-trip/" class="button is-info create-btn" >
  Create New
</router-link>

</div>
<br>

        <!-- Tab links -->
        <div class="tab">
          <button class="tablinks" id="defaultOpen" @click="openCity(event, 'ondeck')">On Deck</button>
          <button class="tablinks" id="tripOpen" @click="openCity(event, 'view')">Trip Detail</button>
        </div>

        <!-- Tab content -->
        <div id="ondeck" class="tabcontent">
          <div class="columns is-multiline">
      <div class="column is-12 custom-column">
        <table class="table table-style is-fullwidth">
          <thead>
            <tr>
              <th>Date of Service</th>
              <th>Member ID</th>
              <th>Rider Name</th>
              <th>Diagnosis</th>
              <th>Conf#</th>
              <th>PCS Expiry</th>
              <th>Date Created</th>
              <th>Created By</th>
              <th>&zwnj;</th>
            </tr>
          </thead>

          <tbody >
            <tr v-for="trip in trips" :key="trip.id">
              <td v-if="trip.active">{{ trip.date_of_service }}</td>
              <td v-if="trip.active">{{ trip.billing_id }}</td>
              <td v-if="trip.active">{{ trip.last_name }}, {{ trip.first_name }}</td>
              <td v-if="trip.active">{{ trip.diagnosis }}</td>
              <td v-if="trip.active">{{ trip.confirmation_number }}</td>
              <td v-if="trip.active">{{ trip.pcs_expiry }}</td>
              <td v-if="trip.active">{{ trip.created_at.substring(0,10) }} {{ trip.created_at.substring(11,16) }}</td>
              <td v-if="trip.active">{{ trip.created_by_username }}</td>
                
              <td v-if="trip.active">
                <a
                 
                  @click="viewTrip(trip.id)"
                  href="#"
                  class="button is-success is-small xsmall"
                  >View</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
        </div>

        <div id="view" class="tabcontent">
          <div class="review-form columns">
            
            <!-- Trip data -->
            <div class="column">
              <div class="pretty-cell">
                <span style="color: #ddd; font-weight: bold; font-size: 1.02em;">Trip Info</span> 
                <hr class="custom-hr">
                Date of Service: {{ review_trip.date_of_service }}
                <hr class="custom-hr-grey">
                Rider Name: {{ review_trip.last_name }}, {{ review_trip.first_name }}
                <hr class="custom-hr-grey">
                Member ID: {{ review_trip.billing_id }}
                <hr class="custom-hr-grey">
                Gender: {{ review_trip.gender }}
                <hr class="custom-hr-grey">
                Date of Birth: {{ review_trip.date_of_birth }}
                <hr class="custom-hr-grey">
                Phone: {{ review_trip.phone_number }}
                <hr class="custom-hr-grey">
                Conf#: {{ review_trip.confirmation_number }}
                <hr class="custom-hr-grey">
                Diagnosis: {{ review_trip.diagnosis }}
                <hr class="custom-hr-grey">
                PCS Expiry: {{ review_trip.pcs_expiry }}
                <hr class="custom-hr-grey">
                Notes: {{ review_trip.notes }}
                <br>
                
                
              </div>
      
            </div> 
            <!-- End trip data -->


            <!-- Ride data -->
            <div class="column">

              <div class="pretty-cell" v-for="(ride, index) in review_rides" :key="ride.id">

                   <span style="color: #ddd; font-weight: bold; font-size: 1.02em;">Ride Position #{{ index + 1 }}:</span> 
                   <hr class="custom-hr">
                    Pickup Time: {{ ride.pickup_time }}
                    <hr class="custom-hr-grey">
                    Dropoff Time: {{ ride.dropoff_time }}
                    <hr class="custom-hr-grey">
                    Pickup Street: {{ ride.pickup_street }}
                    <hr class="custom-hr-grey">
                    Pickup Unit: {{ ride.pickup_unit }}
                    <hr class="custom-hr-grey">
                    Pickup City: {{ ride.pickup_city }}
                    <hr class="custom-hr-grey">
                    Pickup State: {{ ride.pickup_state }}
                    <hr class="custom-hr-grey">
                    Pickup Zipcode: {{ ride.pickup_zipcode }}
                    <hr class="custom-hr-grey">
                    Dropoff Street: {{ ride.dropoff_street }}
                    <hr class="custom-hr-grey">
                    Dropoff Unit: {{ ride.dropoff_unit }}
                    <hr class="custom-hr-grey">
                    Dropoff City: {{ ride.dropoff_city }}
                    <hr class="custom-hr-grey">
                    Dropoff State: {{ ride.dropoff_state }}
                    <hr class="custom-hr-grey">
                    Dropoff Zipcode: {{ ride.dropoff_zipcode }}
                    <br>
                  </div>

            </div>

            
            <!-- End ride data -->


          </div>
        </div>




    <div class="page-title">
      <div class="icon">
      </div>

    </div>



 
  </div>



<!-- ############################## -->

<!-- MANAGER ACCOUNT TERMINAL BELOW -->

<!-- ############################## -->

<div  v-else class="content">
<div class="page-title">
  <div class="icon">
  </div>

</div>

    <h4 style="color:white;">Trip Manager</h4>
    <button class="button is-small is-info" @click="sendToDispatch()">Send to Dispatch</button>
    <div class="trips">
      {{  checkbox_trip_id }}


</div>
<br>

    <!-- Tab links -->
    <div class="tab">
      <button class="tablinks" id="defaultOpen" @click="openCity(event, 'ondeck')">On Deck</button>
      <button class="tablinks" id="tripOpen" @click="openCity(event, 'view')">Trip Detail</button>
    </div>

    <!-- Tab content -->
    <div id="ondeck" class="tabcontent">
      <div class="columns is-multiline">
  <div class="column is-12 custom-column">
    <table class="table table-style is-fullwidth">
      <thead>
        <tr>
          <th width="0.5%">
              <input type="checkbox" @click="selectAll" v-model="allSelected" />
          </th>
          <th >CID</th>
          <th>Date of Service</th>
          <th>Member ID</th>
          <th>Rider Name</th>
          <th>Approval Status</th>
          <th>Dispatched</th>
          <th>Date Created</th>
          <th>Created By</th>
          <th>&zwnj;</th>
          <th>&zwnj;</th>
          <th>&zwnj;</th>
          <th>&zwnj;</th>
          
        </tr>
      </thead>

      <tbody >
        <tr v-for="trip in admin_trips" :key="trip.id">
          <td>
            <input type="checkbox" :value="trip.id" v-model="checkbox_trip_id" @click="selectIndividualCheck" />
          </td>
          <td >{{ trip.date_of_service }}</td>
          <td >{{ trip.billing_id }}</td>
          <td >{{ trip.last_name }}, {{ trip.first_name }}</td>
          <td v-if="trip.approved">Approved</td>
          <td v-else-if="!trip.approved">Pending</td>
          <td v-if="trip.dispatched">Dispatched</td>
          <td v-else>Pending</td>
          <td >{{ trip.created_at.substring(0,10) }} {{ trip.created_at.substring(11,16) }}</td>
          <td >{{ trip.created_by_username}}</td>
            
          <td >
            <a
             
              @click="viewTrip(trip.id)"
              href="#"
              class="button is-info is-small xsmall"
              >View</a
            >
          </td>

           <td><a
            v-if="!trip.approved"
             @click="approveTrip(trip.id)"
             href="#"
             class="button is-success is-small xsmall"
             >Approve</a>

            <a v-else @click="pendingTrip(trip.id)" href="#" class="button is-dark is-small xsmall">Set Pending</a>
          </td>


           <td><a
             
             @click="rejectTrip(trip.id)"
             href="#"
             class="button is-warning is-small xsmall"
             >Reject</a
           ></td>

           <td><a
             
             @click="deleteTrip(trip.id)"
             href="#"
             class="button is-danger is-small xsmall"
             >Delete</a
           ></td>
           

        </tr>
      </tbody>
    </table>
  </div>
</div>
    </div>

    <div id="view" class="tabcontent">
      <div class="review-form columns">
        
        <!-- Trip data -->
        <div class="column">
          <div class="pretty-cell">
            <span style="color: #ddd; font-weight: bold; font-size: 1.02em;">Trip Info</span> 
            <hr class="custom-hr">
            Date of Service: {{ review_trip.date_of_service }}
            <hr class="custom-hr-grey">
            Rider Name: {{ review_trip.last_name }}, {{ review_trip.first_name }}
            <hr class="custom-hr-grey">
            Member ID: {{ review_trip.billing_id }}
            <hr class="custom-hr-grey">
            Gender: {{ review_trip.gender }}
            <hr class="custom-hr-grey">
            Date of Birth: {{ review_trip.date_of_birth }}
            <hr class="custom-hr-grey">
            Phone: {{ review_trip.phone_number }}
            <hr class="custom-hr-grey">
            Conf#: {{ review_trip.confirmation_number }}
            <hr class="custom-hr-grey">
            Diagnosis: {{ review_trip.diagnosis }}
            <hr class="custom-hr-grey">
            PCS Expiry: {{ review_trip.pcs_expiry }}
            <hr class="custom-hr-grey">
            Notes: {{ review_trip.notes }}
            <br>
            
            
          </div>
  
        </div> 
        <!-- End trip data -->


        <!-- Ride data -->
        <div class="column">

          <div class="pretty-cell" v-for="(ride, index) in review_rides" :key="ride.id">

               <span style="color: #ddd; font-weight: bold; font-size: 1.02em;">Ride Position #{{ index + 1 }}:</span> 
               <hr class="custom-hr">
                Pickup Time: {{ ride.pickup_time }}
                <hr class="custom-hr-grey">
                Dropoff Time: {{ ride.dropoff_time }}
                <hr class="custom-hr-grey">
                Pickup Street: {{ ride.pickup_street }}
                <hr class="custom-hr-grey">
                Pickup Unit: {{ ride.pickup_unit }}
                <hr class="custom-hr-grey">
                Pickup City: {{ ride.pickup_city }}
                <hr class="custom-hr-grey">
                Pickup State: {{ ride.pickup_state }}
                <hr class="custom-hr-grey">
                Pickup Zipcode: {{ ride.pickup_zipcode }}
                <hr class="custom-hr-grey">
                Dropoff Street: {{ ride.dropoff_street }}
                <hr class="custom-hr-grey">
                Dropoff Unit: {{ ride.dropoff_unit }}
                <hr class="custom-hr-grey">
                Dropoff City: {{ ride.dropoff_city }}
                <hr class="custom-hr-grey">
                Dropoff State: {{ ride.dropoff_state }}
                <hr class="custom-hr-grey">
                Dropoff Zipcode: {{ ride.dropoff_zipcode }}
                <br>
              </div>

        </div>

        
        <!-- End ride data -->


      </div>
    </div>


     

</div>



</div>

</template>

<script>
import axios from "axios";
import { generateToast } from '../../../common/toast';
import { CSRF_TOKEN } from "../../../common/csrf_token.js";


export default {

  name: "TripManager",
  data() {
    return {
      trips : '',
      admin_trips : '',
      review_trip : '',
      review_rides : '',
      user_is_manager_account : false,
      created_by_usernames : [],
      allSelected : false,
      checkbox_trip_id : '',

      headers: {
        headers: {
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: `Token ${this.$store.state.token}`,
        }
      },

    };
    
  },

  mounted() {
    this.checkUserPermission();
    this.getTrips();
    this.getAdminTrips();
    document.getElementById("defaultOpen").click();
  },

  created() {
    document.title = "Trip Manager";
  },

  methods: {
  //
    selectAll() {
      this.allSelected = !this.allSelected; // Set the opposite bool at start, but let default be false
      this.checkbox_trip_id = [];

      if (this.allSelected) {
        for (const t in this.admin_trips) {
          var trip_id = this.admin_trips[t].id;
          this.checkbox_trip_id.push(trip_id);
        }
      } else {
        this.checkbox_trip_id = [];
      }
    },

    //

    selectIndividualCheck() {
      this.allSelected = false;
    },

    async sendToDispatch() {
      const x = confirm("Please double check to make sure you have made the intended selection, do you want to proceed sending trips to dispatch?")
      if(x){
       this.$store.commit("setIsLoading", true)
      
      await axios
        .post("/api/v1/send-to-dispatch/", {'checkbox_trip_id' : this.checkbox_trip_id}, this.headers)
        .then((response) => {
          console.log("Send to Dispatch Response: ", response)
          this.$store.commit("setIsLoading", false)
          generateToast('Successfully sent selection to dispatch', 'is-success')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
          generateToast(`Failed to send selection to dispatch with error: ${error}`, 'is-danger')
        });
      }
 
    },

    //
    async compareDispatch() {
      this.$store.commit("setIsLoading", true)
      console.log('COMPARE DISPATCH')

     await axios
      .get("/api/v1/trans-sync-compare-records/")
      .then((response) => {
          console.log("X Response: ", response)
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });

      
    },

    //
    async pendingTrip(id) {
      const x = confirm("Are you sure you want to set this trip's approval to pending?")
      if (x) {
        this.$store.commit("setIsLoading", true)

        await axios
          .post(`/api/v1/pending-trip/`, { "id": id }, this.headers)
          .then((response) => {
            console.log("Pending Trip Response: ", response)
            this.$store.commit("setIsLoading", false)
            window.location.reload();
            this.$router.push("/dashboard/trip-manager/")
            document.getElementById("defaultOpen").click();
          })
          .catch((error) => {
            console.log(error)
            generateToast(error.response.data.message, "is-danger")
            this.$store.commit("setIsLoading", false)
          });
      }
    },

    //

    //
    async approveTrip(id) {
      const x = confirm("Are you sure you want to approve this trip request?")
      if (x) {
        this.$store.commit("setIsLoading", true)

        await axios
          .post(`/api/v1/approve-trip/`, { "id": id }, this.headers)
          .then((response) => {
            console.log("Approve Trip Response: ", response)
            this.$store.commit("setIsLoading", false)
            this.$router.push("/")
            window.location.reload();
            document.getElementById("defaultOpen").click();
          })
          .catch((error) => {
            console.log(error)
            generateToast(error.response.data.message, "is-danger")
            this.$store.commit("setIsLoading", false)
          });
      }
    },

    //

    //
    async rejectTrip(id) {
      const x = confirm("Are you sure you want to reject this trip request?")
      if (x) {
        this.$store.commit("setIsLoading", true)

        await axios
          .post(`/api/v1/reject-trip/`, { "id": id }, this.headers)
          .then((response) => {
            console.log("Reject Trip Response: ", response)
            this.$store.commit("setIsLoading", false)
            this.$router.push('/dashboard/trip-manager/')
            document.getElementById("defaultOpen").click();
          })
          .catch((error) => {
            console.log(error)
            generateToast(error.response.data.message, "is-danger")
            this.$store.commit("setIsLoading", false)
          });
      }
    },

    //

    //
    async deleteTrip(id){
      const x = confirm("Are you sure you want to delete this trip request? This is only for when mistakes are made.")
      if(x) {
        this.$store.commit("setIsLoading", true)

     await axios
      .delete(`/api/v1/delete-trip/${id}/`)
      .then((response) => {
          console.log("Delete Trip Response: ", response)
          this.$store.commit("setIsLoading", false)
          this.$router.push('/dashboard/trip-manager/')
          document.getElementById("defaultOpen").click();
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
      } 
    },

    //
    async checkUserPermission() {
      this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/check-permission/")
      .then((response) => {
          console.log("Permission Response: ", response)
          this.user_is_manager_account = response.data.has_permission
          this.$store.commit("setIsLoading", false)
          console.log(this.user_is_manager_account)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });

      
    },
    //
    async viewTrip(trip_id) {
      // Get trip and rides for trip detail tab
      this.$store.commit("setIsLoading", true)

      await axios
      .post(`/api/v1/get-trip-data/`, {"id" : trip_id}, this.headers)
      .then((response) => {
          console.log("Get Trip Data Response: ", response)
          this.review_trip = response.data.json.trip
          this.review_rides = response.data.json.rides
          this.$store.commit("setIsLoading", false)
          document.getElementById("tripOpen").click(); // open tab automatically if there is data
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });

    },
//

    openCity(evt,cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    // evt.currentTarget.className += " active";
  },

  //
  async getAdminTrips(){
    console.log(this.user_is_manager_account)

      this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/get-trips-admin-review/")
      .then((response) => {
          console.log("Admin Trips Response: ", response)
          this.admin_trips = response.data
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });


    },



    //
    async getTrips(){
      console.log('Getting trips...')
      this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/get-trips-review/")
      .then((response) => {
          console.log("Trips Response: ", response)
          this.trips = response.data
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });


    },
    //
        nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
//
    async getFundingSource() {
      this.$store.commit("setIsLoading", true);

      axios
        .get("/api/v1/funding_sources/")
        .then((response) => {
          this.stored_funding_sources = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
//
    async memberSearch() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(
          `/api/v1/member-search/?search=${this.search_query}&ordering=last_name`
        )
        .then((response) => {
          this.members = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },


    async getMembers() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/members/")
        .then((response) => {
          this.members = response.data;
          this.members = this.members;
          // console.log("Members: ", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
//
  },
};
</script>

<style scoped>
.content {
  width: 100%;
}

.custom-column{
  height: 800px;
  overflow: auto;
}

.table-style {
 /* From https://css.glass */
 font-size: 0.7em;
background: rgba(103, 103, 103, 0.2);
border-radius: 6px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
  overflow: auto;
  max-height: 40%;
}

.member-search {
  display: flex;
  width: 50%;
  margin: 0 auto;
}

.search {
  margin: 10px;
  text-align: center;
}

.page-title {
  width: 10%;
  display: flex;
  margin: 0 auto;
  text-align: center;
}

.page-title .members-title {
  padding-top: 2px;
}

table td {
  text-align: center;
}

table tr {
  text-align: center;
}

.edit-btn {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* Tooltip container */
.tooltip {
    margin-top: 3.3%;
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: grey;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  /* opacity: 0.8; */
  margin-right: 50px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 50px;
  left: -65px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}


/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #0033ff;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #0025ba;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #224eff;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 2px solid #000fdb;
  background-color: rgba(0, 0, 0, 0.4);
  border-top: none;
  overflow: auto;
  height: 800px;
}

.xsmall {
  height: 15px;
}

.pretty-cell{
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgb(0, 4, 255);
  border-radius: 4px;
  height: auto;
  width: auto;
  padding: 5px;
  text-align: left;
  margin: 5px;
  font-size: 0.8em
}

/* Style for the horizontal rule */
/* Style for the blue horizontal rule */
.custom-hr {
    border: none;
    background-color: blue;
    margin: 0;
    padding: 0;
    width: 100%; /* Full width */
  }

  .custom-hr-grey {
    border: none;
    background-color: rgb(33, 33, 33);
    margin: 0;
    padding: 0;
    width: 100%; /* Full width */
    height: 0.5px;
  }
</style>