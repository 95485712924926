<template>
  <div class="content">
    <div class="columns is-half">
      <div class="column is-half">
        <h3 class="page-title" style="color:white;">Update Existing Member</h3>
      </div>

      <div class="column is-half">
        <div class="is-small is-pulled-right">
          <button class="button is-dark" @click="backToMembers()">
            Back To Members
          </button>
          &zwnj; &zwnj;
          <button @click="deleteMember" class="button is-danger">Delete Member</button>
          &zwnj; &zwnj;
          <button @click="submitForm()" class="button is-info">Submit</button>
        </div>
      </div>
    </div>

    

    <form @submit.prevent="submitForm">
      <div class="columns create-form">
        <div class="column">
          
          <div class="field">
            <label for="billing_id">Billing ID</label>
            <div class="control filter" >
              <input
                type="text"
                name="billing_id"
                class="input"
                style="text-transform:uppercase"
                maxlength="50"
                v-model="billing_id"
              
              />
            </div>
          </div>
          

          <div class="field">
            <label for="first_name">First Name</label>
            <div class="control">
              <input
              disabled
                type="text"
                name="first_name"
                maxlength="50"
                class="input"
                v-model="first_name"
              />
            </div>
          </div>

          <div class="field">
            <label for="last_name">Last Name</label>
            <div class="control">
              <input
              disabled
                type="text"
                name="last_name"
                maxlength="50"
                class="input"
                v-model="last_name"
              />
            </div>
          </div>

          <div class="field">
            <label for="date_of_birth">Date of Birth</label>
            <div class="control">
              <input type="date" name="date_of_birth" v-model="date_of_birth" />
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label for="diag_code">Diagnosis Code</label>
            <div class="control">
              <input
                type="text"
                name="diag_code"
                maxlength="20"
                class="input"
                v-model="diag_code"
              />
            </div>
          </div>

          <div class="field">
            <label for="gender">Gender</label>
            <div class="control">
              <select
                class="select custom-control"
                name="gender"
                v-model="gender"
              >
                <option :value="1">Female</option>
                <option :value="0">Male</option>
              </select>
            </div>
          </div>

            <div class="field">
            <label for="funding_source">Funding Source Name <span style="color : yellow;">{{ funding_source }} </span> </label>
            <div class="control">
              <select
                class="custom-control"
                name="funding_source"
                v-model="funding_source"
                
              >
                <option
                  v-for="source in funding_sources"
                  :key="source.id"
                  :value="source"
                >
                  {{ source.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="field">
            <label for="owner" @click ="toggleOwnerVisible()">Owner</label>
            <div v-if="!toggle_owner_visible" class="control">
              <select
                
                disabled
                class="custom-control"
                name="funding_source"
                v-model="owner"
              >
                <option
                  v-for="user in users"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.username }}
                </option>
              </select>
            </div>
            
             <div v-if="toggle_owner_visible" class="control">
              <select
               @focusout="handleFocusOut"
                class="custom-control"
                name="funding_source"
                v-model="owner"
              >
                <option
                  v-for="user in users"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.username }}
                </option>
              </select>
            </div>
          </div>


          <div class="field">
            <label for="phone">Phone</label>
            <div class="control">
              <input
                name="phone"
                placeholder="Must be 10 digits"
                maxlength="10"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                class="input"
                v-model="phone"
              />
            </div>
          </div>
        </div>

        <div class="column">

          <div class="column is-3">
          <label for="notes">Notes</label>
          <textarea
            class="textarea notes"
            placeholder="Notes"
            name="notes"
            v-model="notes"
          ></textarea>
<br>
    <small>Member ID</small> 
    <p style="font-size: 0.7em;">{{member_id}}</p>
 
        </div>

        
        </div>
        
      </div>
    </form>
  </div>
</template>

    <script>
import axios from "axios";
import { toast } from "bulma-toast";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
import { generateToast } from ".../../../common/toast.js";

export default {
  name: "CreateMember",

  mounted() {
    this.getCompanyMeta(); // bools to determine what fields to include in the form (example:  wait time, etc)
    this.getMemberData(); // gets member data using update id from vuex store
    this.getFundingSource();
    this.GetUsers();

  },

  data() {
    return {
      billing_id: '',
      member_id: '', 
      first_name: '',
      last_name: '',
      diag_code: '',
      gender: '',
      phone: '',
      date_of_birth: '',
      notes: '',
      company_meta: '',
      errors: [],
      member_update_id : '',
      funding_sources : '',
      funding_source : null,
      users : [],
      owner : null,
      toggle_owner_visible : false,

      // Security
      headers : {headers:{"X-CSRFTOKEN": CSRF_TOKEN}}
    };
  },

  methods: {
    //
    handleFocusOut(){
      this.toggle_owner_visible = false;
    },
    //
    toggleOwnerVisible(){
      if(!this.toggle_owner_visible){
    const x = confirm("Are you sure you want to make owner editable?")
        if (x) {
        this.toggle_owner_visible = !this.toggle_owner_visible;
        }

      } else {
        this.toggle_owner_visible = !this.toggle_owner_visible;
      }
   

    },

        //
    async GetUsers() {
      await axios
        .get("/api/v1/user-list/")
        .then((response) => {
          console.log('Users: ', response.data);

        for(var i = 0; i < response.data.length; i++) {
          if (response.data[i].is_superuser == false) {
          this.users.push(response.data[i])
        }
        }
        })
 
        .catch((error) => {
          console.log(error);
        });

      
    },
    //
        async getFundingSource() {
       this.$store.commit("setIsLoading", true)
       this.fsloading = true;

     await axios
      .get("/api/v1/get-funding-sources/")
      .then((response) => {
          console.log("FS Response: ", response)
          this.funding_sources = response.data.content.Data
          this.$store.commit("setIsLoading", false)
          this.fsloading= false;
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
          this.fsloading= false;
        });


    },
    //
    async deleteMember(){
      this.$store.commit("setIsLoading", true)
      var x = confirm("This will permanently delete a member and cannot be undone, are you sure you want to do this?")
      if(x){
     await axios
      .delete(`/api/v1/update_member/${this.member_update_id}/`)
      .then((response) => {
          console.log("Delete Member Response: ", response)
          this.$store.commit("setIsLoading", false)
          generateToast(`Successfully deleted ${this.first_name} ${this.last_name}`, "is-success")
          this.$router.push('/dashboard/member')
      })
      .catch((error) => {
          console.log(error)
          generateToast(error, "is-danger")
          this.$store.commit("setIsLoading", false)
        });
      }
    },
    //
    async getMemberData() {
      let member_update_id = localStorage.getItem('member_update_id');
      console.log("member_update_id: ",member_update_id)
      this.member_update_id = member_update_id

      if (member_update_id){
        this.$store.commit("setIsLoading", true)
        

     await axios
      .get(`/api/v1/get_update_member/${member_update_id}/`)
      .then((response) => {
          console.log("Get Member Response: ", response.data)
          let data = response.data
          console.log(typeof(data.gender))
          this.billing_id = data.billing_id
          this.member_id = data.member_id
          this.first_name = data.first_name
          this.last_name = data.last_name
          this.diag_code = data.diag_code
          if (data.gender == true){this.gender=1}else{this.gender=0}
          this.phone = data.phone
          this.date_of_birth = data.date_of_birth
          this.notes = data.notes
          this.owner = data.created_by
          this.funding_source = data.funding_source_id
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
      }
    },
    //

    async submitForm() {

      const x = confirm("Are you sure you want to change this member? DOUBLE CHECK to make sure the owner and funding source are correct before continuing.")
      if(x) {
              this.$store.commit("setIsLoading", true);
      console.log('FUNDING SOURCE ID: ', this.funding_source.funding_source_id)

      if (
        this.$store.state.user.id &&
        this.billing_id &&
        this.first_name &&
        this.last_name &&
        this.diag_code &&
        this.phone &&
        this.date_of_birth &&
        this.funding_source &&
        this.owner &&
        !this.member_exists
        
      ) {
        const request = {
          billing_id: this.billing_id.toUpperCase(),
          full_name:
            this.first_name.toUpperCase() + " " + this.last_name.toUpperCase(),
          first_name: this.first_name.toUpperCase(),
          last_name: this.last_name.toUpperCase(),
          date_of_birth: this.date_of_birth,
          diag_code: this.diag_code.toUpperCase(),
          gender:this.gender,
          phone: this.phone,
          notes: this.notes,
          funding_source_id : this.funding_source.funding_source_id,
          funding_source_name : this.funding_source.name,
          created_by: this.owner
        };
        // console.log("REQUEST: ", request);

        await axios
          .patch(`/api/v1/update_member/${this.member_update_id}/`, request, this.headers)

          .then((response) => {
            console.log(response);
            toast({
          message: `Member Updated.`,
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: "top-center",
          opacity: 0.9,
        });
        this.$router.push('/dashboard/member/')
            
          })
          .catch((error) => {
  
            toast({
          message: `Submission failed: ${error}`,
          type: "is-danger",
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: "top-center",
          opacity: 0.9,
        });
            console.log(error);

          });
      } else {
        if (!this.$store.state.user.id) {
          this.errors.push(
            "User is not logged in, please sign out, and log back in."
          );
        }

        if (this.member_exists) {
          this.errors.push("Member Already Exists.");
        }
        if (!this.billing_id) {
          this.errors.push("Missing Billing ID.");
        }
        if (!this.first_name) {
          this.errors.push("Missing First Name.");
        }
        if (!this.last_name) {
          this.errors.push("Missing Last Name.");
        }
        if (!this.date_of_birth) {
          this.errors.push("Missing Date of Birth.");
        }
        if (!this.diag_code) {
          this.errors.push("Missing Diagnosis Code.");
        }
          if (!this.$store.state.user.id) {
            this.errors.push(
              "*****User ID is invalid, or missing from the store."
            );
          }
        
        if (!this.phone || this.phone.length < 10) {
          this.errors.push(
            "Missing Phone Number, or Phone Number is less than 10 digits."
          );
        }

        console.log("Form Errors: ", this.errors);
        toast({
          message: `*** FORM ERRORS *** ${this.errors}`,
          type: "is-danger",
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: "top-center",
          opacity: 0.9,
        });
        this.errors = [];
      }

      this.$store.commit("setIsLoading", false);
      }
    },
    //
    backToMembers() {
     
        this.$router.push("/dashboard/member/");
 
    },

    //
    async getCompanyMeta() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/company_meta/")
        .then((response) => {
          this.company_meta = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },

  },

  created() {
    document.title = "Edit Member";
  },

};
</script>


    <style scoped>
input {
  text-align: center;
}

select {
  text-align: center;
}

select .submit-btn {
  width: 20px;
  margin: 0 auto;
}

.content {
  top: 50px;
  left: 15%;

}

label  {
  margin-top: 10px;
}

/* DATE PICKER BELOW */

[type="date"] {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* custom styles */
body {
  padding: 4em;
  background: #e5e5e5;
}
label {
  display: block;
}
input {
  text-transform: uppercase;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
  height: 45px;
}

.gender {
  margin-bottom: 15px;
}

.create-form {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
}

.custom-control {
  width: 190px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.notes {
  min-width: 180px;
  max-width: 180px;
}
</style>

