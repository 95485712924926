<template>
  <div class="content">
    <h2 style="color: white;">Address Book</h2>
    <div class="columns custom-control">
      <div class="column left">
        <div class="search-by-name">
                
                <div class="search-container">

                <Dropdown_AddressBook
                  :options="addresses"
                  v-on:filter="getDropdownValues"
                  :disabled="false"
                  name="zipcode"
                  :maxItem="10000000"
                  placeholder="Please select an option">
              </Dropdown_AddressBook>
                </div>

        </div>

      </div>
      <div class="column middle"></div>

      <!-- EDI UPLOAD FORM -->
      <div class="column right">
  
          <!-- this is the far right section of the div -->

      </div>

    </div>

    <br>
    <br>
    <br>

    <div class="address-form">

      <small>Street Address</small>
      <input class="input" type="text" id="inputField" v-model="street_address" @input="convertStreetToUpper" maxlength="35">
      <br>
      <small>Zip Code</small>
      <input class="input" type="text" id="numbers" v-model="zip_code" @input="zipCodeFilterInput" maxlength="5">

      <small>Location Indicator</small>
      <input class="input" type="text" v-model="location_code" @input="convertLocToUpper" maxlength="1">
      <br>

<button class="button is-small is-primary" @click="saveAddress">Save</button>

    </div>

    
  </div>
</template>

<script>
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
import { generateToast } from ".../../../common/toast.js";
import FileReader from "@/components/FileReader.vue";
import { convertDates } from ".../../../common/converters.js";
import Dropdown_AddressBook from "@/components/Dropdown_AddressBook.vue";



export default {
  components: { Datepicker, FileReader, Dropdown_AddressBook },

  name: "Claims",
  data() {
    return {
      data: "",
      toggle : true,
      claims: [],
      rides : [],
      services : [],
      service_claim: [],
      street_address_query: "",
      search_query: null,
      funding_sources: [],
      funding_source: "",
      date: "",
      busy: false,
      allSelected: false,
      total_value : 0,
      checkbox_claim_id: [],
      queried_address : '',
      addresses : '',
      address_id : '',
      street_address :  '',
      zip_code : '',
      location_code : '',

      zip_code_value : '',
      street_address_uppercase : '',

      headers: {
        headers: {
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: `Token ${this.$store.state.token}`,
        },
      },
    };
  },

  mounted() {
    this.getAddresses()
  },

  created() {
    document.title = "Address Book";
  },

  methods: {
    //
    async saveAddress() {
      this.$store.commit("setIsLoading", true)
      const errors = []
      if (!this.street_address){
        errors.push('Please add a street address')
      }
      if (this.zip_code.length !== 5){
        errors.push('Please add a zip code, it must be 5 characters')
      }
      if (this.location_code.length !== 1){
        errors.push("Please add a location code, it must be 1 character")
      }
      

      if (errors.length == 0){

      await axios
        .post("/api/v1/save-address/",
          {
            "id" : this.address_id,
            "street_address": this.street_address,
            "zip_code": this.zip_code,
            "location_code": this.location_code
          },
          this.headers)
        .then((response) => {
          console.log("X Response: ", response)
          this.object = response.data
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });

    } else {
      generateToast(errors, "is-warning")
    }

    },
    //
    convertLocToUpper() {
      this.location_code = this.location_code.replace(/[^A-Za-z]/g, '');
      this.location_code = this.location_code.toUpperCase();
    },

    //
    convertStreetToUpper() {
      this.street_address = this.street_address.toUpperCase();
    },
    //
  
    zipCodeFilterInput() {
      this.zip_code_value = this.zip_code_value.replace(/[^0-9]/g, '');
    },
    //
    getDropdownValues(value){
      // Search for the address at dropdown selection
        // gets the value from the dropdown
        this.search_query = value.split(" __ID:")
        this.address_id = parseInt(this.search_query[1])
        if(this.address_id && this.address_id !== undefined && this.address_id !== null) {
          console.log("This is the value: ", value)
          this.searchAddress() // get selected address from db by id

          console.log("Address ID: ",this.address_id)
          

        }

        
      },
//

    async getAddresses() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/all-addresses/")
        .then((response) => {
          console.log("Address Response: ", response);
          this.addresses = response.data;
          console.log("Addresses: ", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
//
async searchAddress(){

  this.$store.commit("setIsLoading", true)

     await axios
      .get(`/api/v1/address-book-search/?address_id=${this.address_id}`)
      .then((response) => {
          console.log("Address Book Response: ", response)
          const res = response.data[0]
          this.street_address = res.street_address
          this.zip_code = res.zip_code
          this.location_code = res.location_code
          console.log("Street address: ", this.street_address)
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });


},


    //



    onSubmit() {
      console.log(this.form);
      // post the form to the server
    },

    async deleteClaims(){
      if (!this.busy){
        this.busy=true
      var x = confirm("This action cannot be undone, are you sure you want to do this?")
      if(x){
      this.$store.commit("setIsLoading", true)
      if(this.checkbox_claim_id.length > 0){

     await axios
      .post("/api/v1/delete-claims/", this.checkbox_claim_id, this.headers)
      .then((response) => {
          console.log("Delete Claims Response: ", response)
          generateToast(`${response.data.message}`, "is-success")
          this.$store.commit("setIsLoading", false)
          this.busy = false
          setTimeout(this.$router.go, 3000);
          
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
          this.busy = false
        });
      } else {
        generateToast("Please select a claim to delete.", "is-warning")
         this.$store.commit("setIsLoading", false)
         this.busy = false
      }
      } else{
        this.busy =false
      }
      } else {
        generateToast("System is busy, please wait...", "is-warning")
        this.busy = false
      }

    },


    modifierSet(modifier_set) {
    let codes = []
    const new_modifier_set = Object.assign({}, modifier_set);
    for (const [key, value] of Object.entries(new_modifier_set)) {
      console.log(`${key}: ${value}`);
      codes.push(value)
    }

    let final_codes = codes.toString()
    return final_codes
   
    },

       //
    searchClaims() {
      if(!this.busy) {
        if (this.funding_source){
        this.busy = true;
        this.toggle=true;
      
      if (this.date && this.date.length == 2) {
        this.$store.commit("setIsLoading", true);

        let start_date = convertDates(this.date[0]);
        let end_date = convertDates(this.date[1]);

        let url = `/api/v1/claims-by-date/?min_date=${start_date}&max_date=${end_date}&funding_source_id=${this.funding_source}`;
        console.log(url);
        axios
          .get(url)
          .then((response) => {
            this.claims = response.data;
            console.log(response.data);
            this.$store.commit("setIsLoading", false);
            this.busy = false;

          // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
          this.service_claim = []
          for(var i = 0; i < response.data.length; i++){
            this.getAllServices(response.data[i].id)
            console.log(this.service_claim)
          }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
            this.busy = false;
          });

        
      } else {
        generateToast("Please select dates.", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
      } else{
        generateToast("Please select a funding source.", "is-warning")
      }
      } else {
        generateToast("System is busy, please wait...", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
      
    },
    //
    async reviewClaim(claim_id){
      this.toggle=false
      console.log(claim_id)
      await this.getServices(claim_id)
      await this.getRides(claim_id)
    },
    //
     async getRides(claim_id) {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/claim-rides/?claim_id=${claim_id}`)
        .then((response) => {
          console.log("Rides Response: ", response);
          this.rides = response.data;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
//
    async getLatestClaims() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/latest_claims/")
        .then((response) => {
          console.log("Latest Claims Response: ", response);
          this.claims = response.data;
          this.$store.commit("setIsLoading", false);

          // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
          this.service_claim = []
          for(var i = 0; i < response.data.length; i++){
            this.getAllServices(response.data[i].id)
            console.log(this.service_claim)
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },

    //
    async syncClaimStatus() {
      this.$store.commit("setIsLoading", true);
      let data = { ids: this.checkbox_claim_id };
      await axios
        .post("/api/v1/sync-claims/", data, this.headers)
        .then((response) => {
          console.log("Sync Claims Response: ", response);
          this.claims = response.data;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    async searchByName() {
      if (!this.busy) {
        if(this.funding_source){
        this.busy = true;
        this.toggle = true;
        this.$store.commit("setIsLoading", true);

        axios
          .get(
            `/api/v1/claims-by-name/?search=${this.search_term}&funding_source_id=${this.funding_source}`
          )
          .then((response) => {
            console.log("Search By Name Response: ", response);
            this.claims = response.data;
            this.$store.commit("setIsLoading", false);
            this.busy = false;

            // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
          this.service_claim = []
          for(var i = 0; i < response.data.length; i++){
            this.getAllServices(response.data[i].id)
            console.log(this.service_claim)
          }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
          } else{
        generateToast("Please select a funding source.", "is-warning")
      }
      } else {
        generateToast("System is busy, please wait...", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
      
    },
//
    async getFundingSource() {
       this.$store.commit("setIsLoading", true);
       this.fsloading = true;

     await axios
      .get("/api/v1/get-funding-sources/")
      .then((response) => {
          console.log("FS Response: ", response)
          this.funding_sources = response.data.content.Data
          this.$store.commit("setIsLoading", false)
          this.fsloading = false;
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
          this.fsloading = false;
        });


    },
//

    async getServices(claim_id){
       this.$store.commit("setIsLoading", true)

     await axios
      .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
      .then((response) => {
          console.log("Services Response: ", response)
          this.services = response.data

          var count = 0.0
          for (var i = 0; i < response.data.length; i++) {
            count += response.data[i].charge
          }
          this.total_value = count
       
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });


    },
//
    getTotal(claim_id){
      // Loop to find a matching claim id in service_claim, if match found, return value.
      for (var i = 0; i < this.service_claim.length; i++) {
        if(this.service_claim[i]['claim_id'] == claim_id){
          return this.service_claim[i]['total'].toFixed(2)
        }
      }
    },
//
  getAllServices(claim_id){
      // Gets services and organizes them for getTotal to call in the template and retrieve total for each claim
      this.$store.commit("setIsLoading", true)

      axios
      .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
      .then((response) => {

          var count = 0.0
          for (var i = 0; i < response.data.length; i++) {
            count += response.data[i].charge
          }
          this.$store.commit("setIsLoading", false)

          this.service_claim.push({ "claim_id" : claim_id, "total": count})
       

      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });


    },

    //
    selectAll() {
      this.allSelected = !this.allSelected; // Set the opposite bool at start, but let default be false
      this.checkbox_claim_id = [];

      if (this.allSelected) {
        for (const r in this.claims) {
          var claim_id = this.claims[r].id;
          this.checkbox_claim_id.push(claim_id);
        }
      } else {
        this.checkbox_claim_id = [];
      }
    },

    selectIndividualCheck() {
      this.allSelected = false;
    },
  },
  //

  
};
</script>

<style scoped>
.controls {
  width: 80%;
  margin: 0 auto;
}

.content {
  width: 100%;
  top: 50px;
}

.table-container {
  width: 100%;
  height: 600px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 0.9em;
}

.select-control {
  height: 42px;
}

.custom-select {
  height: 38px;
  width: 100%;
  margin-right: 5px;
  border: 1px solid #ddd;
}

.really-small {
  font-size: 0.7em;
}

.custom-control {
/* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
  height: 220px;
  margin-bottom: 15px;
  padding: 10px;
  text-align: center;
}

.table-style {
  /* From https://css.glass */
/* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
  overflow: auto;
  max-height: 500px;
  font-size: 0.8em;
}

table td {
  text-align: center;
}

table tr {
  text-align: center;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-form {
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  width: 250px;
}

/* Tooltip container */
/* .tooltip-patch {
  margin-top: -10px; 
  margin-left: 5px;


} */

.middle-btn {
  text-align: center;

  margin-top: 5px;
  margin-bottom: 5px;
}

.review {
  padding: 10px;
  font-size :.7em;
  color: white;
/* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
}

.content table th {
  color: white;
}

tr:hover td {
  background:blue;
}

table {
        border-collapse: collapse;
        width: 100%;
    }
    th {
        background-color: black;
        position: sticky;
        top: 0px;
        z-index: 1; /* Add z-index */
        
    }
    th, td {
        text-align: left;
        padding: 8px;
        border: 2px solid red;
    }

    .address-form{
      width: 25%;
    }

</style>