<template>
  <div class="content">

    <div class="page-title">
      <div class="icon">
      </div>

    </div>

        <h4 style="color:white;">Members</h4>
    <div class="member-search">
      <div class="tooltip">
        <span class="material-icons" style="color: grey">help</span>
        <span class="tooltiptext"
          >Search by: First Name, Last Name, Billing ID, Birth Date, and Diagnosis Code</span
        >
      </div>
      <input
      
        type="text"
        class="input search"
        placeholder="Search"
        @keydown.enter="memberSearch"
        @keydown="nameKeydown($event)"
        v-model="search_query"
      />
      <router-link to="/forms/create-member/" class="button is-info create-btn" style="margin-top: 1.8%">
        Create New
      </router-link>
      
      <router-link to="/dashboard/unregistered-member/" class="button is-warning create-btn" style="margin-top: 1.8%; margin-left:5px;">
        Uknown Members
      </router-link>

    </div>
    <div class="columns is-multiline">
      <div class="column is-12 custom-column">
        <table class="table table-style is-fullwidth">
          <thead>
            <tr>
              <th>Member Name</th>
              <th>Date of Birth</th>
              <th>Billing ID</th>
              <th>Diagnosis Code</th>
              <th>Funding Source</th>
              <th>Date Added</th>
              <th>Last Updated</th>
              <th>&zwnj;</th>
            </tr>
          </thead>

          <tbody >
            <tr v-for="member in members" :key="member.id">
              <td v-if="member.active">{{ member.last_name }}, {{ member.first_name }}</td>
              <td v-if="member.active">{{ member.date_of_birth }}</td>
              <td v-if="member.active">{{ member.billing_id }}</td>
              <td v-if="member.active">{{ member.diag_code }}</td>
              <td v-if="member.active">{{ member.funding_source_name }}</td>
              <td v-if="member.active">{{ member.created_at.substring(0,10) }}</td>
              <td v-if="member.active">{{ member.last_updated.substring(0,10) }}</td>

             <!-- <td v-if="!member.funding_source_name && member.active">{{member.funding_source_id}}</td>
             <td v-else>{{member.funding_source_name}}</td> -->
                
              <td v-if="member.active">
                <a
                 
                  @click="editMember(member.id)"
                  href="#"
                  class="button is-success is-small"
                  >Edit</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {

  name: "Member",
  data() {
    return {
      members: [],
      search_query: null,
      stored_funding_sources : []
    };
  },

  mounted() {
    this.getMembers();
    this.getFundingSource();
  },

  created() {
    document.title = "Members";
  },

  methods: {
    //
        nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
//
    async getFundingSource() {
      this.$store.commit("setIsLoading", true);

      axios
        .get("/api/v1/funding_sources/")
        .then((response) => {
          this.stored_funding_sources = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
//
    async memberSearch() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(
          `/api/v1/member-search/?search=${this.search_query}&ordering=last_name`
        )
        .then((response) => {
          this.members = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
//
    editMember(uid) {
      localStorage.setItem('member_update_id', uid)
      this.$router.push('/forms/update-member')
    },
//

    async getMembers() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/members/")
        .then((response) => {
          this.members = response.data;
          this.members = this.members;
          // console.log("Members: ", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
//
  },
};
</script>

<style scoped>
.content {
  width: 100%;
}

.custom-column{
  height: 800px;
  overflow: auto;
}

.table-style {
 /* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
  overflow: auto;
  max-height: 40%;
}

.member-search {
  display: flex;
  width: 50%;
  margin: 0 auto;
}

.search {
  margin: 10px;
  text-align: center;
}

.page-title {
  width: 10%;
  display: flex;
  margin: 0 auto;
  text-align: center;
}

.page-title .members-title {
  padding-top: 2px;
}

table td {
  text-align: center;
}

table tr {
  text-align: center;
}

.edit-btn {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* Tooltip container */
.tooltip {
    margin-top: 3.3%;
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: grey;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  /* opacity: 0.8; */
  margin-right: 50px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 50px;
  left: -65px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>