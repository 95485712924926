<template>
    <div class="content">
      <h2 style="color: white;">Resubmit Claims</h2>
      <div class="columns custom-control">
        <div class="column left">
          <div class="fs-container">
            <small>Select Funding Source</small>
            <select class="select custom-select" v-model="funding_source">
              <option
                :value="fs.funding_source_id"
                v-for="fs in funding_sources"
                :key="fs.id">
                {{ fs.name }}
              </option>
            </select>
          </div>
  
          <div>
            <small>Search Date Range</small>
            <Datepicker
              v-model="date"
              range
              :partialRange="false"
              :enableTimePicker="false"
              :maxRange="31"
            />
          </div>
  
          <!-- <div class="search-by-name">
            <small>Search By Name</small>
            <input
              type="text"
              class="input search-by-name"
              maxRange="25"
              placeholder="Search By Name"
              v-model="search_term"
            />
          </div> -->

          <div>
          
          </div>
  
        </div>

       <button class="button is-info" @click="searchClaims()"> Search Date Range</button>
       <!-- <button class="button is-info" @click="searchByName()"> Search Search By Name</button> -->

        <div class="column middle"></div>
  
        <!-- EDI UPLOAD FORM -->
        <div class="column right">
    
  
          <!-- DELETE CLAIMS -->
          <!-- <div class="middle-btn">
            <button
            v-if="toggle"
              @click="deleteClaims()"
              class="button is-small is-danger is-rounded"
            >
              Delete Selected
            </button>
          </div> -->
        </div>
  
      </div>
  
      <div class="columns is-multiline">
        <div class="column is-fullwidth">
          <div v-if="toggle" class="table-container">
            <table class="table table-style is-fullwidth">
              <thead>
                <tr style="font-size: 0.8em">
                  <th width="3%">
                    <input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                    />
                  </th>
                  <th width="3%">Member Name</th>
                  <th width="3%">Service Date</th>
                  <th width="3%">Charge Amount</th>
                  <th width="4%">Submitted Amount</th>
                  <th width="3%">Paid Amount</th>
                  <th width="4%">Claim Status</th>
                  <th width="1%">Tracking</th>
                  <th width="1%">Resub</th>
                </tr>
              </thead>
  
              <tbody>
                <tr v-for="claim in claims" :key="claim.id">
                  <td>
                    <input
                      type="checkbox"
                      :value="claim.id"
                      v-model="checkbox_claim_id"
                      @click="selectIndividualCheck"
                    />
                  </td>
  
                  <!-- Member name -->
                  <td class="truncate">
                    {{ claim.subscriber_last_name }},
                    {{ claim.subscriber_first_name }}
                    
                  </td>
  
                  <!-- Service date -->
                  <td class="truncate">{{ claim.encounter_start_date_of_service }}</td>
  
                  <!-- Charge amount -->
                  <td>${{ getTotal(claim.id) }}</td>
                  <!-- Submitted amount -->
                  <td>${{ claim.submitted_amount }}</td>
  
                   <!-- Paid amount -->
                  <td>${{ claim.paid_amount }}</td>
  
  
                 
                  <!-- <td v-if="claim.complete && claim.billed" style="color: green">Complete</td>
                  <td v-else-if="claim.in_process" style="color: orange">
                    Incomplete
                  </td>
                  <td v-else-if="claim.denied" style="color: red">Denied</td> -->
  
                  <!-- Claim status -->
                  <td class="truncate" v-if="!claim.billed">Ready</td>
                  <td class="truncate" v-else-if="claim.short && claim.resubmitted"> <span style="color:orange;">Short</span>  <span style="color: yellow;">(R)</span></td>
                  <td class="truncate" v-else-if="claim.denied && claim.resubmitted" > <span style="color: red;" >Denied</span>  <span style="color:yellow;">(R)</span> </td>
                  <td class="truncate" v-else-if="claim.denied" style="color: red;">Denied</td>
                  <td class="truncate" v-else-if="claim.short" style="color: orange;">Short</td>
                  <td class="truncate" v-else-if="claim.complete" style="color: #34eb3d;">Paid</td>
                  <td class="truncate" v-else-if="claim.resubmitted" style="color: orangered;">Resubmitted</td>
                  <td class="truncate" v-else style="font-weight: bold; color: skyblue;">In Process</td>
                  
                  

                  <td v-if="claim.rh_claim_number">{{ claim.rh_claim_number }}</td>
                  <td v-else>None</td>
  
                  <!-- Review -->
                  <td>
                    <a
                    @click="reviewClaim(claim.id)"
                      href="#"
                      class="button is-small is-info"
                      style="height: 20px"
                    >
                      Resub</a
                    >
                  </td>
  
  
                </tr>
              </tbody>
            </table>
  
            
          </div>

       
          <div v-else class="review">
            <div>
                <select class="select" v-model="selected_ride_id" style="margin-right: 5px;">
                    <option v-for="ride in rides" :key="ride.id" :value="ride.id">{{ ride.id }}</option>
                </select>
                <button @click="editRide()" class="button is-small is-dark" style="margin:2px;"> View </button>
                <h4 style="margin: 0 auto; color:white; width: 30px;">Rides</h4>
              <table>
                <thead>
                  <tr style="font-size: 0.8em">
                    <th width="5%">Ride ID</th>
                    <th width="5%">Ride Hash</th>
                    <th width="10%">Member Name</th>
                    <th width="10%">Service Date</th>
                    <th width="10%">Pickup Time</th>
                    <th width="20%">Pickup</th>
                    <th width="20%">Dropoff</th>
                    <th width="5%">Location Mod</th>
                  </tr>
                </thead>

                
  
                <tbody>
                  <!-- MEMBER NAME -->
                  <tr v-for="ride in rides_databank" :key="ride.id">

                    <!--  ID -->
                     <td>{{ ride.id }}</td>

                     <td>{{ ride.ride_id.split('-')[0].toUpperCase() }}</td>
  
                    <td>{{ ride.first_name }} {{ ride.last_name }}</td>


                    <!-- SERVICE DATE -->
                    <td>{{ ride.date_of_service }}</td>

                    <td>{{ ride.pickup_timestamp.split('T')[1].substring(0,5)}}</td>
  
                    <!-- PICKUP -->
                    <td>{{ ride.pickup_street }} {{ ride.pickup_zip }}</td>
  
                    <!-- DROPOFF -->
                    <td>{{ ride.dropoff_street }} {{ ride.dropoff_zip }}</td>
  
                    <!-- LOCATION -->
                    <td>{{ ride.pickup_modifier }}{{ ride.dropoff_modifier }}</td>

                  </tr>
                </tbody>
              </table>
                <hr>
                <div style="margin-top: 5px;">
                <button class="button is-info is-small" style="margin:2px;" @click="toggle=true, ride_to_edit_toggle = false">Return to Claims</button>
                
                <button class="button is-success is-small" style="margin:2px;" @click="resubmit()">Resubmit</button>
                </div>

            </div>
            <br>
            <div v-if="ride_to_edit_toggle" class="columns top-form">

                <!-- COLUMN A -->
                <div class="column is-one-quarter">
                    <label>First Name</label><br>
                    <input v-model="ride_to_edit.first_name" class="input edit-inputs is-small" type="text"  disabled>
                    
                    <br>
                    <label>Last Name</label><br>
                    <input v-model="ride_to_edit.last_name" class="input edit-inputs is-small" type="text" disabled>

                    <br>
                    <label>Funding Source</label><br>
                    <input v-model="ride_to_edit.funding_source_id" class="input edit-inputs is-small" type="text" disabled>

                    <br>
                    <label>Date of Service</label><br>
                    <input v-model="ride_to_edit.date_of_service" class="input edit-inputs is-small" type="text" disabled>

                    <br>
                    <label>Mileage</label><br>
                    <input v-model="ride_to_edit.mileage" class="input edit-inputs is-small" type="number">
                   
                </div>

                <!-- COLUMN B -->
                <div class="column is-one-quarter">
                    <label>Pickup Modifier</label><br>
                    <input v-model="ride_to_edit.pickup_modifier" class="input edit-inputs is-small" type="text" @input="ride_to_edit.pickup_modifier = $event.target.value.toUpperCase()">

                    <br>
                    <label>Pickup Street</label><br>
                    <input v-model="ride_to_edit.pickup_street" class="input edit-inputs is-small" type="text">

                    <br>
                    <label>Pickup City</label><br>
                    <input v-model="ride_to_edit.pickup_city" class="input edit-inputs is-small" type="text">

                    <br>
                    <label>Pickup State</label><br>
                    <input v-model="ride_to_edit.pickup_state" class="input edit-inputs is-small" type="text">
                    
                    <br>
                    <label>Pickup Zip</label><br>
                    <!-- <input class="input edit-inputs is-small" type="text" :value="ride_to_edit.pickup_zip"> -->
                    <input v-model="ride_to_edit.pickup_zip" class="input edit-inputs is-small" type="text" pattern="[0-9]{5}" maxlength="5">

                </div>

                <!-- COLUMN C -->
                <div class="column is-one-quarter">
                    <label>Dropoff Modifier</label><br>
                    <input v-model="ride_to_edit.dropoff_modifier" class="input edit-inputs is-small" type="text" @input="ride_to_edit.dropoff_modifier = $event.target.value.toUpperCase()">

                    <br>
                    <label>Dropoff Street</label><br>
                    <input v-model="ride_to_edit.dropoff_street" class="input edit-inputs is-small" type="text">

                    <br>
                    <label>Dropoff City</label><br>
                    <input v-model="ride_to_edit.dropoff_city" class="input edit-inputs is-small" type="text">

                    <br>
                    <label>Dropoff State</label><br>
                    <input v-model="ride_to_edit.dropoff_state" class="input edit-inputs is-small" type="text">
                    
                    <br>
                    <label>Dropoff Zip</label><br>
                    <input v-model="ride_to_edit.dropoff_zip" class="input edit-inputs is-small" type="text">
                    
                </div>

                <!-- COLUMN D -->
                <div class="column is-one-quarter">
                    <label>Box 19</label><br>
                    <input v-model="box_19" class="input edit-inputs is-small" type="text" maxlength="80" placeholder="Optional">
                    <br>
                    <small>If you don't add anything to box 19, it will be automatically generated using the pickup and dropoff addresses.</small>


                </div>
                
            <!-- END OF TOP FORM -->
            </div>

            <!-- START OF EDIT SERVICES -->

            <div class="services columns">
  
            <div class="column service-title"><h4 style="margin:0 0 0 150px; text-align:center; color:white;">Services</h4>
            <div class="total">Total Charge: {{total_value.toFixed(2)}} </div> 
            <div>Times Resubmitted: {{ times_resubmitted }}</div>
            <div>Claim Last Updated: {{ last_updated }}</div>
            <button class="button is-success is-small is-pulled-right" style="margin-right: 25px;" @click="addService()">Add</button>
            </div>

            <div class="return-button" >
          
            </div>
            <br>
            <hr style="margin:0; background-color: #ddd;">
            
            
          </div>
  
          <div class="services-data">
            <table>
              <tr>
                <!-- <th>Claim ID</th> -->
                <th>Code Class <span style="color: red">*</span> </th>
                <th>Code <span style="color: red">*</span></th>
                <th>Units <span style="color: red">*</span></th>
                <th>Rate <span style="color: red">*</span></th>
                <th>Charge <span style="color: red">*</span></th>
                <th width="10%">Modifier 1</th>
                <th width="10%">Modifier 2</th>
                <th width="10%">Modifier 3</th>
                <th width="10%">Ride Hash <span style="color: red">*</span></th>
                <th>&zwj;</th>
 
              </tr>
              
              <tr v-for="service in services" :key=service.id>
                <!-- CODE CLASS -->
                <td>
                <select v-model="service.code_class" class="input edit-selects is-small" type="text">
                    <option >{{ service.code_class }}</option>
                    <option>LOAD</option>
                    <option>MILE</option>
                    <option>WAIT</option>
                </select>
            </td>
            <!-- BILLING CODES -->
            <td>
                <select v-model="service.code" class="input edit-selects is-small" >
                    <option>{{ service.code }}</option>
                    <option v-for="code in billing_codes" :key=code.id>{{ code.code }}</option>
                </select>
            </td>

            <!-- UNITS -->
            <td>
            <input  v-model="service.units" class="input edit-selects is-small" type="number" >
        </td>
            <!-- RATE -->
            <td>
                <input v-model="service.rate" class="input edit-selects is-small" type="number">
            </td>

            <!-- CHARGE -->
            <td>
                <input @change="updateCharges()" v-model="service.charge" class="input edit-selects is-small" type="number" >

            </td>

            <!-- MODIFIERS -->

                <td>
                    <input v-model="service.mod1" class="input edit-selects is-small" type="text" @input="service.mod1 = $event.target.value.toUpperCase()"> 
                    
                </td>
                
                <td>
                  <input v-model="service.mod2" class="input edit-selects is-small" type="text" @input="service.mod2 = $event.target.value.toUpperCase()" > 
                    
                </td>

                <td>
                  <input v-model="service.mod3" class="input edit-selects is-small" type="text" @input="service.mod3 = $event.target.value.toUpperCase()"> 
                </td>

                <td>
                  <select v-model="service.ride_id" class="input edit-selects is-small">
                    <option v-for="ride in rides" :key="ride.id" :value="ride.ride_id">
                      {{ride.ride_id}}
                    </option>

                  </select>
                </td>

                <td> <button class="button is-small is-danger" @click="deleteService(service)">X</button> </td>


                
  
              </tr>
            </table>
  
            
          </div>
          <!-- RIDES -->
          <br>
     
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Datepicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
  import { CSRF_TOKEN } from "../../../common/csrf_token.js";
  import { generateToast } from ".../../../common/toast.js";
  import FileReader from "@/components/FileReader.vue";
  import { convertDates } from ".../../../common/converters.js";
  
  
  export default {
    components: { Datepicker, FileReader },
  
    name: "Claims",
    data() {
      return {
        billing_codes : null,
        box_19 : '',
        rate_classes : null,
        ride_to_edit_toggle : false,
        selected_ride_id : null,
        ride_to_edit : null,
        data: "",
        toggle : true,
        claims: [],
        rides : [],
        services : [],
        service_claim: [],
        search_term: "",
        search_query: null,
        funding_sources: [],
        funding_source: "",
        date: "",
        busy: false,
        allSelected: false,
        total_value : 0,
        checkbox_claim_id: [],
        times_resubmitted : null,
        last_updated : null,
        rides_databank : [], // stores rides that have been queried for edit and returns rides that have been edited in place of rides that would be sent along with 'rides' in resbumit()

        headers: {
          headers: {
            "X-CSRFTOKEN": CSRF_TOKEN,
            Authorization: `Token ${this.$store.state.token}`,
          },
        },
      };
    },


  
    mounted() {
      this.getFundingSource();
      // this.getLatestClaims();
      this.allowedBillingCodes();
    },
  
    created() {
      document.title = "Claims";
    },


//////////////////////
    methods: {


      async addService() {
        // Add service to the database under this claim ID so that when they go to the backend the logic works. 
        this.$store.commit("setIsLoading", true)
        let claim_id = this.services[0].claim
        await axios
          .post("/api/v1/add-service/", claim_id, this.headers)
          .then((response) => {
            console.log("Add Service Response: ", response)
            this.services.push({
              "id": parseInt(response.data.message),
              "code": null,
              "code_class": null,
              "units": null,
              "rate": null,
              "charge": null,
              "modifier_set" : "user_added_service",
              "member_name" : `${this.rides[0].first_name} ${this.rides[0].last_name}`,
              "date_of_service" : "user_added_service",
              "ride_id" : null,
              "pickup_time" : "user_added_service",
              "location_mod" : "user_added_service",
              "mod1": "",
              "mod2": "",
              "mod3": "",
            })
            this.$store.commit("setIsLoading", false)
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });



      },
//
      async deleteService(service) {
        const index = this.services.findIndex(obj => obj.id === service.id) // get the services index from services
        this.services.splice(index, 1) // delete the service from services obj
        this.updateCharges()

        this.$store.commit("setIsLoading", true)

        await axios
          .post("/api/v1/delete-service/", service, this.headers)
          .then((response) => {
            console.log("Delete service Response: ", response)
            this.$store.commit("setIsLoading", false)
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });
      },
      //
      onSubmit() {
        console.log(this.form);
        // post the form to the server
      },

//
    updateCharges(){
      // Dynamically update prices based on charges in ride to edit
      const arr = []
      for(var i = 0; i < this.services.length; i++) {
        arr.push(this.services[i].charge)
      }
      this.total_value = this.sumArray(arr)
    },
//
    sumArray(numbers){
        let sum = 0;
        numbers.forEach((number) => {
        sum += number;
        });
      return sum

    },
//  
    // async saveEdit(){
    //   var x = confirm("This will save your changes, are you sure you want to do this?")
    //   if(x){
    //     let data = {"services" : this.services, "edited_ride" : this.ride_to_edit}
    //     await axios
    //   .post("/api/v1/save-resub-edit/", data, this.headers)
    //   .then((response) => {
    //       console.log("Save Edit Response: ", response)
    //       this.$store.commit("setIsLoading", false)
    //   })
    //   .catch((error) => {
    //       console.log(error)
    //       generateToast(`Save failed: ${error}`, "is-danger")

    //       this.$store.commit("setIsLoading", false)
    //     });
    //   }
    // },
//
    async resubmit(){
      var x = confirm("Are you sure you want to resubmit?")
      if (x) {

        // Check to make sure the services are all fully filled in - uses the null as a boolean to indicate which fields are required in addService
        function hasValues(obj) {
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] === null)) {
              return false;
            }
          }
          return true;
        }

        for (var i = 0; i < this.services.length; i++) {
          if (!hasValues(this.services[i])) {
            generateToast("Check services, you missed something... Modifiers are the only fields allowed to be blank.", "is-warning")
            return false
          }
        }

        // Check to see if any rides were edited, and add them to a new rides array to be returned, if not, return the rides as they are so the logic need not be extended beyond here.
  
       // check if any ride ids from rides are already in rides_databank, if they are only add the ride databank ride to new rides, else add the this.ride 
          let new_rides = [];

          // Iterate through each ride in this.rides
          for (let i = 0; i < this.rides.length; i++) {
            let ride = this.rides[i];

            // Check if the ride ID is in this.rides_databank
            let databank_ride = this.rides_databank.find(databank_ride => databank_ride.id === ride.id);

            // If the ride is in the databank, add only the databank ride to new_rides
            if (databank_ride) {
              new_rides.push(databank_ride);
            } else {
              // Otherwise, add the current ride from this.rides to new_rides
              new_rides.push(ride);
            }
          }

          // Assign new_rides to this.rides
          this.rides = new_rides;


      let data = {"rides" : new_rides, "services" : this.services, "charges" : this.total_value, "box_19" : this.box_19}
      await axios
      .post("/api/v1/resubmit/", data, this.headers)
      .then((response) => {
          console.log("Resubmit Response: ", response)
          generateToast("Resubmit successful", "is-success")
          this.$store.commit("setIsLoading", false)
          this.$router.push('/dashboard/resub/') // refresh page
          
      })
      .catch((error) => {
          console.log(error)
          generateToast(`Resubmit failed: ${error}`, "is-danger")

          this.$store.commit("setIsLoading", false)
          this.$router.push('/dashboard/resub/') // refresh page
        });
      }


    },

//
    async allowedBillingCodes(){
    this.$store.commit("setIsLoading", true)

        await axios
        .get("/api/v1/allowed-billing-codes/")
        .then((response) => {
            console.log("Allowed Billing Codes Response: ", response)
            this.billing_codes = response.data
            this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
            });
    },
//

      async editRide(){
        this.$store.commit("setIsLoading", true)

            await axios
            .get(`/api/v1/edit-claim-ride/?ride_id=${this.selected_ride_id}`)
            .then((response) => {
                console.log("Edit Ride Response: ", response)
    
                this.ride_to_edit_toggle = true

                // Commit ride to a temporary databank
                if(!this.rides_databank.find(obj => obj.id === response.data[0].id)) // if id is not found in any object within the databank - this allows you to search back and forth but not keep adding duplicate rides to the databank
                {
                  this.rides_databank.push(response.data[0])
                }
                this.ride_to_edit = this.rides_databank.find(obj => obj.id === response.data[0].id)
                
                
                this.$store.commit("setIsLoading", false)
            })
            .catch((error) => {
                console.log(error)
                this.$store.commit("setIsLoading", false)
            });

      },
  
      async deleteClaims(){
        if (!this.busy){
          this.busy=true
        var x = confirm("This action cannot be undone, are you sure you want to do this?")
        if(x){
        this.$store.commit("setIsLoading", true)
        if(this.checkbox_claim_id.length > 0){
  
       await axios
        .post("/api/v1/delete-claims/", this.checkbox_claim_id, this.headers)
        .then((response) => {
            console.log("Delete Claims Response: ", response)
            generateToast(`${response.data.message}`, "is-success")
            this.$store.commit("setIsLoading", false)
            this.busy = false
            setTimeout(this.$router.go, 3000);
            
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
            this.busy = false
          });
        } else {
          generateToast("Please select a claim to delete.", "is-warning")
           this.$store.commit("setIsLoading", false)
           this.busy = false
        }
        } else{
          this.busy =false
        }
        } else {
          generateToast("System is busy, please wait...", "is-warning")
          this.busy = false
        }
  
      },
  
  
      modifierSet(modifier_set) {
        // Create string from modifier set
      let codes = []
      const new_modifier_set = Object.assign({}, modifier_set);
      for (const [key, value] of Object.entries(new_modifier_set)) {
        console.log(`${key}: ${value}`);
        this.modifier_codes.push(value)
      }
  
      let final_codes = codes.toString()
      return final_codes
     
      },
  
         //
      searchClaims() {
        if(!this.busy) {
          if (this.funding_source){
          this.busy = true;
          this.toggle=true;
        
        if (this.date && this.date.length == 2) {
          this.$store.commit("setIsLoading", true);
  
          let start_date = convertDates(this.date[0]);
          let end_date = convertDates(this.date[1]);
  
          let url = `/api/v1/claims-by-date/?min_date=${start_date}&max_date=${end_date}&funding_source_id=${this.funding_source}`;
          console.log(url);
          axios
            .get(url)
            .then((response) => {
              this.claims = response.data;
              console.log("CLAIMS DATA: ",response.data);
              this.$store.commit("setIsLoading", false);
              this.busy = false;
  
            // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
            // this.service_claim = []
            // for(var i = 0; i < response.data.length; i++){
            //   this.getAllServices(response.data[i].id)
            //   console.log(this.service_claim)
            // }
            })
            .catch((error) => {
              console.log(error);
              this.$store.commit("setIsLoading", false);
              this.busy = false;
            });
  
          
        } else {
          generateToast("Please select dates.", "is-warning");
          this.$store.commit("setIsLoading", false);
        }
        } else{
          generateToast("Please select a funding source.", "is-warning")
        }
        } else {
          generateToast("System is busy, please wait...", "is-warning");
          this.$store.commit("setIsLoading", false);
        }
        
      },
      //
      async reviewClaim(claim_id){
        this.toggle=false
        console.log(claim_id)
        this.times_resubmitted = null // reset each time a new claim is loaded
        this.last_resubmission = null
        this.ride_to_edit = null
        this.rides_databank = []
        await this.getServices(claim_id)
        await this.getRides(claim_id)
        await this.getClaim(claim_id)
      },

//
      async getClaim(claim_id) {
        // Get claim to show resubmission info
        this.$store.commit("setIsLoading", true)

        await axios
          .get(`/api/v1/claim-by-id/?claim_id=${claim_id}`)
          .then((response) => {
            console.log("getClaim Response: ", response)
            this.times_resubmitted = response.data[0].times_resubmitted
            var dt = response.data[0].last_updated
            var dt = new Date(dt)
            var dt = dt.toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});

            this.last_updated = dt
            this.$store.commit("setIsLoading", false)
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });


      },
//
       async getRides(claim_id) {
        this.$store.commit("setIsLoading", true);
  
        await axios
          .get(`/api/v1/claim-rides/?claim_id=${claim_id}`)
          .then((response) => {
            console.log("Rides Response: ", response);
            this.rides = response.data;
            this.$store.commit("setIsLoading", false);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
      },
  //
      async getLatestClaims() {
        this.$store.commit("setIsLoading", true);
  
        await axios
          .get("/api/v1/latest_claims/")
          .then((response) => {
            console.log("Latest Claims Response: ", response);
            this.claims = response.data;
            this.$store.commit("setIsLoading", false);
  
            // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
            // this.service_claim = []
            // for(var i = 0; i < response.data.length; i++){
            //   this.getAllServices(response.data[i].id)
            //   console.log(this.service_claim)
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
      },
  
      //
      async syncClaimStatus() {
        this.$store.commit("setIsLoading", true);
        let data = { ids: this.checkbox_claim_id };
        await axios
          .post("/api/v1/sync-claims/", data, this.headers)
          .then((response) => {
            console.log("Sync Claims Response: ", response);
            this.claims = response.data;
            this.$store.commit("setIsLoading", false);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
      },
      //
      // async searchByName() {
      //   if (!this.busy) {
      //     if(this.funding_source){
      //     this.busy = true;
      //     this.toggle = true;
      //     this.$store.commit("setIsLoading", true);
  
      //     axios
      //       .get(
      //         `/api/v1/claims-by-name/?search=${this.search_term}&funding_source_id=${this.funding_source}`
      //       )
      //       .then((response) => {
      //         console.log("Search By Name Response: ", response);
      //         this.claims = response.data;
      //         this.$store.commit("setIsLoading", false);
      //         this.busy = false;
  
      //         // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
      //       this.service_claim = []
      //       for(var i = 0; i < response.data.length; i++){
      //         this.getAllServices(response.data[i].id)
      //         console.log(this.service_claim)
      //       }
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //         this.$store.commit("setIsLoading", false);
      //       });
      //       } else{
      //     generateToast("Please select a funding source.", "is-warning")
      //   }
      //   } else {
      //     generateToast("System is busy, please wait...", "is-warning");
      //     this.$store.commit("setIsLoading", false);
      //   }
        
      // },
  //
      async getFundingSource() {
         this.$store.commit("setIsLoading", true);
         this.fsloading = true;
  
       await axios
        .get("/api/v1/get-funding-sources/")
        .then((response) => {
            console.log("FS Response: ", response)
            this.funding_sources = response.data.content.Data
            this.$store.commit("setIsLoading", false)
            this.fsloading = false;
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
            this.fsloading = false;
          });
  
  
      },
  //
      addModifiers(){
        // add modifiers to services from their object
        for(var i = 0; i < this.services.length; i++) {
          const modifier_set = this.services[i].modifier_set;
          const values = Object.values(modifier_set);
          const value = values.length ? values : ''; // if values keep them as an array, if there are no values return empty string
         
          // Place all the active modifiers in their places if they exist
          if(value != ''){ 

            try { 
              this.services[i].mod1 = value[0]
            }catch {
              console.log("No modifier at the 0 index of this service", this.services[i])
            }

            try {
              this.services[i].mod2 = value[1]
            }
            catch {
              console.log("No modifier at the 1 index of this service", this.services[i])
            }

            try {
              this.services[i].mod3 = value[2]
            }
            catch {
              console.log("No modifier at the 2 index of this service", this.services[i])
            }

            }
            
        }

        

      },
  //
  
      async getServices(claim_id){
         this.$store.commit("setIsLoading", true)
  
       await axios
        .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
        .then((response) => {
            console.log("Services Response: ", response)

            // Round all floats before saving to services 
            response.data.forEach(obj => {
              obj.charge = parseFloat(obj.charge.toFixed(2));
              obj.rate = parseFloat(obj.rate.toFixed(2));
              obj.units = parseFloat(obj.units.toFixed(2));
            })
            
            this.services = response.data
            this.addModifiers(this.services)
  
            var count = 0.0
            for (var i = 0; i < response.data.length; i++) {
              count += response.data[i].charge
            }
            this.total_value = count
         
            this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });
  
  
      },
  //
      getTotal(claim_id){
        // Loop to find a matching claim id in service_claim, if match found, return value.
        for (var i = 0; i < this.service_claim.length; i++) {
          if(this.service_claim[i]['claim_id'] == claim_id){
            return this.service_claim[i]['total'].toFixed(2)
          }
        }
      },
  //
    getAllServices(claim_id){
        // Gets services and organizes them for getTotal to call in the template and retrieve total for each claim
        this.$store.commit("setIsLoading", true)
  
        axios
        .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
        .then((response) => {
  
            var count = 0.0
            for (var i = 0; i < response.data.length; i++) {
              count += response.data[i].charge
            }
            this.$store.commit("setIsLoading", false)
  
            this.service_claim.push({ "claim_id" : claim_id, "total": count})
         
  
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });
  
  
      },
  
      //
      selectAll() {
        this.allSelected = !this.allSelected; // Set the opposite bool at start, but let default be false
        this.checkbox_claim_id = [];
  
        if (this.allSelected) {
          for (const r in this.claims) {
            var claim_id = this.claims[r].id;
            this.checkbox_claim_id.push(claim_id);
          }
        } else {
          this.checkbox_claim_id = [];
        }
      },
  
      selectIndividualCheck() {
        this.allSelected = false;
      },
    },
    //
  
    
  };
  </script>
  
  <style scoped>
  .controls {
    width: 80%;
    margin: 0 auto;
  }
  
  .content {
    width: 100%;
    top: 50px;
  }
  
  .table-container {
    width: 100%;
    height: 600px;
    overflow: auto;
    overflow-x: hidden;
    font-size: 0.9em;
  }
  
  .select-control {
    height: 42px;
  }
  
  .custom-select {
    height: 38px;
    width: 100%;
    margin-right: 5px;
    border: 1px solid #ddd;
  }
  
  .really-small {
    font-size: 0.7em;
  }
  
  .custom-control {
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
    height: 220px;
    margin-bottom: 15px;
    padding: 10px;
    text-align: center;
  }
  
  .table-style {
    /* From https://css.glass */
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
    overflow: auto;
    max-height: 500px;
    font-size: 0.8em;
  }
  
  table td {
    text-align: center;
  }
  
  table tr {
    text-align: center;
  }
  
  .truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
  }
  
  .upload {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .upload-form {
    text-align: center;
    margin-left: 5px;
    margin-top: 5px;
    width: 250px;
  }
  
  /* Tooltip container */
  /* .tooltip-patch {
    margin-top: -10px; 
    margin-left: 5px;
  
  
  } */
  
  .middle-btn {
    text-align: center;
  
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .review {
    padding: 10px;
    font-size :.7em;
    color: white;
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
  }
  
  .content table th {
    color: white;
  }
  
  tr:hover td {
    background:blue;
  }
  
  .edit-inputs{
    width:200px; 
    text-align: center; 
    margin-bottom: 5px;
    text-transform:uppercase;
  }

  .edit-selects{
    width:100px; 
    text-align: center; 
    margin-bottom: 5px;
    text-transform:uppercase;
  }

  .total {
    font-weight : bold;
    font-size : 2em;
  }

  table {
        border-collapse: collapse;
        width: 100%;
    }
    th {
        background-color: black;
        position: sticky;
        top: 0px;
        z-index: 1; /* Add z-index */
        
    }
    th, td {
        text-align: left;
        padding: 8px;
        border: 2px solid red;
    }
  </style>