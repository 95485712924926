<template>


  <div>

    <!-- START TOP NAV -->
    <nav
      class="navbar is-fixed-top box-shadow-y"
      v-if="$store.state.isAuthenticated"
    >
      <div class="navbar-brand has-background-black ">
        <a
          role="button"
          class="navbar-burger toggler"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
        <a
          href="#"
          class="logo-link navbar-item has-text-weight-bold has-text-white"
        >
          <img
            class="pnr_logo"
            src="~@/assets/img/pnr_infinity.png"
            alt="PIONIR"
          />
        </a>
        <a
          role="button"
          class="navbar-burger nav-toggler"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu has-background-black has-text-white responsive-admin">
        <div class="navbar-end">
          <div class="is-loading-bar has-text-centered" :class="{'is-loading': $store.state.isLoading}">
            <div class="lds-dual-ring"></div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable ">
            <a v-if="notifications.length > 0" href="" class="navbar-link"> <span  class='material-icons-round red-color'>warning</span> Notifications  </a>
            <a v-else href="#" class="navbar-link has-text-white"> <span class='material-icons-round ok-color'>done_all</span> Notifications  </a>
            <div class="navbar-dropdown is-right">
              <a v-for="message in notifications" :key=message.id :href="message.url" class="navbar-item">{{message.desc}}: {{message.data1}} {{message.data2}}</a>

            </div>
            
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a href="#" class="navbar-link"> {{$store.state.user.username}} </a>
            <div class="navbar-dropdown is-right">
              <span v-if="$store.state.user.username != 'onboard@pionir.ai'">
              <a href="#" @click="this.$router.push('/detail/review-billing-payload')" class="navbar-item"> Review Payloads </a>
              <a href="#" @click="this.$router.push('/detail/review-billing-error')" class="navbar-item"> Review Errors </a>
              <a href="#" @click="this.$router.push('/detail/transport-sync')" class="navbar-item"> Transport Sync </a>

              <a @click="openSettings()" class="navbar-item">Settings</a>
              <hr class="navbar-divider" />
              </span>

              <a @click="logout()" class="navbar-item">Log Out</a>
            </div>
            
          </div>
        </div>
      </div>

      
    </nav>
    <div style="margin-top: 200px;">
    </div>

    <!-- END TOP NAV -->
    <!-- START SIDE MENU -->
    <div class="columns is-variable is-0" v-if="$store.state.isAuthenticated && $store.state.user.username != 'onboard@pionir.ai'">
      <div>
        <div class="menu-container px-1 has-background-black">
          <div class="menu-wrapper py-1">
            
            <div class="side-menu-items">
              <aside class="menu side-menu-items">
                <ul class="menu-list glass-pill" v-if="user_is_manager_account">
                  <li @click="Accounting()"><a>Accounting</a></li>
                  
                </ul>

                <ul class="menu-list glass-pill" v-if="user_is_manager_account">
                  <li><a @click="Bill()">Bill</a></li>
                </ul>
                <ul class="menu-list glass-pill" v-if="user_is_manager_account">
                   <li><a @click="Claims()">Claims</a></li>
                </ul>
                <ul class="menu-list glass-pill" v-if="user_is_manager_account">
                  <li><a @click="CreateClaim()">Create Claim</a></li>

                </ul>
                <ul class="menu-list glass-pill" v-if="user_is_manager_account">
                  <li><a @click="Resub()">Resub</a></li>

                </ul>
                <ul class="menu-list glass-pill" v-if="user_is_manager_account">
                  <li><a @click="Members()">Members</a></li>

                </ul>
                <!-- <ul class="menu-list glass-pill">
                  <li><a @click="Oracle()">Oracle RL</a></li>

                </ul> -->


                <ul class="menu-list glass-pill" v-if="user_is_manager_account">
                  <li @click="AddressBook()"><a>Address Book</a></li>

                </ul> 

                <ul class="menu-list glass-pill" >
                  <li @click="TripManager()"><a>Trip Manager</a></li>

                </ul> 

                

                
<!--                 
                <ul class="menu-list glass-pill">
                  <ul>
                    <li><a @click="Dashboard()">Dashboard</a></li>
                  </ul>
                </ul>
              -->
           

                
             
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>

    

    <!-- END SIDE MENU -->

    <div class="content">
      <router-view />
    </div>
    <div class="content-bg"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {

  data()  {
    return {
    }
  },

  watch:{
    $route (to, from){
        // without the below the route change will trigger checkNotifications to run after logout, and cause an admin log in dialogue to appear from django(?) and an error will occur in the console informing that the endpoint is unauthorized.
        if (to.path != '/login') {
          this.checkNotifications();
          this.checkUserPermission(); // Check permission at the beginning of all other functions
        }
        
    }
} ,
  beforeCreate() {
    this.$store.commit("initializeStore");

    // Set request headers for axios
    if (this.$store.state.token) {
      // If a token was granted via successful login, tell backend you are authenticated
      axios.defaults.headers.common["Authorization"] =
        "Token " + this.$store.state.token;
    } else {
      // Tell backend you are not authenticated if login has not occured or is invalid
      axios.defaults.headers.common["Authorization"] = "";
    }

    

  },

  // mounted() {
  //   this.checkUserPermission()
  // },


  data() {
    return {
      notifications : [],
      user_is_manager_account : '',

    }
  },

  methods: {

    //
    async checkUserPermission() {
      this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/check-permission/")
      .then((response) => {
          console.log("App Permission Response: ", response)
          this.user_is_manager_account = response.data.has_permission
          this.$store.commit("setIsLoading", false)
          console.log(this.user_is_manager_account)

      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });

    },
    //

    async checkNotifications() {
      // Check all endpoints in this list, if there are any records post to vuex store
      // this.$store.commit("setIsLoading", true)
      let notif = []

    // Unregistered Members
     await axios
      .get("/api/v1/unregistered-member/")
      .then((response) => {
          console.log("Review: ", response.data)
          for(var i = 0; i < response.data.length; i++){
            notif.push({
              // "desc" : `${response.data[i].desc.substring(0,8)}`,
               "desc" : "Review",
              "url" : "/dashboard/unregistered-member/",
              "data1" : `${response.data[i].first_name}`,
              "data2" : `${response.data[i].last_name}`,
              })

          }
          this.$store.commit('setNotifications', notif)
          this.notifications = this.$store.state.notifications;

          // this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
    },
//
    async Dashboard() {
      this.$router.push('/')
    },

    async Bill() {
      this.$router.push('/dashboard/bill/')
    },
    async Accounting() {
      this.$router.push('/dashboard/accounting/')
    },

    async Oracle() {
      this.$router.push('/dashboard/oracle/')
    },

    async Resub() {
      this.$router.push('/dashboard/resub/')
    },

    async CreateClaim() {
      this.$router.push('/dashboard/create-claim/')
    },

    async Claims() {
      this.$router.push('/dashboard/claims/')
    },

    async Members() {
      this.$router.push("/dashboard/member/");
    },

    async AddressBook() {
      this.$router.push("/dashboard/address-book/");
    },

    async TripManager() {
      this.$router.push("/dashboard/trip-manager/");
    },


    async openSettings() {
      this.$router.push("/company-settings/");
    },
    
    async logout() {
      await axios
        .post("/api/v1/token/logout/")
        .then((response) => {
          this.user_is_manager_account = ''
          console.log("Logged out");
          this.$store.commit("purgeNotifications")
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });

      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      this.$store.commit("removeToken");

      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
@import "../node_modules/bulma";

html{
  background-color: black;
}



.content {
  z-index: 2;
  position: absolute;
  top: 20px;
  margin: 0 auto;
  padding-left: 10%;
  padding-right: 10%;
  width: 90%;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .content {
    z-index: 2;
    position: absolute;
    top: 50px;
    margin-left: 6%;
    width: 85%;
  }
}

@media (min-width: 5px) and (max-width: 768px) {
  .content {
    z-index: 2;
    position: absolute;
    top: 50px;
    left: 10px;
    margin: 0 auto;
    width: 90%;
  }
}

.button-basic {
  border: none;
  padding: 15px 30px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.button-submit {
  @extend .button-basic;
  background-color: green;
  color: white;
}

.content-bg {
  padding: 0;
  margin: 0;
  z-index: -1;
  height: 100%;
  min-height: 1000px;
  background-image: url("~@/assets/img/nodes1.jpeg");
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  // background-color: black;
  opacity: 0.5;
}

.menu {
  margin-left: 10px;
}

.red-color {
        color:red;
    }

.ok-color{
  
  color: green;
}


.menu-label {
  color: white;
  font-weight: bold;
}

// .menu-list ul li{

//   background-color: red;
// }

.side-menu-items {
  margin-top: 60px;
}

.is-transparent {
  opacity: 0.8;
}

body {
  background-color: #000;
  color: white;
}

.table{
  color: white;
}

.content table thead th {
  color: white;
}

.td {
  color: white;
}

.card {
  /* From https://css.glass */
  background: rgba($color: #35394f, $alpha: 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba($color: #35394f, $alpha: 0.2);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .logo-link {
    margin-left: 1%;
  }
}
@media (min-width: 1024px) and (max-width: 1500px) {
  .logo-link {
    margin-left: 50%;
    // margin-right: 75px;
  }
}

@media (min-width: 1501px) and (max-width: 2094px) {
  .logo-link {
    margin-left: 100%;
    // margin-right: 75px;
  }
}

@media (min-width: 2095px) and (max-width: 10000px) {
  .logo-link {
    margin-left: 145%;
    // margin-right: 75px;
  }
}

.px-1,
.p-1 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-1,
.p-1 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.box-shadow-y {
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

.menu-container {
  position: fixed;
  bottom: 0;
  top: 0;
  margin: auto;
  min-width: 300px;
  z-index: 10;

  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.menu-container.active {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.icon {
  height: 30px;
  width: 30px;
  vertical-align: baseline;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.toggler {
  margin-left: 0;
  cursor: pointer;
}

@media (min-width: 769px) {
  .menu-container {
    background-image: url("~@/assets/img/finance.jpeg");
    background-size: cover;
    width: 25%;
    min-width: auto;
    background-color: black;

    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@media (min-width: 1024px) {
  .menu-container {
    width: 16.6667%;
    min-width: auto;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.glass-pill {
  /* From https://css.glass */
  font-weight: bold;
  text-shadow: 2px 3px 5px rgba(0,0,0,0.5);
  
}

.menu-list a{
  color : white;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 30px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #ccc;
    border-color: #ccc transparent #ccc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.is-loading-bar {
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    
    &.is-loading {
        height: 30px;
    }
}

.navbar-link{
  color: white;
  background-color: black;
}
.navbar-link:focus{
  color: white;
  background-color: black;
}

.navbar-dropdown {
  background-color:black;
  color: white;
  border-top: 0px;
}

.navbar-dropdown:hover {
  background-color:black;
  color: white;
}

a.navbar-item {
  color: white;
}


 

.navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link{
  background-color: black;
  color: white;
}
</style>
