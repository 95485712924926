function convertDates(d) {
    date = new Date(d);
    date = date.toISOString();
    date = new Date(date);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let date = year + "-" + month + "-" + dt;
    return date
  }




function convertTime(time) {
  const hour = time.hours
  const minutes = time.minutes

  const time_string = hour + ":" + minutes 
  return time_string

}


export {convertDates, convertTime}


