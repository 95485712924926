import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import 'material-icons/iconfont/material-icons.css'

import SignUp from '../views/SignUp.vue'
import Login from '../views/Login.vue'
import CompanySettings from '../views/dashboard/CompanySettings.vue'

import Dashboard from '../views/dashboard/Dashboard.vue'
import Member from '../views/dashboard/Member.vue'
import CreateMember from '../views/forms/CreateMemberForm.vue'
import RateClassForm from '../views/forms/RateClassForm.vue'
import IntegrationForm from '../views/forms/IntegrationForm.vue'
import CreateTripForm from '../views/forms/CreateTripForm.vue'
import Accounting from '../views/dashboard/Accounting.vue'
import Bill from '../views/dashboard/Bill.vue'
import Claims from '../views/dashboard/Claims.vue'
import QBLogin from '../views/quickbooks/QBLogin.vue'
import UpdateMemberForm from '../views/forms/UpdateMemberForm.vue'
import UnregisteredMembers from '../views/dashboard/UnregisteredMembers.vue'
import ReviewBillingPayload from '../views/detail/ReviewBillingPayload.vue'
import ReviewBillingError from '../views/detail/ReviewBillingError.vue'
import TransportSync from '../views/detail/TransportSync.vue'
import Resub from '../views/dashboard/Resub.vue'
import CreateClaim from '../views/dashboard/CreateClaim.vue'
import PageNotFound from '../views/dashboard/PageNotFound.vue'
import AddressBook from '../views/dashboard/AddressBook.vue'
import TripManager from '../views/dashboard/TripManager.vue'


const routes = [

  {
    path: '/',
    name: 'QBLogin',
    component: QBLogin,
    meta : {requireLogin : true}
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta : {requireLogin : true}
  },


  
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'SignUp',
    component: SignUp
  },


  {
    path: '/company-settings',
    name: 'CompanySettings',
    component: CompanySettings,
    meta : {requireLogin : true}
  },


  {
    path: '/dashboard/member',
    name: 'Member',
    component: Member,
    meta : {requireLogin : true}
  },


  {
    path: '/dashboard/bill',
    name: 'Bill',
    component: Bill,
    meta : {requireLogin : true}
  },
  {
    path: '/dashboard/claims',
    name: 'Claims',
    component: Claims,
    meta : {requireLogin : true}
  },

  // {
  //   path: '/dashboard/accounting',
  //   name: 'Accounting',
  //   component: Accounting,
  //   meta : {requireLogin : true}
  // },

  {
    path: '/dashboard/accounting',
    name: 'Accounting',
    component: Accounting,
    meta : {requireLogin : true}
  },

  {
    path: '/dashboard/unregistered-member',
    name: 'UnregisteredMembers',
    component: UnregisteredMembers,
    meta : {requireLogin : true}

  },

  // {
  //   path: '/dashboard/oracle',
  //   name: 'Oracle',
  //   component: Oracle,
  //   meta : {requireLogin : true}

  // },

  {
    path: '/dashboard/resub',
    name: 'Resub',
    component: Resub,
    meta : {requireLogin : true}

  },

  {
    path: '/dashboard/create-claim',
    name: 'CreateClaim',
    component: CreateClaim,
    meta : {requireLogin : true}

  },

  {
    path: '/dashboard/address-book',
    name: 'AddressBook',
    component: AddressBook,
    meta : {requireLogin : true}

  },

  {
    path: '/dashboard/trip-manager',
    name: 'TripManager',
    component: TripManager,
    meta : {requireLogin : true}

  },


  
  // Forms

  {
    path: '/forms/create-member',
    name: 'CreateMember',
    component: CreateMember,
    meta : {requireLogin : true}
  },

  {
    path: '/forms/rate-class-form',
    name: 'RateClassForm',
    component: RateClassForm,
    meta : {requireLogin : true}
  },

  {
    path: '/forms/integration-form',
    name: 'IntegrationForm',
    component: IntegrationForm,
    meta : {requireLogin : true}
  },

  {
    path: '/forms/update-member',
    name: 'UpdateMemberForm',
    component: UpdateMemberForm,
    meta : {requireLogin : true}

  },

  {
    path: '/forms/create-trip',
    name: 'CreateTripForm',
    component: CreateTripForm,
    meta : {requireLogin : true}

  },

  //Details
  
  {
    path: '/detail/review-billing-payload',
    name: 'ReviewBillingPayload',
    component: ReviewBillingPayload,
    meta : {requireLogin : true}

  },

  {
    path: '/detail/review-billing-error',
    name: 'ReviewBillingError',
    component: ReviewBillingError,
    meta : {requireLogin : true}

  },

  {
    path: '/detail/transport-sync',
    name: 'TransportSync',
    component: TransportSync,
    meta : {requireLogin : true}

  },

  // Page not found
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta : {requireLogin : true}
  },


]



// Below is the way to create the router and is what gets rid of the /static/dist/ route in the url or the localhost etc (without his you would get localhost:8000/static/dist/{routes})
let router = createRouter({
  history: createWebHistory(),
  routes,
})



// Check if user is authenticated before each request, if not, send to login route, if so, allow to the intended route.
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/login')
  } else {
    next()
  }


})

export default router
