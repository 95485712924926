<template>
  <div class="content">
      
      <div class="column is">
        <div class="is-small is-pulled-right">
          <button @click="backToTrips()" class="button is-dark">Back To Trip Review</button> &zwnj;&zwnj;
          <button @click="saveTrip()" class="button is-info">Finish</button>
          
        </div>
      </div> 

      <br>
      <br>

      
      <div class="tab">
  <button class="tablinks" @click="openCity($event, 'trip')" id="defaultOpen">Trip</button>
  <button  class="tablinks" @click="openCity($event, 'rides')" >Rides</button>
</div>

<!-- Tab content -->
<!-- TRIP TAB -->
<div id="trip" class="tabcontent">
  <!-- <p>Create a Trip</p> -->
  
  <div class="columns is-full">

    <!-- Column 1 -->
    <div class="column control">

      <div class="field">
      <label class="label white-text">First Name</label>
        <div class="control">
          <input class="input is-small" style="text-transform: uppercase;" type="text" v-model="first_name" maxlength="20" @change="tripFormFilled()">
        </div>
      </div>

      <div class="field">
      <label class="label white-text">Last Name</label>
        <div class="control">
          <input class="input is-small" style="text-transform: uppercase;"  type="text" v-model="last_name" maxlength="20" @change="tripFormFilled()">
        </div>
      </div>

      <div class="field">
      <label class="label white-text">Member ID</label>
        <div class="control">
          <input class="input is-small" style="text-transform: uppercase;"  type="text" v-model="billing_id" maxlength="20" @change="tripFormFilled()">
        </div>
      </div>
        

        <div class="field width-adjustment">
        <label class="label white-text">Gender</label>
        <div class="control">
          <div class="select" >
            <select class="select" v-model="gender" @change="tripFormFilled()">
          <option value="F">F</option>
          <option value="M">M</option>
        </select>
          </div>
        </div>
      </div>
        
      <!-- Date of Birth -->
      <label class="label white-text">Date of Birth</label>
        <div class="date-container" >
            <Datepicker
              v-model="date_of_birth"
              :partialRange="false"
              :enableTimePicker="false"
              :onClickOutside="tripFormFilled()"
            />
          </div>

      <div class="field">
      <label class="label white-text">Phone Number</label>
        <div class="control">
          <input class="input" type="text" v-model="phone_number" maxlength="10" @input="phoneFilterInput" @change="tripFormFilled()">
        </div>
      </div>
        
    



    </div>

    <!-- Column 2 -->
    <div class="column">

      <!-- Date of Service -->
      <label class="label white-text">Date of Service</label>
      <div class="date-container">
            <Datepicker
              v-model="date_of_service"
              :partialRange="false"
              :enableTimePicker="false"
              :onClickOutside="tripFormFilled()"
            />
          </div>

          <div class="field">
      <label class="label white-text">Conf #</label>
        <div class="control">
          <input class="input is-small" type="text" v-model="conf_num" maxlength="20" @change="tripFormFilled()">
        </div>
      </div>

      <div class="field">
      <label class="label white-text">Diag Code</label>
        <div class="control">
          <input class="input is-small" type="text" v-model="diag_code" maxlength="10" @change="tripFormFilled()">
        </div>
      </div>

      <!-- PCS Expiry -->
      <label class="label white-text">PCS Expiry</label>
      <div class="date-container">
            <Datepicker
              v-model="pcs_expiry"
              :partialRange="false"
              :enableTimePicker="false"
              :onClickOutside="tripFormFilled()"
            />
          </div>
      
          <!-- <div class="field width-adjustment">
        <label class="label white-text">Trip Type</label>
        <div class="control">
          <div class="select">
            <select class="select" v-model="trip_type" @change="tripFormFilled()">
          <option value="one_way">One Way</option>
          <option value="round_trip">Round Trip</option>
          <option value="poly_trip">Poly Trip</option>
        </select>
          </div>
        </div>
      </div> -->
        
        

    </div>

    <!-- Column 3 -->
    <div class="column">
      <label for="">Trip Notes</label>
      <textarea maxlength="200" class="textarea" placeholder="e.g. Anything we need to know." @change="tripFormFilled()" v-model="notes"></textarea>
<br>
<small>Autofill form with existing member data</small>
      <div class="dropdown" >
                <Dropdown
                  :options="members"
                  v-on:selected="validateSelection"
                  v-on:filter="getDropdownValues"
                  :disabled="false"
                  name="zipcode"
                  :maxItem="10000000"
                  placeholder="Please select an option">
              </Dropdown>
            </div>
  
    
    </div>

    <!-- Column 4 -->
    <div class="column">
      
    </div>

    <!-- Column 5 -->
    <div class="column">
      
    </div>

  </div>

</div>

<!-- RIDES TAB -->
<div v-if="ride_entry_allowed" id="rides" class="tabcontent">
  <button @click="saveRide()" class="button is-success is-small">Add This Ride</button>
  

  <div>
    <label class="checkbox" style="display: flex; align-items: center; gap: 5px;">
      <input v-model="round_trip_toggle" type="checkbox" style="height: 20px; width: 20px;">
      Make this a round trip
    </label>
  </div>

  <div class="columns  is-full">

    <!-- Column 1 -->

   

    
    <div class="column is-2"> 

       <!-- Pickup Time -->
    <label class="label white-text">Pickup Time</label>
      <div class="date-container">
          <Datepicker
            v-model="pickup_time"
            :timePicker="true"
          />
        </div>

 
      
      <!-- Pickup Street -->
      <div class="field">
      <label class="label white-text">Pickup Street</label>
        <div class="control">
          <input class="input is-small" type="text" v-model="pickup_street" maxlength="20">
        </div>
      </div>

      <!-- Pickup Unit -->
      <div class="field">
      <label class="label white-text">Pickup Unit</label>
        <div class="control">
          <input class="input is-small"  placeholder="Optional" type="text" v-model="pickup_unit" maxlength="10">
        </div>
      </div>

      <!-- Pickup City -->
      <div class="field">
      <label class="label white-text">Pickup City</label>
        <div class="control">
          <input class="input is-small" type="text" v-model="pickup_city" maxlength="45">
        </div>
      </div>

      <!-- Pickup State Selection -->
      <label class="label white-text" for="states">Select a State:</label>
        <select class="input date-container" id="states" v-model="pickup_state">
            <option value="">Select a state</option>
            <option v-for="(stateName, stateCode) in states" :key="stateCode" :value="stateCode">{{ stateName }}</option>
        </select>


      <!-- Pickup Zipcode -->
      <div class="field">
      <label class="label white-text">Pickup Zipcode</label>
        <div class="control">
          <input class="input is-small" type="text" v-model="pickup_zipcode" @input="pickupZipCodeFilterInput" maxlength="5">
        </div>
      </div>






    </div>  <!-- End of column  -->

    
  
    <!-- Column 2 -->
    <div class="column is-2">

         <!-- Dropoff Time-->
         <label class="label white-text">Dropoff Time</label>
      <div class="date-container">
            <Datepicker
              v-model="dropoff_time"
              :timePicker="true"
            />
          </div>

      
    <!-- Dropoff Street -->
    <div class="field">
      <label class="label white-text">Dropoff Street</label>
        <div class="control ">
          <input class="input is-small" type="text" v-model="dropoff_street" maxlength="20">
        </div>
      </div>

      <!-- Dropoff Unit -->
      <div class="field">
      <label class="label white-text">Dropoff Unit</label>
        <div class="control">
          <input class="input is-small" placeholder="Optional" type="text" v-model="dropoff_unit" maxlength="10">
        </div>
      </div>

      <!-- Dropoff City -->
      <div class="field">
      <label class="label white-text">Dropoff City</label>
        <div class="control">
          <input class="input is-small" type="text" v-model="dropoff_city" maxlength="45">
        </div>
      </div>

      <!-- Dropoff State Selection -->
      <label class="label white-text" for="states">Select a State:</label>
        <select class="input date-container" id="states" v-model="dropoff_state">
            <option value="">Select a state</option>
            <option v-for="(stateName, stateCode) in states" :key="stateCode" :value="stateCode">{{ stateName }}</option>
        </select>


      <!-- Dropoff Zipcode -->
      <div class="field">
      <label class="label white-text">Dropoff Zipcode</label>
        <div class="control">
          <input class="input is-small" type="text" v-model="dropoff_zipcode" @input="dropoffZipCodeFilterInput" maxlength="5">
        </div>
      </div>

      





    </div> <!-- End of column  -->

    <!-- Column 3 -->
    <div class="column is-8">
      <div style="font-size: .7em;">{{billing_id}} | {{ last_name }}, {{ first_name }} | DOS: {{ date_of_service.getFullYear() }}-{{ date_of_service.getMonth() % 12 + 1 }}-{{ date_of_service.getDate() }}
 </div>
      
      <!-- HISORICAL RIDES -->
      <div class="historical-rides rides-data">
      <small>Click a historical ride to autofill the form, reduces typing.</small>

      <div class="row container-css">
        <div class="col-8">

          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Pickup Street</th>
                <th scope="col">Pickup Unit</th>
                <th scope="col">Pickup City</th>
                <th scope="col">Pickup State</th>
                <th scope="col">Pickup Zip</th>

                <th scope="col">Dropoff Street</th>
                <th scope="col">Dropoff Unit</th>
                <th scope="col">Dropoff City</th>
                <th scope="col">Dropoff State</th>
                <th scope="col">Dropoff Zip</th>
                
              </tr>
            </thead>
            <draggable v-model="historical_rides" tag="tbody" item-key="name" >
              <template #item="{ element }">
                <tr class="tr-dark" @click="autofillRideForm(element.id)">
                  <td>{{ element.pickup_street }}</td>
                  <td>{{ element.pickup_unit }}</td>
                  <td>{{ element.pickup_city }}</td>
                  <td>{{ element.pickup_state }}</td>
                  <td>{{ element.pickup_zipcode }}</td>

                  <td>{{ element.dropoff_street }}</td>
                  <td>{{ element.dropoff_unit }}</td>
                  <td>{{ element.dropoff_city }}</td>
                  <td>{{ element.dropoff_state }}</td>
                  <td>{{ element.dropoff_zipcode }}</td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>

    <rawDisplayer class="col-3" :value="list" title="List" />
  </div>
        
      </div>



      <!-- SESSION RIDES -->
      <div class="this-trip-rides rides-data">
        <!-- <p>This Trip Rides</p> -->
<br>
        <div class="row">
    <div class="col-8">
      <small>To Reorder, Click and Drag Ride.</small>

      <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <!-- <th scope="col">Member ID</th>
            <th scope="col">Rider Name</th> -->
            <th scope="col">ID</th>
            <th scope="col">Date of Service</th>
            <th scope="col">Pickup Time</th>
            <th scope="col">Pickup Street</th>
            <th scope="col">Pickup Unit</th>
            <th scope="col">Pickup Zip</th>
            <th scope="col">Dropoff Time</th>
            <th scope="col">Dropoff Street</th>
            <th scope="col">Dropoff Unit</th>
            <th scope="col">Dropoff Zip</th>
            <th scope="col">Delete</th>
            
          </tr>
        </thead>
        <draggable v-model="rides" tag="tbody" item-key="name" >
          <template #item="{ element }">
            <tr class="tr-dark">
              <!-- <td scope="row">{{ element.billing_id }}</td>
              <td>{{ element.last_name}}, {{ element.first_name }}</td> -->
              <td>{{ element.id }}</td>
              <td>{{ element.date_of_service }}</td>
              <td>{{ element.pickup_time }}</td>
              <td>{{ element.pickup_street }}</td>
              <td>{{ element.pickup_unit }}</td>
              <td>{{ element.pickup_zipcode }}</td>
              <td>{{ element.dropoff_time }}</td>
              <td>{{ element.dropoff_street }}</td>
              <td>{{ element.dropoff_unit }}</td>
              <td>{{ element.dropoff_zipcode }}</td>
              <td><button @click="deleteRide(element.id)" class="button is-small is-danger smaller-btn">X</button></td>
            </tr>
          </template>
        </draggable>
      </table>
    </div>

    <rawDisplayer class="col-3" :value="list" title="List" />
  </div>


 






      </div>

      
    </div>


</div>

</div>

<div v-else class="rides_disallowed">
  <p>Please fill out the Trip Form before adding rides.</p>
</div>
 














  </div>
</template>

    <script>
import axios from "axios";
import { toast } from "bulma-toast";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
import { generateToast } from ".../../../common/toast.js";
import Datepicker from "@vuepic/vue-datepicker";
import { convertDates, convertTime } from ".../../../common/converters.js";
import draggable from 'vuedraggable'
import Dropdown from "@/components/Dropdown.vue";



export default {
  name: "CreateMember",
  components :{Datepicker, convertDates, convertTime, draggable, Dropdown},

  mounted() {

  },

  data() {
    return {
      drag: false,
      fsloading : false,
      billing_id: '',
      first_name: '',
      last_name: '',
      diag_code: '',
      gender: '',
      date_of_birth: '',
      date_of_service : '',
      conf_num : '',
      pcs_expiry : '',
      notes : '',

      pickup_time : '',
      dropoff_time : '',
      
      pickup_street : '',
      pickup_unit : '',
      pickup_city : '',
      pickup_state : '',
      pickup_zipcode : '',


      dropoff_street : '',
      dropoff_unit : '',
      dropoff_city : '',
      dropoff_state : '',
      dropoff_zipcode : '',
      

      rides :[],
      ride_entry_allowed :  false,
      round_trip_toggle : false,
      historical_rides : '',
      ride_data : null,
      queried_member : '',
      member_search_query :'',
      members : [],
      trip_errors: [],
      ride_errors : [],

      busy: false,
      counter : 0,

      phone_number : '',

      states : {
            "AL": "Alabama",
            "AK": "Alaska",
            "AZ": "Arizona",
            "AR": "Arkansas",
            "CA": "California",
            "CO": "Colorado",
            "CT": "Connecticut",
            "DE": "Delaware",
            "DC": "District Of Columbia",
            "FL": "Florida",
            "GA": "Georgia",
            "HI": "Hawaii",
            "ID": "Idaho",
            "IL": "Illinois",
            "IN": "Indiana",
            "IA": "Iowa",
            "KS": "Kansas",
            "KY": "Kentucky",
            "LA": "Louisiana",
            "ME": "Maine",
            "MD": "Maryland",
            "MA": "Massachusetts",
            "MI": "Michigan",
            "MN": "Minnesota",
            "MS": "Mississippi",
            "MO": "Missouri",
            "MT": "Montana",
            "NE": "Nebraska",
            "NV": "Nevada",
            "NH": "New Hampshire",
            "NJ": "New Jersey",
            "NM": "New Mexico",
            "NY": "New York",
            "NC": "North Carolina",
            "ND": "North Dakota",
            "OH": "Ohio",
            "OK": "Oklahoma",
            "OR": "Oregon",
            "PA": "Pennsylvania",
            "PR": "Puerto Rico",
            "RI": "Rhode Island",
            "SC": "South Carolina",
            "SD": "South Dakota",
            "TN": "Tennessee",
            "TX": "Texas",
            "UT": "Utah",
            "VT": "Vermont",
            "VA": "Virginia",
            "WA": "Washington",
            "WV": "West Virginia",
            "WI": "Wisconsin",
            "WY": "Wyoming"
        },


      // Security
      headers : {headers:{"X-CSRFTOKEN": CSRF_TOKEN}}
    };
  },

  mounted() {
    document.getElementById("defaultOpen").click();
    this.getHistoricalRides()
    this.getMembers()
  },


  methods: {

        //

        getDropdownValues(value){
        // gets the value from the dropdown
        console.log('Get Dropdown Values: ', value)
        const billing_id = value.split("-")[1]
        this.member_search_query = billing_id
        this.memberSearch()
        
      },

      
//
      async memberSearch() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/member-search/?search=${this.member_search_query}&ordering=last_name`
        )
        .then((response) => {
          console.log('Member Search: ', response)
          this.queried_member = response.data[0];
          this.first_name = this.queried_member.first_name
          this.last_name = this.queried_member.last_name
          
          this.diag_code = this.queried_member.diag_code
          this.phone_number = this.queried_member.phone
          this.date_of_birth = this.queried_member.date_of_birth
          this.billing_id = this.queried_member.billing_id
          this.gender = this.queried_member.gender
          if(this.queried_member.gender){
            this.gender = "F"
          } else {
            this.gender = "M"
          }


        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
//

async getMembers() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/all-members/")
        .then((response) => {
          this.members = response.data;
          this.members.unshift({"first_name" : "NONE", "last_name" : "SELECT", "billing_id" : "NO_MEMBER_SELECTED"})
          console.log("Members: ", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },

//


    autofillRideForm(ride_id){
      // Uses ride id to locate ride data within historical rides and append data to the ride form
      const foundRide = this.historical_rides.find(obj => obj.id === ride_id);
      this.pickup_street = foundRide.pickup_street
      this.pickup_unit = foundRide.pickup_unit
      this.pickup_city = foundRide.pickup_city
      this.pickup_state = foundRide.pickup_state
      this.pickup_zipcode = foundRide.pickup_zipcode
      this.dropoff_street = foundRide.dropoff_street
      this.dropoff_unit = foundRide.dropoff_unit
      this.dropoff_city = foundRide.dropoff_city
      this.dropoff_state = foundRide.dropoff_state
      this.dropoff_zipcode = foundRide.dropoff_zipcode
    },

    async getHistoricalRides(){
      // Get historical rides for autofill
      
      this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/get-rides-autofill/")
      .then((response) => {
          console.log("Hist Rides Response: ", response)
          this.historical_rides = response.data
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });

    },
//
    tripFormFilled(){
      // Check if trip form is filled for rides entry toggle
      if(
        this.last_name.length > 0
      && this.first_name.length > 0
      && this.billing_id.length > 0
      && this.gender.length > 0
      && this.date_of_birth.toString().length > 0
      && this.date_of_service.toString().length > 0
      && this.phone_number.length > 0
      && this.conf_num.length > 0
      // && this.trip_type.length > 0
      && this.diag_code.length > 0
      && this.pcs_expiry.toString().length > 0){
        this.ride_entry_allowed = true
      
      } else {
        this.ride_entry_allowed = false
      }
      // console.log("ride_entry_allowed: ", this.ride_entry_allowed)
      this.last_name = this.last_name.toUpperCase()
      this.first_name = this.first_name.toUpperCase()
      this.conf_num = this.conf_num.toUpperCase()
      this.diag_code = this.diag_code.toUpperCase()
      this.billing_id = this.billing_id.toUpperCase()
      
    },
    //
    deleteRide(ride_id){
      for (let i = 0; i < this.rides.length; i++) {
      if (this.rides[i].id === ride_id) {
        this.rides.splice(i, 1); // Use splice to remove the element
        break; // Once found and deleted, exit the loop
      }
    }
    },
    //
    async saveTrip() {
      if (this.rides.length > 0) {
      // Saves the trip and rides form if data passes validation
      this.$store.commit("setIsLoading", true)

      // Trip level validation check
      if(!this.last_name){
        this.trip_errors.push("Last name is required.")
      }
      if(!this.first_name){
        this.trip_errors.push("First name is required.")
      }
      if(!this.billing_id){
        this.trip_errors.push("Member ID is required.")
      }
      if(!this.gender){
        this.trip_errors.push("Gender selection is required.")
      }
      if(!this.date_of_birth){
        this.trip_errors.push("Date of Birth is required.")
      }
      if(!this.date_of_service){
        this.trip_errors.push("Date of Service is required.")
      }
      if(this.phone_number.length !== 10){
        this.trip_errors.push("Phone number must be 10 digits long")
      }
      if(!this.conf_num){
        this.trip_errors.push("Confirmation Number is required.")
      }
      // if(!this.trip_type){
      //   this.trip_errors.push("Trip Type is required.")
      // }
      if(!this.diag_code){
        this.trip_errors.push("Diagnosis Code is required.")
      }
      if(!this.pcs_expiry){
        this.trip_errors.push("PCS expiry is required.")
      }
      if(this.rides.length === 0){
        this.trip_errors.push("You must create rides for this trip before submitting it.")
      }
      


      // If there are no errors, save the trip
      if (this.trip_errors.length == 0 && this.rides.length > 0){
        const dob = convertDates(this.date_of_birth)
        const dos = convertDates(this.date_of_service)
        const pcs = convertDates(this.pcs_expiry)

        const payload = {
          "last_name" :  this.last_name.toUpperCase(),
          "first_name" : this.first_name.toUpperCase(),
          "billing_id" : this.billing_id.toUpperCase(),
          "gender" : this.gender.toUpperCase(),
          "date_of_birth" : dob,
          "date_of_service" : dos,
          "phone_number" : this.phone_number,
          "confirmation_number" : this.conf_num.toUpperCase(),
          "diag_code" : this.diag_code,
          "pcs_expiry" : pcs,
          "notes" : this.notes,
          "rides" : this.rides

        } 



     await axios
      .post("/api/v1/save-trip/", payload, this.headers)
      .then((response) => {
          console.log("Save Trip Response: ", response)
          generateToast("Successfully created trip", "is-success")
          this.$router.push('/dashboard/trip-manager/')
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          generateToast(`Error creating trip, please contact support --> ${error}`, "is-error")
          this.$store.commit("setIsLoading", false)
        });
      }else {
          alert(this.trip_errors)
          generateToast("Please resolve all form errors to continue", "is-warning")
          this.trip_errors=[] // set errors to empty again so user can correct and not have old errors reported
        }

      } else {
        generateToast("Please add at least one ride to continue", "is-warning")
      }
      
    },

    //
    saveRide(){
      if(this.rides.length < 8){
      // Validate rides before adding them to the rides array for saveTrip()
      
         // Ride level validation check
        if(!this.pickup_time || this.pickup_time === undefined){
          this.ride_errors.push("Pickup Time is required. You may need to refesh the page.")
        }
        if(!this.pickup_street){
          this.ride_errors.push("Pickup Street is required.")
        }
        if(!this.pickup_city){
          this.ride_errors.push("Pickup City is required.")
        }
        if(!this.pickup_state){
          this.ride_errors.push("Pickup State is required.")
        }
        if(this.pickup_zipcode.length !== 5){
          this.ride_errors.push("Pickup Zipcode must be 5 digits long.")
        }
        if(!this.dropoff_street){
          this.ride_errors.push("Dropoff Street is required.")
        }
        if(!this.dropoff_city){
          this.ride_errors.push("Dropoff City is required.")
        }
        if(!this.dropoff_state){
          this.ride_errors.push("Dropoff State is required.")
        }
        if(this.dropoff_zipcode.length !== 5){
          this.ride_errors.push("Dropoff Zipcode must be 5 digits long.")
        }
        if(!this.billing_id){
          this.ride_errors.push("Go back to Trip and add a Member ID.")
        }
        if(!this.first_name){
          this.ride_errors.push("Go back to Trip - First name required.")
        }
        if(!this.last_name){
          this.ride_errors.push("Go back to Trip - Last name required.")
        }
        if(!this.date_of_service){
          this.ride_errors.push("Go back to Trip - Date of Service required.")
        }




        if(this.ride_errors.length == 0){

          const payload = {
            "id" : this.counter,
            "billing_id" : this.billing_id.toUpperCase(),
            "first_name" : this.first_name.substring(0,8).toUpperCase(),
            "last_name" : this.last_name.substring(0,8).toUpperCase(),
            "date_of_service" : convertDates(this.date_of_service),
            "pickup_time" : convertTime(this.pickup_time),
            "pickup_street"  : this.pickup_street.toUpperCase(),
            "pickup_unit" : this.pickup_unit.toUpperCase(),
            "pickup_city" : this.pickup_city.toUpperCase(),
            "pickup_state" : this.pickup_state,
            "pickup_zipcode" : this.pickup_zipcode,

            "dropoff_time" : convertTime(this.dropoff_time),
            "dropoff_street"  : this.dropoff_street.toUpperCase(),
            "dropoff_unit" : this.dropoff_unit.toUpperCase(),
            "dropoff_city" : this.dropoff_city.toUpperCase(),
            "dropoff_state" : this.dropoff_state,
            "dropoff_zipcode" : this.dropoff_zipcode,
          }
          
          // If user selects round trip toggle create a round trip automatically
          if(this.round_trip_toggle) {
            this.counter = this.counter + 1
            const copiedRide = Object.assign({}, payload, { 
              id : this.counter,
              pickup_time : 'NS', // NOT SCHEDULED
              pickup_street: payload.dropoff_street,
              pickup_unit: payload.dropoff_unit,
              pickup_city: payload.dropoff_city,
              pickup_state: payload.dropoff_state,
              pickup_zipcode: payload.dropoff_zipcode,
              dropoff_street: payload.pickup_street,
              dropoff_unit: payload.pickup_unit,
              dropoff_city: payload.pickup_city,
              dropoff_state : payload.pickup_state,
              dropoff_zipcode: payload.pickup_zipcode,
              dropoff_time : 'NS'
            });
            this.rides.push(payload)
            this.rides.push(copiedRide)
            this.counter = this.counter + 1

            
          } else{
            // Push validated ride object to rides array
            this.counter = this.counter + 1
            this.rides.push(payload)
            
          }

          
          
          generateToast("Successfully added ride to this trip", "is-success")
          
          // Clear data for next ride entry
          this.pickup_time = ''
          this.pickup_street = ''
          this.pickup_unit = ''
          this.pickup_city = ''
          this.pickup_state = ''
          this.pickup_zipcode = ''

          this.dropoff_time = ''
          this.dropoff_street = ''
          this.dropoff_unit = ''
          this.dropoff_city = ''
          this.dropoff_state = ''
          this.dropoff_zipcode = ''
          
        

        } else {
          alert("FORM ERRORS: "+ " " + this.ride_errors)
          this.ride_errors = []
        }
      } else {
        generateToast("You cannot add more than 8 rides per trip", "is-warning")
      }
        
    },
    //
  
    pickupZipCodeFilterInput() {
      this.pickup_zipcode = this.pickup_zipcode.replace(/[^0-9]/g, '');
      
    },
    //
  
    dropoffZipCodeFilterInput() {
      this.dropoff_zipcode = this.dropoff_zipcode.replace(/[^0-9]/g, '');
      
    },
    //

    //
  
    phoneFilterInput() {
      this.phone_number = this.phone_number.replace(/[^0-9]/g, '');
    },
  
    //

    openCity(evt, cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    // evt.currentTarget.className += " active";
  },

    //
    async getFundingSource() {
       this.$store.commit("setIsLoading", true)
       this.fsloading = true;

     await axios
      .get("/api/v1/get-funding-sources/")
      .then((response) => {
          console.log("FS Response: ", response)
          this.funding_sources = response.data.content.Data
          this.$store.commit("setIsLoading", false)
          this.fsloading= false;
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
          this.fsloading= false;
        });


    },
    //
    async getSpaceType(){
       this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/get-space-types/")
      .then((response) => {
        if(response.status==200) {
          console.log("Space Types Response: ", response)
          this.space_types = response.data.content
          this.$store.commit("setIsLoading", false)
        }
        else{
           generateToast(`${response.data}`, "is-warning");
        }
          
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
    },

    //
    memberExists() {
      axios
      .post('api/v1/member_exists/', {'billing_id' : this.billing_id.toUpperCase()}, this.headers)
      .then((response) => {
        console.log('Member Exists: ', response)
        if(response.data.message == true){
          this.member_exists = true
          toast({
          message: `Member already exists, no need to add again.`,
          type: "is-warning",
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: "top-center",
          opacity: 0.9,
        });
        }else {
          this.member_exists = false
        }
      }).catch((error) => {
        console.log('Member Exists Error: ', error)
      })
    },
    
    //
    async submitForm() {
      if (!this.busy){
        this.busy = true
      
      this.$store.commit("setIsLoading", true);

      let exist = this.memberExists()

      if (
        this.$store.state.user.id &&
        this.billing_id &&
        this.first_name &&
        this.last_name &&
        this.diag_code &&
        this.phone &&
        this.date_of_birth &&
        this.funding_source &&
        this.space_type &&

        !this.member_exists
        
      ) {
        const request = {
          billing_id: this.billing_id.toUpperCase(),
          full_name:
            this.first_name.toUpperCase() + " " + this.last_name.toUpperCase(),
          first_name: this.first_name.toUpperCase(),
          last_name: this.last_name.toUpperCase(),
          date_of_birth: this.date_of_birth,
          diag_code: this.diag_code.toUpperCase(),
          gender: parseInt(this.gender),
          phone: this.phone,
          notes: this.notes,
          funding_source: this.funding_source.name,
          funding_source_id : this.funding_source.funding_source_id,
          space_type : this.space_type,
          created_by: this.$store.state.user.id,
        };
        // console.log("REQUEST: ", request);

        axios
          .post("/api/v1/new_member/", request, this.headers)

          .then((response) => {
            if (response.status == 200) {
              this.busy = false
              generateToast("New Member Created!", "is-success")
            }
            
        this.$router.push('/dashboard/member/')
            
          })
          .catch((error) => {
            this.busy = false
            generateToast(`Submission failed: ${error}`, "is-error")
          });
          
      } else {
        if (!this.$store.state.user.id) {
          this.errors.push(
            "User is not logged in, please sign out, and log back in."
          );
        }
        if (!this.funding_source) {
          this.errors.push("Missing Funding Source.");
        }
        if (!this.space_type) {
          this.errors.push("Missing Space Type.");
        }
        if (this.member_exists) {
          this.errors.push("Member Already Exists.");
        }
        if (!this.billing_id) {
          this.errors.push("Missing Billing ID.");
        }
        if (!this.first_name) {
          this.errors.push("Missing First Name.");
        }
        if (!this.last_name) {
          this.errors.push("Missing Last Name.");
        }
        if (!this.date_of_birth) {
          this.errors.push("Missing Date of Birth.");
        }
        if (!this.diag_code) {
          this.errors.push("Missing Diagnosis Code.");
        }
          if (!this.$store.state.user.id) {
            this.errors.push(
              "*****User ID is invalid, or missing from the store."
            );
          }
        
        if (!this.phone || this.phone.length < 10) {
          this.errors.push(
            "Missing Phone Number, or Phone Number is less than 10 digits."
          );
        }

        generateToast(`*** FORM ERRORS *** ${this.errors}`, "is-error")
        this.errors = [];
        this,busy=false
      }

      this.$store.commit("setIsLoading", false);
 
      } else{
        generateToast("System is processing - please wait...", "is-warning")
      }
    },
    //
    backToTrips() {
        this.$router.push("/dashboard/trip-manager");

    },

    //
    async getCompanyMeta() {
      this.$store.commit("setIsLoading", true);

      axios
        .get("/api/v1/company_meta/")
        .then((response) => {
          this.company_meta = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //
  },

  created() {
    document.title = "Trip Manager";
  },

};
</script>


    <style scoped>
input {
  text-align: center;
}

select {
  text-align: center;
}

select .submit-btn {
  width: 20px;
  margin: 0 auto;
}

.content {
  top: 50px;
  left: 15%;
}

/* DATE PICKER BELOW */

[type="date"] {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* custom styles */
body {
  padding: 4em;
  background: #e5e5e5;
}
label {
  display: block;
}
input {
  text-transform: uppercase;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
  height: 45px;
}

.gender {
  margin-bottom: 15px;
}

.create-form {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
}

.custom-control {
  width: 190px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.notes {
  min-width: 180px;
  max-width: 180px;
}

label  {
  margin-top: 10px;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #0033ff;
  background-color: #000000;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: rgb(0, 16, 123);
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #0033ff;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #0033ff;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 20px 12px;
  border: 1px solid #0033ff;
  border-top: none;
  background-color: rgba(0, 0, 0, 0.7)
  
}

.table-style {
  /* From https://css.glass */
  /* From https://css.glass */
  background: rgba(243, 240, 240, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
  overflow: auto;
  max-height: 500px;
  font-size: 0.8em;
}

.white-text {
  color: white;
}

.date-container {
  width: 190px;
}



buttons {
  margin-top: 35px;
}
.thead-dark {
  background-color: black;
  color: rgb(0, 149, 255);
}

.tr-dark{
  background-color: black;
  border: 1px solid blue;
  color: white;
}

td {
  font-size: 0.6em;
}

th {
  font-size: 0.6em;
}

.smaller-btn{
  width : 8px;
  height: 15px;
}

.container-css{
  overflow-y: scroll;
  height: 200px;
}
</style>

