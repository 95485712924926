<template>
  <div class="content">
    <div class="columns is-half">
      <div class="column is-half">
        <h3 class="page-title" style="color: white;">Create New Member</h3>
        <small v-if="fsloading" style="color: #0bfc03;">Funding Sources and Space Types Are Loading Please Wait...</small>

      </div>

      <div class="column is-half">
        <div class="is-small is-pulled-right">
          <button class="button is-dark" @click="backToMembers()">
            Back To Members
          </button>
          &zwnj; &zwnj;
          <button @click="submitForm()" class="button is-info">Submit</button>
        </div>
      </div>
    </div>

    <form @submit.prevent="submitForm">
      <div class="columns create-form">
        <div class="column">
          <div class="field">
            <label for="billing_id">Billing ID</label>
            <div class="control filter" >
              <input
                type="text"
                name="billing_id"
                class="input"
                style="text-transform:uppercase"
                maxlength="50"
                v-model="billing_id"
                :blur="memberExists()"
              />
            </div>
          </div>

          <div class="field">
            <label for="first_name">First Name</label>
            <div class="control">
              <input
                type="text"
                name="first_name"
                maxlength="50"
                class="input"
                v-model="first_name"
              />
            </div>
          </div>

          <div class="field">
            <label for="last_name">Last Name</label>
            <div class="control">
              <input
                type="text"
                name="last_name"
                maxlength="50"
                class="input"
                v-model="last_name"
              />
            </div>
          </div>

          <div class="field">
            <label for="date_of_birth">Date of Birth</label>
            <div class="control">
              <input type="date" name="date_of_birth" v-model="date_of_birth" />
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label for="diag_code">Diagnosis Code</label>
            <div class="control">
              <input
              pattern="[^()/><\][\\\x22,;|]+"
                type="text"
                name="diag_code"
                maxlength="20"
                class="input"
                v-model="diag_code"
              />
            </div>
          </div>

          <div class="field">
            <label for="gender">Gender</label>
            <div class="control">
              <select
                class="select custom-control"
                name="gender"
                v-model="gender"
              >
                <option value="1">Female</option>
                <option value="0">Male</option>
              </select>
            </div>
          </div>

          <div class="field">
            <label for="phone">Phone</label>
            <div class="control">
              <input
                name="phone"
                placeholder="Must be 10 digits"
                maxlength="10"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                class="input"
                v-model="phone"
              />
            </div>
          </div>

          <div class="field">
            <label for="funding_source">Funding Source Name</label>
            <div class="control">
              <select
                class="custom-control"
                name="funding_source"
                v-model="funding_source"
              >
                <option
                  v-for="source in funding_sources"
                  :key="source.id"
                  :value="source"
                >
                  {{ source.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

  <div class="column is-3">


        <label for="space_type">Space Type</label>
        <select v-model="space_type">
          <option v-for="st in space_types" :key="st.funding_source_id" :value="st.space_type">
              {{st.space_type}}
          </option>
        </select>
        
        </div>

 <div class="column is-3">
          <label for="notes">Notes</label>
          <textarea
            class="textarea notes"
            placeholder="Notes"
            name="notes"
            v-model="notes"
          ></textarea>
        </div>
    
      </div>
    </form>
  </div>
</template>

    <script>
import axios from "axios";
import { toast } from "bulma-toast";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
import { generateToast } from ".../../../common/toast.js";


export default {
  name: "CreateMember",

  mounted() {
    this.getCompanyMeta(); // bools to determine what fields to include in the form (example:  wait time, etc)
    this.getSpaceType();
    this.getFundingSource();
  },

  data() {
    return {
      fsloading : false,
      billing_id: '',
      first_name: '',
      last_name: '',
      diag_code: '',
      gender: '',
      phone: '',
      date_of_birth: '',
      funding_source: null, // null for boolean checkout in submitform
      funding_sources : '',
      notes: '',
      company_meta: '',
      errors: [],
      member_exists : '',
      space_types : '',
      space_type : '',
      busy: false,


      // Security
      headers : {headers:{"X-CSRFTOKEN": CSRF_TOKEN}}
    };
  },

  methods: {
    async getFundingSource() {
       this.$store.commit("setIsLoading", true)
       this.fsloading = true;

     await axios
      .get("/api/v1/get-funding-sources/")
      .then((response) => {
          console.log("FS Response: ", response)
          this.funding_sources = response.data.content.Data
          this.$store.commit("setIsLoading", false)
          this.fsloading= false;
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
          this.fsloading= false;
        });


    },
    //
    async getSpaceType(){
       this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/get-space-types/")
      .then((response) => {
        if(response.status==200) {
          console.log("Space Types Response: ", response)
          this.space_types = response.data.content
          this.$store.commit("setIsLoading", false)
        }
        else{
           generateToast(`${response.data}`, "is-warning");
        }
          
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
    },

    //
    memberExists() {
      axios
      .post('api/v1/member_exists/', {'billing_id' : this.billing_id.toUpperCase()}, this.headers)
      .then((response) => {
        console.log('Member Exists: ', response)
        if(response.data.message == true){
          this.member_exists = true
          toast({
          message: `Member already exists, no need to add again.`,
          type: "is-warning",
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: "top-center",
          opacity: 0.9,
        });
        }else {
          this.member_exists = false
        }
      }).catch((error) => {
        console.log('Member Exists Error: ', error)
      })
    },
    
    //
    async submitForm() {
      if (!this.busy){
        this.busy = true
      
      this.$store.commit("setIsLoading", true);

      let exist = this.memberExists()

      if (
        this.$store.state.user.id &&
        this.billing_id &&
        this.first_name &&
        this.last_name &&
        this.diag_code &&
        this.phone &&
        this.date_of_birth &&
        this.funding_source &&
        this.space_type &&

        !this.member_exists
        
      ) {
        const request = {
          billing_id: this.billing_id.toUpperCase(),
          full_name:
            this.first_name.toUpperCase() + " " + this.last_name.toUpperCase(),
          first_name: this.first_name.toUpperCase(),
          last_name: this.last_name.toUpperCase(),
          date_of_birth: this.date_of_birth,
          diag_code: this.diag_code.toUpperCase(),
          gender: parseInt(this.gender),
          phone: this.phone,
          notes: this.notes,
          funding_source: this.funding_source.name,
          funding_source_id : this.funding_source.funding_source_id,
          space_type : this.space_type,
          created_by: this.$store.state.user.id,
        };
        // console.log("REQUEST: ", request);

        axios
          .post("/api/v1/new_member/", request, this.headers)

          .then((response) => {
            if (response.status == 200) {
              this.busy = false
              generateToast("New Member Created!", "is-success")
            }
            
        this.$router.push('/dashboard/member/')
            
          })
          .catch((error) => {
            this.busy = false
            generateToast(`Submission failed: ${error}`, "is-error")
          });
          
      } else {
        if (!this.$store.state.user.id) {
          this.errors.push(
            "User is not logged in, please sign out, and log back in."
          );
        }
        if (!this.funding_source) {
          this.errors.push("Missing Funding Source.");
        }
        if (!this.space_type) {
          this.errors.push("Missing Space Type.");
        }
        if (this.member_exists) {
          this.errors.push("Member Already Exists.");
        }
        if (!this.billing_id) {
          this.errors.push("Missing Billing ID.");
        }
        if (!this.first_name) {
          this.errors.push("Missing First Name.");
        }
        if (!this.last_name) {
          this.errors.push("Missing Last Name.");
        }
        if (!this.date_of_birth) {
          this.errors.push("Missing Date of Birth.");
        }
        if (!this.diag_code) {
          this.errors.push("Missing Diagnosis Code.");
        }
          if (!this.$store.state.user.id) {
            this.errors.push(
              "*****User ID is invalid, or missing from the store."
            );
          }
        
        if (!this.phone || this.phone.length < 10) {
          this.errors.push(
            "Missing Phone Number, or Phone Number is less than 10 digits."
          );
        }

        generateToast(`*** FORM ERRORS *** ${this.errors}`, "is-error")
        this.errors = [];
        this,busy=false
      }

      this.$store.commit("setIsLoading", false);
 
      } else{
        generateToast("System is processing - please wait...", "is-warning")
      }
    },
    //
    backToMembers() {
        this.$router.push("/dashboard/member/");

    },

    //
    async getCompanyMeta() {
      this.$store.commit("setIsLoading", true);

      axios
        .get("/api/v1/company_meta/")
        .then((response) => {
          this.company_meta = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    //
  },

  created() {
    document.title = "Create Member";
  },

};
</script>


    <style scoped>
input {
  text-align: center;
}

select {
  text-align: center;
}

select .submit-btn {
  width: 20px;
  margin: 0 auto;
}

.content {
  top: 50px;
  left: 15%;
}

/* DATE PICKER BELOW */

[type="date"] {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* custom styles */
body {
  padding: 4em;
  background: #e5e5e5;
}
label {
  display: block;
}
input {
  text-transform: uppercase;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
  height: 45px;
}

.gender {
  margin-bottom: 15px;
}

.create-form {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
}

.custom-control {
  width: 190px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.notes {
  min-width: 180px;
  max-width: 180px;
}

label  {
  margin-top: 10px;
}
</style>

