<template>
    <div class="content">
      <h2 style="color: white;">Create Claim</h2>
      
<!-- {{ services }} -->
  
      <div class="columns is-multiline">
        <div class="column is-fullwidth">
          <div v-if="toggle" class="table-container">
            <table class="table table-style is-fullwidth">
              <thead>
                <tr style="font-size: 0.8em">
                  <th width="3%">
                    <input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                    />
                  </th>
                  <th width="3%">Member Name</th>
                  <th width="3%">Service Date</th>
                  <th width="3%">Charge Amount</th>
                  <th width="4%">Submitted Amount</th>
                  <th width="3%">Paid Amount</th>
                  <th width="4%">Claim Status</th>
                  <th width="1%">Tracking</th>
                  <th width="1%">Resub</th>
                </tr>
              </thead>
  
              <tbody>
                <tr v-for="claim in claims" :key="claim.id">
                  <td>
                    <input
                      type="checkbox"
                      :value="claim.id"
                      v-model="checkbox_claim_id"
                      @click="selectIndividualCheck"
                    />
                  </td>
  
                  <!-- Member name -->
                  <td class="truncate">
                    {{ claim.subscriber_last_name }},
                    {{ claim.subscriber_first_name }}
                    
                  </td>
  
                  <!-- Service date -->
                  <td class="truncate">{{ claim.encounter_start_date_of_service }}</td>
  
                  <!-- Charge amount -->
                  <td>${{ getTotal(claim.id) }}</td>
                  <!-- Submitted amount -->
                  <td>${{ claim.submitted_amount }}</td>
  
                   <!-- Paid amount -->
                  <td>${{ claim.paid_amount }}</td>
  
  
                 
                  <!-- <td v-if="claim.complete && claim.billed" style="color: green">Complete</td>
                  <td v-else-if="claim.in_process" style="color: orange">
                    Incomplete
                  </td>
                  <td v-else-if="claim.denied" style="color: red">Denied</td> -->
  
                  <!-- Claim status -->
                  <td class="truncate" v-if="!claim.billed">Ready</td>
                  <td class="truncate" v-else-if="claim.short && claim.resubmitted"> <span style="color:orange;">Short</span>  <span style="color: yellow;">(R)</span></td>
                  <td class="truncate" v-else-if="claim.denied && claim.resubmitted" > <span style="color: red;" >Denied</span>  <span style="color:yellow;">(R)</span> </td>
                  <td class="truncate" v-else-if="claim.denied" style="color: red;">Denied</td>
                  <td class="truncate" v-else-if="claim.short" style="color: orange;">Short</td>
                  <td class="truncate" v-else-if="claim.complete" style="color: #34eb3d;">Paid</td>
                  <td class="truncate" v-else-if="claim.resubmitted" style="color: orangered;">Resubmitted</td>
                  <td class="truncate" v-else style="font-weight: bold; color: skyblue;">In Process</td>
                  
                  

                  <td v-if="claim.rh_claim_number">{{ claim.rh_claim_number }}</td>
                  <td v-else>None</td>
  
                  <!-- Review -->
                  <td>
                    <a
                    @click="reviewClaim(claim.id)"
                      href="#"
                      class="button is-small is-info"
                      style="height: 20px"
                    >
                      Resub</a
                    >
                  </td>
  
  
                </tr>
              </tbody>
            </table>
  
            
          </div>

          <!-- :options="[{ id: 1, name: 'Option 1'}, { id: 2, name: 'Option 2'}]" -->
          <div v-else class="review">
            <div>
              <div>
                <small>Search Members by <span style="color: yellow;">Last Name</span> </small> <br>
                <div class="search-container">
                  <!-- <input type="text">
                <button @click="editRide()" class="button is-small is-dark" style="margin-left:2px; height: 24px;"> Select </button> -->
                
                <Dropdown
                  :options="members"
                  v-on:selected="validateSelection"
                  v-on:filter="getDropdownValues"
                  :disabled="false"
                  name="zipcode"
                  :maxItem="10000000"
                  placeholder="Please select an option">
              </Dropdown>
              <!-- {{ queried_member }} -->
                </div>
              </div>



                <div style="margin-top: 5px;">
                <button class="button is-info is-small" style="margin:2px;" @click="submitClaim()">Submit Claim</button>
                
                <!-- <button class="button is-success is-small" style="margin:2px;" @click="resubmit()">Resubmit</button> -->
                </div>

            </div>
            <br>
            <div v-if="member_search_query" class="columns top-form">

                <!-- COLUMN A -->
                <div  class="column is-one-quarter">
                    <label>First Name</label><br>
                    <input v-model="first_name" class="input edit-inputs is-small" type="text"  disabled>
                    
                    <br>
                    <label>Last Name</label><br>
                    <input v-model="last_name" class="input edit-inputs is-small" type="text" disabled>

                    <br>
                    <label>Gender</label><br>
                    <input v-if="gender" class="input edit-inputs is-small" value="Female" disabled>
                    <input v-else class="input edit-inputs is-small" value="Male" disabled>
                   

                    <br>
                    <label>Funding Source</label><br>
                    <select v-model="funding_source_name" class="input edit-inputs is-small" type="text">
                      <option v-for="fs in funding_sources" :key="fs.id" :value=fs.name>{{ fs.name }}</option>  
                    </select>

                    <br>
                    <label>Date of Service <span style="color: red;">*</span></label><br>
                    <div style="width: 200px">
                      <Datepicker
                    v-model="date_of_service"
                    :partialRange="false"
                    :enableTimePicker="false"

                  />
                    </div>
           
                    

                 
                </div>

                <!-- COLUMN B -->
                <div class="column is-one-quarter">
                    <label>Pickup Modifier</label><br>
                    <input v-model="pickup_modifier" class="input edit-inputs is-small" type="text" @input="ride_to_edit.pickup_modifier = $event.target.value.toUpperCase()">

                    <br>
                    <label>Pickup Street</label><br>
                    <input v-model="pickup_street" class="input edit-inputs is-small" type="text">

                    <br>
                    <label>Pickup City</label><br>
                    <input v-model="pickup_city" class="input edit-inputs is-small" type="text">

                    <br>
                    <label>Pickup State</label><br>
                    <input v-model="pickup_state" class="input edit-inputs is-small" type="text">
                    
                    <br>
                    <label>Pickup Zip</label><br>
                    <!-- <input class="input edit-inputs is-small" type="text" :value="ride_to_edit.pickup_zip"> -->
                    <input v-model="pickup_zip" class="input edit-inputs is-small" type="text" pattern="[0-9]{5}" maxlength="5">

                </div>

                <!-- COLUMN C -->
                <div class="column is-one-quarter">
                    <label>Dropoff Modifier</label><br>
                    <input v-model="dropoff_modifier" class="input edit-inputs is-small" type="text" @input="ride_to_edit.dropoff_modifier = $event.target.value.toUpperCase()">

                    <br>
                    <label>Dropoff Street</label><br>
                    <input v-model="dropoff_street" class="input edit-inputs is-small" type="text">

                    <br>
                    <label>Dropoff City</label><br>
                    <input v-model="dropoff_city" class="input edit-inputs is-small" type="text">

                    <br>
                    <label>Dropoff State</label><br>
                    <input v-model="dropoff_state"  class="input edit-inputs is-small" type="text">
                    
                    <br>
                    <label>Dropoff Zip</label><br>
                    <input v-model="dropoff_zip" class="input edit-inputs is-small" type="text">
                    
                </div>

                <!-- COLUMN D -->
                <div class="column is-one-quarter">


                    <label>Diagnosis Code</label><br>
                    <input v-model="diag_code" class="input edit-inputs is-small" type="text" disabled>
                    <br>


                    <label>Date of Birth</label><br>
                    <input v-model="date_of_birth" class="input edit-inputs is-small" type="text" disabled>
                    <br>

                    <label>Box 19</label><br>
                    <input class="input edit-inputs is-small" type="text" maxlength="80" placeholder="Optional">
                    <br>
                    <small>If you don't add anything to box 19, it will be automatically generated using the pickup and dropoff addresses.</small>

                    


                </div>
                
            <!-- END OF TOP FORM -->
            </div>

            <!-- START OF EDIT SERVICES -->

            <div v-if="member_search_query" class="services columns">
  
            <div class="column service-title"><h4 style="margin:0 0 0 150px; text-align:center; color:white;">Services</h4>
            <div class="total">Total Charge: ${{total_value.toFixed(2)}} </div> 

            <button class="button is-success is-small is-pulled-right" style="margin-right: 25px;" @click="addService()">Add</button>
            </div>

            <div class="return-button" >
          
            </div>
            <br>
            <hr style="margin:0; background-color: #ddd;">
            
            
          </div>
  
          <div v-if="member_search_query" class="services-data">
            <table>
              <tr>
                <!-- <th>Claim ID</th> -->
                <th>Code Class <span style="color: red">*</span> </th>
                <th>Code <span style="color: red">*</span></th>
                <th>Units <span style="color: red">*</span></th>
                <th>Rate <span style="color: red">*</span></th>
                <th>Charge <span style="color: red">*</span></th>
                <th width="10%">Loc Code<span style="color: red">*</span></th>
                <th width="10%">Mod 1</th>
                <th width="10%">Mod 2</th>


                <th>&zwj;</th>
 
              </tr>
              
              <tr v-for="service in services" :key=service.id>
                <!-- CODE CLASS -->
                <td>
                <select v-model="service.code_class" class="input edit-selects is-small" type="text">
                    <option >{{ service.code_class }}</option>
                    <option>LOAD</option>
                    <option>MILE</option>
                    <option>WAIT</option>
                </select>
            </td>
            <!-- BILLING CODES -->
            <td>
                <select class="input edit-selects is-small" type="text" v-model="service.code" >
                    <option>an option</option>
                    <option v-for="code in billing_codes" :key=code.id>{{ code.code }}</option>
                </select>
            </td>

            <!-- UNITS -->
            <td>
            <input v-model="service.units" class="input edit-selects is-small" type="number" >
        </td>
            <!-- RATE -->
            <td>
                <input v-model="service.rate" class="input edit-selects is-small" type="number">
            </td>

            <!-- CHARGE -->
            <td>
                <input @change="updateCharges()" v-model="service.charge" class="input edit-selects is-small" type="number" >

            </td>

            <!-- MODIFIERS -->

                
            <td>
                  <input v-model="service.location_mod" class="input edit-selects is-small" type="text" @input="service.location_mod = $event.target.value.toUpperCase()"> 
                </td>


                <td>
                    <input v-model="service.modifier_set[0]" class="input edit-selects is-small" type="text" @input="service.mod1 = $event.target.value.toUpperCase()"> 
                    
                </td>
                
                <td>
                  <input v-model="service.modifier_set[1]" class="input edit-selects is-small" type="text" @input="service.mod2 = $event.target.value.toUpperCase()" > 
                    
                </td>

                <!-- <td>
                  <input v-model="service.modifier_set[2]" class="input edit-selects is-small" type="text" @input="service.mod3 = $event.target.value.toUpperCase()"> 
                </td> -->

                

         

                <td> <button class="button is-small is-danger" @click="deleteService(service)">X</button> </td>


                
  
              </tr>
            </table>
  
            
          </div>
          <!-- RIDES -->
          <br>
     
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Datepicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
  import { CSRF_TOKEN } from "../../../common/csrf_token.js";
  import { generateToast } from ".../../../common/toast.js";
  import FileReader from "@/components/FileReader.vue";
  import { convertDates } from ".../../../common/converters.js";
  import Dropdown from "@/components/Dropdown.vue";
  
  export default {
    components: { Datepicker, FileReader, Dropdown },
  
    name: "Claims",
    data() {
      return {
        new_toggle : true,
        billing_codes : null,
        box_19 : '',
        rate_classes : null,
        data: "",
        claims: [],
        rides : [],
        service_claim: [],
        search_term: "",
        search_query: null,
        funding_sources: [],
        funding_source: "",
        date: "",
        busy: false,
        allSelected: false,
        total_value : 0,
        checkbox_claim_id: [],
        times_resubmitted : null,
        last_updated : null,
        rides_databank : [], // stores rides that have been queried for edit and returns rides that have been edited in place of rides that would be sent along with 'rides' in resbumit()
        members : null,
        queried_member : "No member selected",
        member_search_query : null,
        new_claim_created : false,
        
        // Top form
        first_name : null,
        last_name : null,
        funding_source_name : null,
        funding_source_id : null,
        date_of_service : null,
        gender : null,
        pickup_modifier : null,
        pickup_street : null,
        pickup_city : null,
        pickup_state : null,
        pickup_zip : null,
        dropoff_modifier : null,
        dropoff_street : null,
        dropoff_city : null,
        dropoff_state : null,
        dropoff_zip : null,
        diag_code : null,
        date_of_birth : null,
        box_19 : '',
        temp_service_id : 0,
        company_fs : [],

        // Services
        services : [],


        headers: {
          headers: {
            "X-CSRFTOKEN": CSRF_TOKEN,
            Authorization: `Token ${this.$store.state.token}`,
          },
        },
      };
    },


  
    mounted() {
      this.getFundingSource();
      this.getLatestClaims();
      this.allowedBillingCodes();
      this.getMembers() 
      this.getCompanyFundingSource()
    },
  
    created() {
      document.title = "Claims";
    },


//////////////////////
    methods: {



      async getCompanyFundingSource() {
        // Get the company funding sources
        this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/funding_source_list/")
      .then((response) => {
          console.log("Funding Sources: ",response)
          this.company_fs = response.data
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
      },

      async submitClaim(){

        // Check to make sure the services are all fully filled in - uses the null as a boolean to indicate which fields are required in addService
        function hasValues(obj) {
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] === null)) {
              return false;
            }
          }
          return true;
        }

        for (var i = 0; i < this.services.length; i++) {
          if (!hasValues(this.services[i])) {
            generateToast("Check services, you missed something... Modifiers are the only fields allowed to be blank.", "is-warning")
            return false
          }
        }

        if(!this.date_of_service) {
          generateToast("Date of service is missing, please add it to continue.", "is-warning")
            return false
        }
      var x = confirm("Are you sure you want to submit this claim?")
      if (x) {
        this.$store.commit("setIsLoading", true)
        generateToast("Creating and submitting claim, please wait...", "is-success")
        

      const top_form = {
        "date_of_service" : this.date_of_service.toLocaleString(),
        "member_id" : this.queried_member.member_id,
        'member_name' : `${this.queried_member.last_name}, ${this.queried_member.first_name}`,
        "funding_source_id" : this.funding_source_id,
        "pickup_modifier" : this.pickup_modifier,
        "pickup_street" : this.pickup_street,
        "pickup_city" : this.pickup_city,
        "pickup_state" : this.pickup_state,
        "pickup_zip" : this.pickup_zip,
        "dropoff_modifier" : this.dropoff_modifier,
        "dropoff_street" : this.dropoff_street,
        "dropoff_city" : this.dropoff_city,
        "dropoff_state" : this.dropoff_state,
        "dropoff_zip" : this.dropoff_zip,
        "box_19" : this.box_19
      }

      let data = {"top_form" : top_form, "services" : this.services, "charges" : this.total_value}
      await axios
      .post("/api/v1/submit-unique-claim/", data, this.headers)
      .then((response) => {
          console.log("Create claim Response: ", response)
          generateToast("Success", "is-success")
          this.$store.commit("setIsLoading", false)
          // this.$router.push('/dashboard/create-claim/') // refresh page
          this.date_of_service = null
          
      })
      .catch((error) => {
          console.log(error)
          generateToast(`Submission failed: ${error}`, "is-danger")

          this.$store.commit("setIsLoading", false)
          this.$router.push('/dashboard/create-claim/') // refresh page
        });
      }
  

    },

//
      async getMostRecentClaim() {
        // gets the most recent claim for the member
        this.$store.commit("setIsLoading", true)
        let data = {"member" : this.queried_member}
        this.services = []
        await axios
          .post("/api/v1/most-recent-claim/", data, this.headers)
          .then((response) => {
            console.log("Most Recent Claim Response: ", response)
            let res = response.data
            this.pickup_modifier = res.pickup_modifier
            this.pickup_street = res.pickup_street
            this.pickup_city = res.pickup_city
            this.pickup_state = res.pickup_state
            this.pickup_zip = res.pickup_zip
            this.dropoff_modifier = res.dropoff_modifier
            this.dropoff_street = res.dropoff_street
            this.dropoff_city = res.dropoff_city
            this.dropoff_state = res.dropoff_state
            this.dropoff_zip = res.dropoff_zip
            if(res.services){
              this.services = res.services
            } else {
              this.services = []
            }
            this.$store.commit("setIsLoading", false)

            this.updateCharges()
            this.temp_service_id = 0
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });
      },
//
      modifierSet(modifier_set) {
        // Create string from modifier set
      let codes = []
      const new_modifier_set = Object.assign({}, modifier_set);
      for (const [key, value] of Object.entries(new_modifier_set)) {
        console.log(`${key}: ${value}`);
        this.modifier_codes.push(value)
      }
  
      let final_codes = codes.toString()
      return final_codes
     
      },
//
      getDropdownValues(value){
        // gets the value from the dropdown
        const billing_id = value.split("-")[1]
        this.member_search_query = billing_id
        this.memberSearch()
        
      },
//
      async memberSearch() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/member-search/?search=${this.member_search_query}&ordering=last_name`
        )
        .then((response) => {
          console.log('Member Search: ', response)
          this.queried_member = response.data[0];
          this.first_name = this.queried_member.first_name
          this.last_name = this.queried_member.last_name
          this.funding_source_name = this.queried_member.funding_source_name
          this.funding_source_id = this.queried_member.funding_source_id
          this.gender = this.queried_member.gender
          this.diag_code = this.queried_member.diag_code
          this.date_of_birth = this.queried_member.date_of_birth

          this.getMostRecentClaim() // gets the most recent claim for the member and populates the form

        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
//

      async getMembers() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/all-members/")
        .then((response) => {
          this.members = response.data;
          console.log("Members: ", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },

//
      addService() {
       
       const blank_service = {
              "id": this.temp_service_id,
              "code": null,
              "code_class": null,
              "location_mod" : null,
              "rate": null,
              "units": null,
              "charge": null,
              "modifier_set" : [],
            }
          this.services.push(blank_service)
          this.temp_service_id++;
          console.log("Temp service ID: " + this.temp_service_id)

      },
//
      async deleteService(service) {
        console.log('Delete a service here')
        let serviceToRemove = this.services.findIndex(obj => obj.id === service.id);

        this.services.splice(serviceToRemove,1)
        this.updateCharges()


      },
      //


      onSubmit() {
        // console.log(this.form);
        // post the form to the server
      },

//
    updateCharges(){
      // Dynamically update prices based on charges in ride to edit
      const arr = []
      for(var i = 0; i < this.services.length; i++) {
        arr.push(this.services[i].charge)
      }
      this.total_value = this.sumArray(arr)
    },
//
    sumArray(numbers){
        let sum = 0;
        numbers.forEach((number) => {
        sum += number;
        });
      return sum

    },


//
    async allowedBillingCodes(){
    this.$store.commit("setIsLoading", true)

        await axios
        .get("/api/v1/allowed-billing-codes/")
        .then((response) => {
            console.log("Allowed Billing Codes Response: ", response)
            this.billing_codes = response.data
            this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
            });
    },
//


  
         //
      searchClaims() {
        if(!this.busy) {
          if (this.funding_source){
          this.busy = true;
          this.toggle=true;
        
        if (this.date && this.date.length == 2) {
          this.$store.commit("setIsLoading", true);
  
          let start_date = convertDates(this.date[0]);
          let end_date = convertDates(this.date[1]);
  
          let url = `/api/v1/claims-by-date/?min_date=${start_date}&max_date=${end_date}&funding_source_id=${this.funding_source}`;
          // console.log(url);
          axios
            .get(url)
            .then((response) => {
              this.claims = response.data;
              console.log("CLAIMS DATA: ",response.data);
              this.$store.commit("setIsLoading", false);
              this.busy = false;
  
            // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
            this.service_claim = []
            for(var i = 0; i < response.data.length; i++){
              this.getAllServices(response.data[i].id)
              // console.log(this.service_claim)
            }
            })
            .catch((error) => {
              console.log(error);
              this.$store.commit("setIsLoading", false);
              this.busy = false;
            });
  
          
        } else {
          generateToast("Please select dates.", "is-warning");
          this.$store.commit("setIsLoading", false);
        }
        } else{
          generateToast("Please select a funding source.", "is-warning")
        }
        } else {
          generateToast("System is busy, please wait...", "is-warning");
          this.$store.commit("setIsLoading", false);
        }
        
      },


//
      async getClaim(claim_id) {
        // Get claim to show resubmission info
        this.$store.commit("setIsLoading", true)

        await axios
          .get(`/api/v1/claim-by-id/?claim_id=${claim_id}`)
          .then((response) => {
            console.log("getClaim Response: ", response)
            this.times_resubmitted = response.data[0].times_resubmitted
            var dt = response.data[0].last_updated
            var dt = new Date(dt)
            var dt = dt.toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});

            this.last_updated = dt
            this.$store.commit("setIsLoading", false)
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });


      },
//
       async getRides(claim_id) {
        this.$store.commit("setIsLoading", true);
  
        await axios
          .get(`/api/v1/claim-rides/?claim_id=${claim_id}`)
          .then((response) => {
            console.log("Rides Response: ", response);
            this.rides = response.data;
            this.$store.commit("setIsLoading", false);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
      },
  //
      async getLatestClaims() {
        this.$store.commit("setIsLoading", true);
  
        await axios
          .get("/api/v1/latest_claims/")
          .then((response) => {
            console.log("Latest Claims Response: ", response);
            this.claims = response.data;
            this.$store.commit("setIsLoading", false);
  
            // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
            this.service_claim = []
            for(var i = 0; i < response.data.length; i++){
              this.getAllServices(response.data[i].id)
              // console.log(this.service_claim)
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
      },
  
      //
      async syncClaimStatus() {
        this.$store.commit("setIsLoading", true);
        let data = { ids: this.checkbox_claim_id };
        await axios
          .post("/api/v1/sync-claims/", data, this.headers)
          .then((response) => {
            console.log("Sync Claims Response: ", response);
            this.claims = response.data;
            this.$store.commit("setIsLoading", false);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
      },
      //

  //
      async getFundingSource() {
         this.$store.commit("setIsLoading", true);
         this.fsloading = true;
  
       await axios
        .get("/api/v1/get-funding-sources/")
        .then((response) => {
            console.log("FS Response: ", response)
            this.funding_sources = response.data.content.Data
            this.$store.commit("setIsLoading", false)
            this.fsloading = false;
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
            this.fsloading = false;
          });
  
  
      },
  //
      addModifiers(){
        // add modifiers to services from their object
        for(var i = 0; i < this.services.length; i++) {
          const modifier_set = this.services[i].modifier_set;
          const values = Object.values(modifier_set);
          const value = values.length ? values : ''; // if values keep them as an array, if there are no values return empty string
         
          // Place all the active modifiers in their places if they exist
          if(value != ''){ 

            try { 
              this.services[i].mod1 = value[0]
            }catch {
              console.log("No modifier at the 0 index of this service", this.services[i])
            }

            try {
              this.services[i].mod2 = value[1]
            }
            catch {
              console.log("No modifier at the 1 index of this service", this.services[i])
            }

            try {
              this.services[i].mod3 = value[2]
            }
            catch {
              console.log("No modifier at the 2 index of this service", this.services[i])
            }

            }
            
        }

        

      },
  //
  
      async getServices(claim_id){
         this.$store.commit("setIsLoading", true)
  
       await axios
        .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
        .then((response) => {
            console.log("Services Response: ", response)
            this.services = response.data
            this.addModifiers(this.services)
  
            var count = 0.0
            for (var i = 0; i < response.data.length; i++) {
              count += response.data[i].charge
            }
            this.total_value = count
         
            this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });
  
  
      },
  //
      getTotal(claim_id){
        // Loop to find a matching claim id in service_claim, if match found, return value.
        for (var i = 0; i < this.service_claim.length; i++) {
          if(this.service_claim[i]['claim_id'] == claim_id){
            return this.service_claim[i]['total'].toFixed(2)
          }
        }
      },
  //
    getAllServices(claim_id){
        // Gets services and organizes them for getTotal to call in the template and retrieve total for each claim
        this.$store.commit("setIsLoading", true)
  
        axios
        .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
        .then((response) => {
  
            var count = 0.0
            for (var i = 0; i < response.data.length; i++) {
              count += response.data[i].charge
            }
            this.$store.commit("setIsLoading", false)
  
            this.service_claim.push({ "claim_id" : claim_id, "total": count})
         
  
        })
        .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });
  
  
      },
  
      //
      selectAll() {
        this.allSelected = !this.allSelected; // Set the opposite bool at start, but let default be false
        this.checkbox_claim_id = [];
  
        if (this.allSelected) {
          for (const r in this.claims) {
            var claim_id = this.claims[r].id;
            this.checkbox_claim_id.push(claim_id);
          }
        } else {
          this.checkbox_claim_id = [];
        }
      },
  
      selectIndividualCheck() {
        this.allSelected = false;
      },
    },
    //
  
    
  };
  </script>
  
  <style scoped>
  .controls {
    width: 80%;
    margin: 0 auto;
  }
  
  .content {
    width: 100%;
    top: 50px;
  }
  
  .table-container {
    width: 100%;
    height: 600px;
    overflow: auto;
    overflow-x: hidden;
    font-size: 0.9em;
  }
  
  .select-control {
    height: 42px;
  }
  
  .custom-select {
    height: 38px;
    width: 100%;
    margin-right: 5px;
    border: 1px solid #ddd;
  }
  
  .really-small {
    font-size: 0.7em;
  }
  
  .custom-control {
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
    height: 170px;
    margin-bottom: 15px;
    padding: 10px;
    text-align: center;
  }
  
  .table-style {
    /* From https://css.glass */
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
    overflow: auto;
    max-height: 500px;
    font-size: 0.8em;
  }
  
  table td {
    text-align: center;
  }
  
  table tr {
    text-align: center;
  }
  
  .truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
  }
  
  .upload {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .upload-form {
    text-align: center;
    margin-left: 5px;
    margin-top: 5px;
    width: 250px;
  }
  
  /* Tooltip container */
  /* .tooltip-patch {
    margin-top: -10px; 
    margin-left: 5px;
  
  
  } */
  
  .middle-btn {
    text-align: center;
  
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .review {
    padding: 10px;
    font-size :.7em;
    color: white;
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
  }
  
  .content table th {
    color: white;
  }
  
  tr:hover td {
    background:blue;
  }
  
  .edit-inputs{
    width:200px; 
    text-align: center; 
    margin-bottom: 5px;
    text-transform:uppercase;
  }

  .edit-selects{
    width:100px; 
    text-align: center; 
    margin-bottom: 5px;
    text-transform:uppercase;
  }

  .total {
    font-weight : bold;
    font-size : 2em;
  }

  table {
        border-collapse: collapse;
        width: 100%;
    }
    th {
        background-color: black;
        position: sticky;
        top: 0px;
        z-index: 1; /* Add z-index */
        
    }
    th, td {
        text-align: left;
        padding: 8px;
        border: 2px solid red;
    }
  </style>