<template>
  <div class="content">

    <div class="columns">
      <div class="column is-3 custom-form">
        <small>Add Rate Class</small>

        <div class="field" style="margin-top: 10px">
          <label for="rate_id">Rate ID</label>
          <input type="text" v-model="rate_id" class="input" />
        </div>

        <label for="funding_source">Funding Source</label>
        <div class="field">
          <select
            class="custom-control"
            style="width: 100%"
            name="funding_source"
            v-model="funding_source"
          >
            <option
              v-for="source in stored_funding_sources"
              :key="source"
              :value="source.id"
            >
              {{ source.name }}
            </option>
          </select>
        </div>



        <label for="load">Load</label>
        <div class="field">
          <input
            type="number"
            v-model="load"
            min="1"
            step="any"
            class="custom-control"
          />
        </div>

        <label for="mile_charge">Mile Charge</label>
        <div class="field">
          <input
            type="number"
            v-model="mile_charge"
            min="1"
            step="any"
            class="custom-control"
          />
        </div>

        <label for="mile_charge">Wait Unit Charge</label>
        <div class="field">
          <input
            type="number"
            v-model="wait_unit_charge"
            min="1"
            class="custom-control"
          />
        </div>

        <div class="button is-primary">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "RateClassForm",

    data() {
      return {
              stored_funding_sources: [],
        }
    },

    methods: {
//
        async getFundingSource() {
      this.$store.commit("setIsLoading", true);

      axios
        .get("/api/v1/funding_sources/")
        .then((response) => {
          this.stored_funding_sources = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
        },
//
    async createRateClass(){
        // Dont forget to tie the funding source in here and correlate it to the source id
    },
//

        
    },

    mounted() {
        this.getFundingSource();
    },

    created() {
        document.title="Add Rate Class"
    }
    
  };
</script>


<style scoped>
.content {
  top: 50px;
}
.custom-control {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.custom-form{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
margin-top: 10px;
text-align: center;
  /* margin: 0 auto; */
}

input {
  text-align: center;
}
</style>
