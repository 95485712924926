<template>
  <div class="container">
    <img src="~@/assets/img/pnr_infinity.png" class="logo-design" alt="" />
    <div class="columns">
      <div class="login-form">
        <!-- <h1 class="title">Log in</h1> -->

        <form @submit.prevent="submitForm">
          <div class="field">
            <label>Company ID</label>
            <div class="control">
              <input
                type="email"
                name="email"
                class="input"
                v-model="username"
              />
            </div>
          </div>

          <div class="field">
            <label>Password</label>
            <div class="control">
              <input
                type="password"
                name="password"
                class="input"
                v-model="password"
              />
            </div>
          </div>

          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>

          <div class="field">
            <div class="control">
              <button class="button is-info is-centered is-half">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LogIn",
  data() {
    return {
      username: "",
      password: "",
      errors: [],
      notifications : [],
    };
  },
  methods: {
    created() {
      this.checkNotifications();
      document.title = "Login";
    },
    //
    
    async checkNotifications() {
      // Check all endpoints in this list, if there are any records post to vuex store
      this.$store.commit("setIsLoading", true);

      // Unregistered Members
      await axios
        .get("/api/v1/unregistered-member/")
        .then((response) => {
          console.log("Unreg Member: ", response.data);
          for (var i = 0; i < response.data.length; i++) {
            this.notifications.push({
              desc: "Unknown Member",
              url: "/dashboard/unregistered-member/",
              data1: `${response.data[i].first_name}`,
              data2: `${response.data[i].last_name}`,
            });

            this.$store.commit("setNotifications", this.notifications);
          }
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    async submitForm() {
      this.$store.commit("setIsLoading", true);

      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");

      const formData = {
        username: this.username,
        password: this.password,
      };

      await axios
        .post("/api/v1/token/login/", formData)
        .then((response) => {
          const token = response.data.auth_token;

          this.$store.commit("setToken", token);

          axios.defaults.headers.common["Authorization"] = "Token " + token;

          localStorage.setItem("token", token);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
        });

      await axios
        .get("/api/v1/users/me/")
        .then((response) => {
          // console.log('Users / me: ', response.data)
          this.$store.commit("setUser", {
            id: response.data.id,
            username: response.data.username,
          });

          localStorage.setItem("username", response.data.username);
          localStorage.setItem("userid", response.data.id);
        })
        .catch((error) => {
          console.log(error);
        });

      this.$router.push("/");

      this.$store.commit("setIsLoading", false);
    },
  },
  //
};
</script>

<style scoped>
.container {
  /* border: 1px solid blue; */
  width: 50%;
  text-align: center;
  margin-left: 33%;
}
.logo-design {
  /* border: 1px solid red; */
  width: 40%;
  margin: 0 auto;
  margin-bottom: 25px;
}

.login-form {
  margin: 0 auto;
}
</style>