<template>
  <div class="content">
      <h3 class="page-title">Secret Dungeon 404</h3>

      <p class="center">You have stumbled upon a secret page which you must never stumble upon again!</p>
      <div class="btn-div center" style="width: 100%">
      <a href="#" class="center button is-info" @click="goBack">Click here to return to safety </a>

      </div>
    </div>
</template>

<script>

  export default {
    name: "PageNotFound",

    data() {
      return {

        }
    },

    methods: {

      goBack(){
        this.$router.push('/')
      },

    },

    mounted() {

    },

    created() {
        document.title="Secret Dungeon 404"
    }
    
  };
</script>

<style scoped>

.page-title {
  margin-top: 80px;
  text-align: center;
}
.center {
  text-align: center;
}

</style>