<template>
  <div class="content">
   
      <h4 class="page-title" style="color: white;">Transport Sync</h4>
      <!-- <input type="text" class="input search" placeholder="Search"> -->
    <div class="is-centered main-container">
       
        <pre class="json-format" v-for="record in transport_sync_records"  :key="record.id">ID:{{record.id}}<br>{{record.desc}}<br><span style="color: green; font-weight: bold;">Record Created: {{record.created_at}} <br></span>{{JSON.stringify( record.payload, null, 2) }}</pre>
    </div>

    
  </div>
</template>

<script>
import axios from "axios";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
export default {
  name: "Transport Sync",

  mounted() {
      this.getTransportSyncRecords();
  },

  data() {
    return {
      transport_sync_records : null,
      // Security
      headers: { headers: { "X-CSRFTOKEN": CSRF_TOKEN } },
    };
  },


  methods: {      
    async getTransportSyncRecords() {
      // Retrieves all TSR query objects from the past month from database
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/trasport-sync-records/")
        .then((response) => {
          console.log("Transport Sync Response: ", response);
          this.transport_sync_records = response.data;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
  },



  created() {
    document.title = "Transport Sync";
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  text-align: center;
}
.main-container {
  width: 60%;
  height: 700px;;

  margin: 0 auto;


  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  overflow: auto;

  font-size: 0.8em;
}

.json-format {
    text-align: left;
    opacity: 0.8;
    background-color: black;
    color: #00ff1a;
}

.page-title{
      margin-top: 25px;
    margin-bottom: 10px;
}

.search {
  border-radius: 15px;
  text-align: center;
  width: 620px;
}
</style>
