<template>
  <div class="content">

    <div class="columns">
      <div class="column is-3 custom-form">
        <small>Add Integration</small>

        <div class="field" style="margin-top: 10px">
          <label for="name">Integration Name</label>
          <input type="text" v-model="name" name="name" class="input" />
        </div>

        <label for="category">Category</label>
        <select name="category">
          <option value="billing">Billing</option>
          <option value="accounting">Accounting</option>
          <option value="payment_processing">Payment Processing</option>
        </select>

        <div class="field" style="margin-top: 10px">
          <label for="user_id">User ID</label>
          <input type="text" v-model="user_id" name="user_id" class="input" />
        </div>

        <div class="field" style="margin-top: 10px">
          <label for="password">Password</label>
          <input type="text" v-model="password" name="password" class="input" />
        </div>

        <div class="button is-primary">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "IntegrationForm",

    data() {
      return {
              name : '',
              user_id : '',
              password : '',
              category : '',
        }
    },

    methods: {
//
    async createIntegration(){
         this.$store.commit("setIsLoading", true)

      axios
      .get("/api/v1/endpoint/")
      .then((response) => {
          this.object = response.data
      })
      .catch((error) => {
          console.log(error)
        });

      this.$store.commit("setIsLoading", false)
    },
//

        
    },

    created() {
        document.title="Add an Integration"
    }
    
  };
</script>


<style scoped>
.content {
  top: 50px;
}
.custom-control {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.custom-form{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
margin-top: 10px;
text-align: center;
  /* margin: 0 auto; */
}

input {
  text-align: center;
}
</style>
