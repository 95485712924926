<template>
  <div class="content">


    </div>
</template>

<script>
  import axios from 'axios'
  import { CSRF_TOKEN } from "../../../common/csrf_token.js"; //MAKE SURE THIS PATH IS CORRECT
  export default {
    name: "NameGoesHere",

    data() {
      return {
        // Security
        headers : {headers:{"X-CSRFTOKEN": CSRF_TOKEN}}
        }
    },

    methods: {


    },

    mounted() {

    },

    created() {
        document.title="Login"
    }
    
  };
</script>

<style scoped>
#col {
    text-align: center;
}




</style>

