import { createStore } from 'vuex'

export default createStore({
  state: {
    setIsLoading: false,
    isLoading: false,
    isAuthenticated: false,
    token: '',
    user : {id: 0, username : ''},
    notifications : [],

  },
  mutations: {

    initializeStore(state) {
      if (localStorage.getItem('token')) {
        console.log(localStorage.getItem('token'))
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
        state.user.username = localStorage.getItem('username')
        state.user.id = localStorage.getItem('userid')
      } else {
        state.token = ''
        state.isAuthenticated = false
        state.user.id = 0
        state.user.username = ''
      }
    },

    setLoadingStatus(state, status) {
      state.isLoading = status
    },

    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
      state.user = {id: 0, username : ''}
    },

    setIsLoading(state, status) {
      state.isLoading = status
    },

    setUser(state, user) {
      state.user = user
    },

    setNotifications(state, notifications) {
      state.notifications = notifications
    },

    purgeNotifications(state) {
      state.notifications = []
    },



    



  },
  actions: {
  },
  modules: {
  }
})
