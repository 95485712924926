import { toast } from "bulma-toast";
function generateToast(message, condition) {
    toast({
      message: `${message}`,
      type: `${condition}`,
      dismissible: true,
      pauseOnHover: true,
      duration: 5000,
      position: "top-center",
      opacity: 0.9,
    });
  }

  export {generateToast}

