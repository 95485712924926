<template>
  <div class="content">
    <h2 style="color: white;">Claims</h2>
    <div class="columns custom-control">
      <div class="column left">
        <div class="fs-container">
          <small>Select Funding Source</small>
          <select class="select custom-select" v-model="funding_source">
            <option
              :value="fs.funding_source_id"
              v-for="fs in funding_sources"
              :key="fs.id"
            >
              {{ fs.name }}
            </option>
          </select>
        </div>

        <div>
          <small>Search Date Range</small>
          <Datepicker
            v-model="date"
            range
            :partialRange="false"
            :enableTimePicker="false"
            :maxRange="31"
            @keydown.enter="searchClaims"
          />
        </div>

        <div class="search-by-name">
          <small>Search By Name</small>
          <input
            type="text"
            class="input search-by-name"
            maxRange="25"
            placeholder="Search By Name"
            v-model="search_term"
            @keydown.enter="searchByName"
            @focusout="searchByName"
          />
        </div>

      </div>
      <div class="column middle"></div>

      <!-- EDI UPLOAD FORM -->
      <div class="column right">
  

        <!-- DELETE CLAIMS -->
        <div class="middle-btn">
          <button
          v-if="toggle"
            @click="deleteClaims()"
            class="button is-small is-danger is-rounded"
          >
            Delete Selected
          </button>
        </div>
      </div>

    </div>

    <div class="columns is-multiline">
      <div class="column is-fullwidth">
        <div v-if="toggle" class="table-container">
          <table class="table table-style is-fullwidth">
            <thead>
              <tr style="font-size: 0.8em">
                <th width="3%">
                  <input
                    type="checkbox"
                    @click="selectAll"
                    v-model="allSelected"
                  />
                </th>
                <th width="3%">Member Name</th>
                <th width="3%">Service Date</th>
                <th width="3%">Charge Amount</th>
                <th width="4%">Submitted Amount</th>
                <th width="3%">Paid Amount</th>
                <th width="4%">Claim Status</th>
                <th width="1%">Review</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="claim in claims" :key="claim.id">
                <td>
                  <input
                    type="checkbox"
                    :value="claim.id"
                    v-model="checkbox_claim_id"
                    @click="selectIndividualCheck"
                  />
                </td>

                <!-- Member name -->
                <td class="truncate">
                  {{ claim.subscriber_last_name }},
                  {{ claim.subscriber_first_name }}
                  
                </td>

                <!-- Service date -->
                <td class="truncate">{{ claim.encounter_start_date_of_service }}</td>

                <!-- Charge amount -->
                <td>${{ getTotal(claim.id) }}</td>
                <!-- Submitted amount -->
                <td>${{ claim.submitted_amount }}</td>

                 <!-- Paid amount -->
                <td>${{ claim.paid_amount }}</td>


               
                <!-- <td v-if="claim.complete" style="color: green">Complete</td>
                <td v-else-if="claim.in_process" style="color: orange">
                  Incomplete
                </td>
                <td v-else-if="claim.denied" style="color: red">Denied</td> -->

                <!-- Claim status -->
                <td class="truncate" v-if="!claim.billed">Ready</td>
                  <td class="truncate" v-else-if="claim.accounting_migrated"  style="color: #34eb3d;">Migrated</td>
                  <td class="truncate" v-else-if="claim.denied" style="color: red;">Denied</td>
                  <td class="truncate" v-else-if="claim.short" style="color: orange;">Short</td>
                  <td class="truncate" v-else-if="claim.complete" style="color: #34eb3d;">Paid</td>
                  <td class="truncate" v-else style="font-weight: bold; color: skyblue;">In Process</td>
                
          
                <!-- Review -->
                <td>
                  <a
                  @click="reviewClaim(claim.id)"
                    href="#"
                    class="button is-small is-info"
                    style="height: 20px"
                  >
                    Review</a
                  >
                </td>



              </tr>
            </tbody>
          </table>

          
        </div>

        <div v-else class="review">
          <div class="services columns">

          <div class="column service-title"><h4 style="margin:0 0 0 150px; text-align:center; color:white;">Services</h4>
          <div class="total">Total Charge: {{total_value.toFixed(2)}} </div>
          </div>
          
          <div class="return-button" >
          <button class="button is-info is-small" style="margin:10px;" @click="toggle=true">Return to Claims</button>
        
          </div>
          <br>
          <hr style="margin:0; background-color: #ddd;">
          
          
        </div>

        <div class="services-data">
          <table>
            <tr>
              <th>Claim ID</th>
              <th>Code Class</th>
              <th>Code</th>
              <th>Units</th>
              <th>Rate</th>
              <th>Charge</th>
              <th width="25%">Modifiers</th>
              <th>Location</th>
              <th>Member Name</th>
              <th>Date of Service</th>     
            </tr>
            <tr v-for="service in services" :key=service.id>
              <td>{{service.claim}}</td>
              <td>{{service.code_class}}</td>
              <td>{{service.code}}</td>
              <td>{{service.units}}</td>
              <td>{{service.rate}}</td>
              <td>{{service.charge}}</td>
              <td>
                {{modifierSet(service.modifier_set)}}</td>
                <td>{{service.location_mod}}</td>
              <td>{{service.member_name}}</td>
              <td>{{service.date_of_service}}</td>
              

            </tr>
          </table>

          
        </div>
        <!-- RIDES -->
        <br>
    <h4 style="margin: 0 auto; color:white; width: 30px;">Rides</h4>
            <table>
              <thead>
                <tr style="font-size: 0.8em">
                  <th width="10%">Member Name</th>
                  <th width="10%">Service Date</th>
                  <th width="20%">Pickup</th>
                  <th width="20%">Dropoff</th>
                  <th width="5%">Location Mod</th>
                </tr>
              </thead>

              <tbody>
                <!-- MEMBER NAME -->
                <tr v-for="ride in rides" :key="ride.id">
                  <td>{{ ride.first_name }} {{ ride.last_name }}</td>

                  <!-- SERVICE DATE -->
                  <td>{{ ride.date_of_service }}</td>

                  <!-- PICKUP -->
                  <td>{{ ride.pickup_street }} {{ ride.pickup_zip }}</td>

                  <!-- DROPOFF -->
                  <td>{{ ride.dropoff_street }} {{ ride.dropoff_zip }}</td>

                  <!-- LOCATION -->
                  <td>{{ ride.pickup_modifier }}{{ ride.dropoff_modifier }}</td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
import { generateToast } from ".../../../common/toast.js";
import FileReader from "@/components/FileReader.vue";
import { convertDates } from ".../../../common/converters.js";


export default {
  components: { Datepicker, FileReader },

  name: "Claims",
  data() {
    return {
      data: "",
      toggle : true,
      claims: [],
      rides : [],
      services : [],
      service_claim: [],
      search_term: "",
      search_query: null,
      funding_sources: [],
      funding_source: "",
      date: "",
      busy: false,
      allSelected: false,
      total_value : 0,
      checkbox_claim_id: [],
      headers: {
        headers: {
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: `Token ${this.$store.state.token}`,
        },
      },
    };
  },

  mounted() {
    this.getFundingSource();
    this.getLatestClaims();
  },

  created() {
    document.title = "Claims";
  },

  methods: {
    onSubmit() {
      console.log(this.form);
      // post the form to the server
    },

    async deleteClaims(){
      if (!this.busy){
        this.busy=true
      var x = confirm("This action cannot be undone, are you sure you want to do this?")
      if(x){
      this.$store.commit("setIsLoading", true)
      if(this.checkbox_claim_id.length > 0){

     await axios
      .post("/api/v1/delete-claims/", this.checkbox_claim_id, this.headers)
      .then((response) => {
          console.log("Delete Claims Response: ", response)
          generateToast(`${response.data.message}`, "is-success")
          this.$store.commit("setIsLoading", false)
          this.busy = false
          setTimeout(this.$router.go, 3000);
          
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
          this.busy = false
        });
      } else {
        generateToast("Please select a claim to delete.", "is-warning")
         this.$store.commit("setIsLoading", false)
         this.busy = false
      }
      } else{
        this.busy =false
      }
      } else {
        generateToast("System is busy, please wait...", "is-warning")
        this.busy = false
      }

    },


    modifierSet(modifier_set) {
    let codes = []
    const new_modifier_set = Object.assign({}, modifier_set);
    for (const [key, value] of Object.entries(new_modifier_set)) {
      console.log(`${key}: ${value}`);
      codes.push(value)
    }

    let final_codes = codes.toString()
    return final_codes
   
    },

       //
    searchClaims() {
      if(!this.busy) {
        if (this.funding_source){
        this.busy = true;
        this.toggle=true;
      
      if (this.date && this.date.length == 2) {
        this.$store.commit("setIsLoading", true);

        let start_date = convertDates(this.date[0]);
        let end_date = convertDates(this.date[1]);

        let url = `/api/v1/claims-by-date/?min_date=${start_date}&max_date=${end_date}&funding_source_id=${this.funding_source}`;
        console.log(url);
        axios
          .get(url)
          .then((response) => {
            this.claims = response.data;
            console.log(response.data);
            this.$store.commit("setIsLoading", false);
            this.busy = false;

          // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
          this.service_claim = []
          for(var i = 0; i < response.data.length; i++){
            this.getAllServices(response.data[i].id)
            console.log(this.service_claim)
          }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
            this.busy = false;
          });

        
      } else {
        generateToast("Please select dates.", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
      } else{
        generateToast("Please select a funding source.", "is-warning")
      }
      } else {
        generateToast("System is busy, please wait...", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
      
    },
    //
    async reviewClaim(claim_id){
      this.toggle=false
      console.log(claim_id)
      await this.getServices(claim_id)
      await this.getRides(claim_id)
    },
    //
     async getRides(claim_id) {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/claim-rides/?claim_id=${claim_id}`)
        .then((response) => {
          console.log("Rides Response: ", response);
          this.rides = response.data;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
//
    async getLatestClaims() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/latest_claims/")
        .then((response) => {
          console.log("Latest Claims Response: ", response);
          this.claims = response.data;
          this.$store.commit("setIsLoading", false);

          // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
          this.service_claim = []
          for(var i = 0; i < response.data.length; i++){
            this.getAllServices(response.data[i].id)
            console.log(this.service_claim)
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },

    //
    async syncClaimStatus() {
      this.$store.commit("setIsLoading", true);
      let data = { ids: this.checkbox_claim_id };
      await axios
        .post("/api/v1/sync-claims/", data, this.headers)
        .then((response) => {
          console.log("Sync Claims Response: ", response);
          this.claims = response.data;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    async searchByName() {
      if (!this.busy) {
        if(this.funding_source){
        this.busy = true;
        this.toggle = true;
        this.$store.commit("setIsLoading", true);

        axios
          .get(
            `/api/v1/claims-by-name/?search=${this.search_term}&funding_source_id=${this.funding_source}`
          )
          .then((response) => {
            console.log("Search By Name Response: ", response);
            this.claims = response.data;
            this.$store.commit("setIsLoading", false);
            this.busy = false;

            // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
          this.service_claim = []
          for(var i = 0; i < response.data.length; i++){
            this.getAllServices(response.data[i].id)
            console.log(this.service_claim)
          }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
          } else{
        generateToast("Please select a funding source.", "is-warning")
      }
      } else {
        generateToast("System is busy, please wait...", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
      
    },
//
    async getFundingSource() {
       this.$store.commit("setIsLoading", true);
       this.fsloading = true;

     await axios
      .get("/api/v1/get-funding-sources/")
      .then((response) => {
          console.log("FS Response: ", response)
          this.funding_sources = response.data.content.Data
          this.$store.commit("setIsLoading", false)
          this.fsloading = false;
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
          this.fsloading = false;
        });


    },
//

    async getServices(claim_id){
       this.$store.commit("setIsLoading", true)

     await axios
      .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
      .then((response) => {
          console.log("Services Response: ", response)
          this.services = response.data

          var count = 0.0
          for (var i = 0; i < response.data.length; i++) {
            count += response.data[i].charge
          }
          this.total_value = count
       
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });


    },
//
    getTotal(claim_id){
      // Loop to find a matching claim id in service_claim, if match found, return value.
      for (var i = 0; i < this.service_claim.length; i++) {
        if(this.service_claim[i]['claim_id'] == claim_id){
          return this.service_claim[i]['total'].toFixed(2)
        }
      }
    },
//
  getAllServices(claim_id){
      // Gets services and organizes them for getTotal to call in the template and retrieve total for each claim
      this.$store.commit("setIsLoading", true)

      axios
      .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
      .then((response) => {

          var count = 0.0
          for (var i = 0; i < response.data.length; i++) {
            count += response.data[i].charge
          }
          this.$store.commit("setIsLoading", false)

          this.service_claim.push({ "claim_id" : claim_id, "total": count})
       

      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });


    },

    //
    selectAll() {
      this.allSelected = !this.allSelected; // Set the opposite bool at start, but let default be false
      this.checkbox_claim_id = [];

      if (this.allSelected) {
        for (const r in this.claims) {
          var claim_id = this.claims[r].id;
          this.checkbox_claim_id.push(claim_id);
        }
      } else {
        this.checkbox_claim_id = [];
      }
    },

    selectIndividualCheck() {
      this.allSelected = false;
    },
  },
  //

  
};
</script>

<style scoped>
.controls {
  width: 80%;
  margin: 0 auto;
}

.content {
  width: 100%;
  top: 50px;
}

.table-container {
  width: 100%;
  height: 600px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 0.9em;
}

.select-control {
  height: 42px;
}

.custom-select {
  height: 38px;
  width: 100%;
  margin-right: 5px;
  border: 1px solid #ddd;
}

.really-small {
  font-size: 0.7em;
}

.custom-control {
/* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
  height: 220px;
  margin-bottom: 15px;
  padding: 10px;
  text-align: center;
}

.table-style {
  /* From https://css.glass */
/* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
  overflow: auto;
  max-height: 500px;
  font-size: 0.8em;
}

table td {
  text-align: center;
}

table tr {
  text-align: center;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-form {
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  width: 250px;
}

/* Tooltip container */
/* .tooltip-patch {
  margin-top: -10px; 
  margin-left: 5px;


} */

.middle-btn {
  text-align: center;

  margin-top: 5px;
  margin-bottom: 5px;
}

.review {
  padding: 10px;
  font-size :.7em;
  color: white;
/* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
}

.content table th {
  color: white;
}

tr:hover td {
  background:blue;
}

table {
        border-collapse: collapse;
        width: 100%;
    }
    th {
        background-color: black;
        position: sticky;
        top: 0px;
        z-index: 1; /* Add z-index */
        
    }
    th, td {
        text-align: left;
        padding: 8px;
        border: 2px solid red;
    }
</style>