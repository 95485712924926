<template>
  <div v-if="$store.state.user.username != 'onboard@pionir.ai'" class="content">
    <!-- <h1>THIS IS THE DASHBOARD - CHART COMPONENTS / VISUAL DATA GOES HERE</h1> -->
    <h1 style="color: white">Welcome.</h1>
    <p>Please select a module from the sidebar.</p>

  </div>

  <!-- IF ONBOARDING LOGGED IN -->
  <div v-else class="content">
    <h4 style="color: white">ONBOARDING</h4>

    <div :class="[modal_active ? 'modal is-active' : 'modal']">
      <div class="has-text-white has-background-black"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Errors</p>
          <button
            @click="resetModal"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <ul>
            <li v-for="data in modal_data" :key="data.message">
              {{ data }}
            </li>
          </ul>
        </section>
        <footer class="modal-card-foot">
          <!-- <button class="button is-success">Save changes</button> -->
          <button @click="resetModal" class="button">Close</button>
        </footer>
      </div>
    </div>

    <!-- Tab links -->
    <div class="tab">
      <button class="tablinks active" @click="openCity($event, 'step_1')">
        Step 1
      </button>
      <button class="tablinks" @click="openCity($event, 'step_2')">
        Step 2
      </button>
      <button class="tablinks" @click="openCity($event, 'step_3')">
        Step 3
      </button>
      <button class="tablinks" @click="openCity($event, 'step_4')">
        Step 4
      </button>
      <button class="tablinks" @click="openCity($event, 'step_5')">
        Step 5
      </button>
    </div>

    <!-- Tab content -->
    <div id="step_1" class="tabcontent" style="display: block">
      <div class="columns">
        <div class="column is-4">
          <p style="color: white; font-weight: bold">Register Client</p>
          <p style="color: grey">
            Username must be an email format, and the password must be at least
            8 characters long. Easy to guess  passwords are not allowed.
          </p>
        </div>

        <div class="column is-4">
          <!-- REGISTER CLIENT -->

          <form @submit.prevent="submitForm">
            <div class="field">
              <small>Username (Must be email)</small>
              <div class="control">
                <input
                  type="email"
                  name="email"
                  class="input"
                  v-model="username"
                />
              </div>
            </div>

            <div class="field">
              <label>Password</label>
              <div class="control">
                <input
                  type="password"
                  name="password1"
                  class="input"
                  v-model="password1"
                />
              </div>
              {{ password1 }}
            </div>

            <div class="field">
              <label>Repeat password</label>
              <div class="control">
                <input
                  type="password"
                  name="password2"
                  class="input"
                  v-model="password2"
                />
              </div>
              {{ password2 }}
            </div>

            <div class="notification is-danger" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>

            <div class="field">
              <div class="control">
                <button class="button is-info">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- INTEGRATION DATA -->
    <div id="step_2" class="tabcontent">
      <div class="columns">
        <!-- INTEGRATION TITLE -->
        <div class="column is-4">
          <small style="color: white; font-weight: bold">Add Integration</small>
          <p style="color: grey">
            Select a user from registered users, select a supported integration,
            API Key, Client ID and Client Passphrase are provided by the
            integration. You will need to ask them about those credentials. Add
            the integration's API Key and
            <span style="font-weight: bold; color: red">double check</span> to
            ensure it's correct.
          </p>
        </div>

        <!-- INTEG COLUMN 1 -->
        <div class="column is-4">
          <small>Select User</small><br />
          <select
            style="width: 100%"
            class="select"
            name="registered_user"
            v-model="selected_user"
          >
            <option v-for="user in users" :key="user.id" :value="user">
              {{ user.username }}
            </option>
          </select>
          <br />

          <small>Integration Name</small><br />
          <select style="width: 100%" class="select" v-model="integration_name">
            <option value="Mediroutes">Mediroutes</option>
            <option disabled value="Quickbooks">Quickbooks</option>
            <option disabled value="Stripe">Stripe</option>
            <option disabled value="Expensify">Expensify</option>
          </select>

          <br />
          <small>Transportation Provider API Key</small>
          <input class="input" type="text" v-model="api_key" />
        </div>

        <!-- INTEG COLUMN 2 -->
        <div class="column is-4">
          <small>Client ID</small>
          <input class="input" type="text" v-model="client_id" />
          <small>Client Passphrase</small>
          <input class="input" type="text" v-model="client_passphrase" />
          <small>Auth Address</small>
          <input disabled class="input" type="text" v-model="auth_address" />

          <button
            v-if="verify_success"
            style="margin-top: 10px"
            class="button is-primary"
            @click="submitInteg"
          >
            Add
          </button>
          <button
            v-if="!verify_success"
            style="margin-top: 10px"
            class="button is-info"
            @click="verifyCreds"
          >
            Verify
          </button>
        </div>
      </div>
    </div>

    <!-- // GENERAL SETTINGS -->
    <div id="step_3" class="tabcontent">
      <div class="columns">
        <div class="column is-4">
          <small>Select User</small><br />
          <select
            style="width: 100%"
            class="select"
            name="registered_user"
            v-model="selected_user"
          >
            <option v-for="user in users" :key="user.id" :value="user">
              {{ user.username }}
            </option>
          </select>

          <small>NPI</small>
          <input
            maxlength="30"
            class="input"
            type="text"
            v-model="npi"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          />

          <small>TIN</small>
          <input
            maxlength="30"
            class="input"
            type="text"
            v-model="tin"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          />

          <small>Company Name</small>
          <input
            style="text-transform: uppercase"
            maxlength="30"
            class="input"
            type="text"
            v-model="company_name"
          />

          <small>Billing Phone</small>
          <input
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            maxlength="10"
            class="input"
            type="text"
            v-model="billing_phone"
          />
        </div>

        <div class="column is-4">
          <small>Street Address</small>

          <input
            maxlength="30"
            style="text-transform: uppercase"
            class="input"
            type="text"
            v-model="street_address"
          />

          <small>City</small>
          <input
            style="text-transform: uppercase"
            class="input"
            maxlength="30"
            type="text"
            v-model="city"
          />

          <small>State</small>
          <input
            style="text-transform: uppercase"
            class="input"
            type="text"
            maxlength="2"
            v-model="state"
          />

          <small>Zip Code</small>
          <input
            maxlength="9"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            class="input"
            type="text"
            v-model="zip_code"
          />
        </div>

        <div class="column is-4">
          <small>Has Wait Time</small>
          <select class="select" style="width: 100%" v-model="has_wait_units">
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>

          <small>Has Dry Runs</small>
          <select class="select" style="width: 100%" v-model="has_dry_runs">
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>

          <div>
            <br />
            <button class="button is-info" @click="submitCompanyInfo">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- SYNC MEMBERS -->
    <div id="step_4" class="tabcontent">
      <div class="columns">
        <!-- COL 1 -->
        <div class="column is-4">
          <p>Sync Members</p>
          <small style="color:grey;"
            >This will import all members (riders) from the selected
            integration. And change their Unique ID to one designated by
            PIONIR.</small
          >
        </div>

        <!-- COL 2 -->
        <div class="column is-4">
          <!-- Select user -->
          <small>Select User</small><br />
          <select
            style="width: 100%; margin-bottom: 5px;"
            class="select"
            name="registered_user"
            v-model="selected_user">

            <option v-for="user in users" :key="user.id" :value="user">
              {{ user.username }}
            </option>
          </select>

          <!-- Select integration -->
          <small>Select Integration</small>
          <select 
            style="width: 100%; margin-bottom: 5px;"
            class="select"
            v-model="member_sync_integration">
            <option value="Mediroutes">Mediroutes</option>
          </select>
          <hr style="background-color: grey; margin-top: 0.5rem; margin-right: 0px; margin-bottom: 0.5rem; margin-left: 0px;">
          
          <!-- Upload form -->
          <div class="upload-form" style="display: inline-block;">
          <small>Member Upload</small><br />
          <FileReader @load="file_data = $event"></FileReader>
          <button @click="syncMembers" class="button is-dark is-small">
            Upload
          </button>
        </div>
          <hr style="background-color: grey; margin-top: 0.5rem; margin-right: 0px; margin-bottom: 0.5rem; margin-left: 0px;">

          <div >
            <button style="margin-top: 5px;" class="button is-info" @click="syncMembers">Sync Members</button>
          </div>
        </div>
         <!-- END COL 2 -->

        <!-- COL 3 -->
        <div class="column is-4">
        </div>
      </div>
    </div>

    <div id="step_5" class="tabcontent">
      <div class="columns">
      <!-- COL 1 -->
        <div class="column is-4">
          <p>Add Billing Codes</p>
          <small style="color:grey;">
            Add billing codes for this client. If you don't see a billing code in the select that you're looking for, please contact the dev team.
          </small>
        </div>
        <!-- COL 2 -->
          <!-- Select code -->
        <div class="column is-4">
           <small>Select User</small><br />

        <!-- Select user -->
          <select
            style="width: 100%; margin-bottom: 5px;"
            class="select"
            name="registered_user"
            v-model="selected_user">

            <option v-for="user in users" :key="user.id" :value="user">
              {{ user.username }}
            </option>
          </select>

          <small>Code</small>
          <select
            style="width: 100%; margin-bottom: 5px;"
            class="select"
            name="code_selection"
            v-model="code_selection">

            <option v-for="code in billing_codes" :key="code.id" :value="code">
             {{code.code}} ({{ code.code_class }})
            </option>
          </select>

          <button @click="addBillingCode" class="button is-info" >Add</button>

        </div>
        <!-- COL 3 -->
        <div class="column is-4">
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { generateToast } from ".../../../common/toast.js";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
import FileReader from "@/components/FileReader.vue";


export default {
  components : {
    FileReader
  },
  created() {
    document.title = "Dashboard";
  },
  data() {
    return {
      member_sync_integration : "Mediroutes",
      billing_codes : null,
      code_selection : null,

      file_data : '',
      fsloading : false,
      funding_sources : null,
      selected_fs : null,

      verify_success: false,
      company_name: null,
      street_address: null,
      city: null,
      state: null,
      zip_code: null,
      billing_phone: null,
      has_wait_units: true,
      has_dry_runs: true,
      npi: null,
      tin: null,

      modal_active: false,
      modal_data: [],

      api_key: null,
      client_id: null,
      client_passphrase: null,
      integration_name: "Mediroutes",
      auth_address: "https://external.mediroutesapi.com/token",
      username: "",
      password1: "",
      password2: "",
      errors: [],
      user_id: "",
      selected_user: "",
      users: "",
      new_user: "",
      headers: {
        headers: {
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: `Token ${this.$store.state.token}`,
        },
      },

    };
  },

  
  created() {
        document.title="Dashboard"
    },
    

  mounted() {
    this.GetUsers();
    this.allowedBillingCodes();
    
  },

  methods: {


    async logout() {
      await axios
        .post("/api/v1/token/logout/")
        .then((response) => {
          console.log("Logged out");
          this.$store.commit("purgeNotifications")
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });

      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      this.$store.commit("removeToken");

      this.$router.push("/login");
    },


async addBillingCode() {
    // Add billing code to selected user
    if (this.code_selection && this.selected_user) {
    this.$store.commit("setIsLoading", true)
    let data = {"user" : this.selected_user, "code" : this.code_selection}
     await axios
      .post("/api/v1/add-billing-code/", data, this.headers)
      .then((response) => {
          console.log(" Add Billing Code Response: ", response)
          if(response.status == 200) {
            generateToast("Successfully added", "is-success")
          }
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          generateToast(`Error: ${error.response.data.message}`, "is-danger")
          this.$store.commit("setIsLoading", false)
        });
    } else {
      generateToast("Please make sure both User and Code are selected.", "is-warning")
    }
    },


async allowedBillingCodes(){
  this.$store.commit("setIsLoading", true)

     await axios
      .get("/api/v1/allowed-billing-codes/")
      .then((response) => {
          console.log("Allowed Billing Codes Response: ", response)
          this.billing_codes = response.data
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
},
//
async syncMembers(){
  // Imports riders using integration credentials sends to backend for processing
  if (this.file_data && this.selected_user){
  this.$store.commit("setIsLoading", true)
  let data = {
    "file_data" : this.file_data,
    "selected_user" : this.selected_user,
    "member_sync_integration" : this.member_sync_integration
    }

     await axios
      .post("/api/v1/sync-members/", data, this.headers)
      .then((response) => {
          // console.log("Sync Members Response: ", response)
          generateToast(`${response.data.message}`, "is-success")
          this.$store.commit("setIsLoading", false)
      })
      .catch((error) => {
          // console.log(error)
          generateToast(`${error.data.message}`, "is-success")
          this.$store.commit("setIsLoading", false)
        });

  } else {
    generateToast("Please make sure you selected user, and a file for upload.", "is-warning")
  }
},
//
    async verifyCreds() {
      // Verify the credentials entered exist, if they do then try to retrieve a 200 using credentials
      if (this.client_id && this.client_passphrase && this.api_key) {
        let params = {
          grant_type: "password",
          client_id: this.client_id,
          client_passphrase: this.client_passphrase,
          auth_address: this.auth_address,
        };

        axios
          .post("/api/v1/verify-integration/", params)
          .then((response) => {
            if (response.status == 200) {
              this.verify_success = true;
              generateToast(
                "Successfully validated credentials!",
                "is-success"
              );
            }
          })
          .catch((error) => {
            console.log("VERIFICATION ERROR: ", error);
            generateToast(
              "Verification Failed due to server error, or incorrect credentials",
              "is-warning"
            );
          });
      } else {
        generateToast("Please enter all required credentials", "is-warning");
      }
    },
    //
    openCity(evt, tabName) {
      // Declare all variables
      console.log(evt);
      var i, tabcontent, tablinks;

      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      // Show the current tab, and add an "active" class to the button that opened the tab
      document.getElementById(tabName).style.display = "block";
      evt.currentTarget.className += " active";
    },
    //
    async submitCompanyInfo() {
      let valid = true;
      if (!this.selected_user) {
        valid = false;
        generateToast("User is invalid, or not selected", "is-warning");
      }
      if (!this.company_name) {
        valid = false;
        generateToast("Company name is invalid, or not selected", "is-warning");
      }
      if (!this.street_address) {
        valid = false;
        generateToast(
          "Street address is invalid, or not selected",
          "is-warning"
        );
      }
      if (!this.city) {
        valid = false;
        generateToast("City is invalid, or not selected", "is-warning");
      }
      if (!this.state || this.state < 2) {
        valid = false;
        generateToast("State must be 2 chars long", "is-warning");
      }
      if (!this.zip_code || this.zip_code.length < 9) {
        valid = false;
        generateToast("Zip must be 9 chars long", "is-warning");
      }
      if (!this.billing_phone || this.billing_phone.length < 10) {
        valid = false;
        generateToast("Phone must be 10 chars long", "is-warning");
      }
      if (!this.npi) {
        valid = false;
        generateToast("NPI must be added.", "is-warning");
      }
      if (!this.tin) {
        valid = false;
        generateToast("TIN must be added.", "is-warning");
      }

      if (valid) {
        this.$store.commit("setIsLoading", true);
        let payload = {
          user: this.selected_user.id,
          company_name: this.company_name,
          street_address: this.street_address,
          city: this.city,
          state: this.state,
          zip_code: this.zip_code,
          billing_phone: this.billing_phone,
          has_wait_units: this.has_wait_units,
          has_dry_runs: this.has_dry_runs,
          npi: this.npi,
          tin: this.tin,
        };

        await axios
          .post("/api/v1/add-company-info/", payload, this.headers)
          .then((response) => {
            generateToast(`${response.data.message}`, "is-success");
            this.$store.commit("setIsLoading", false);
          })
          .catch((error) => {
            console.log(error);
            generateToast(`${error.message}`, "is-warning");
            this.$store.commit("setIsLoading", false);
          });
      }
    },
    //
    resetModal() {
      this.modal_data = [];
      this.modal_active = false;
    },
    //
    async submitInteg() {
      // Submit integtration data
      let integ_errors = [];
      let x = confirm(
        "Please double check, make sure the data you're about to enter is correct. If you want to go back, hit cancel."
      );
      if (x) {
        // Check for errors
        if (!this.selected_user && this.selected_user != "Select User") {
          integ_errors.push("User is required.");
        }
        if (!this.api_key) {
          integ_errors.push("API Key is required.");
        }
        if (this.integration_name == "Mediroutes") {
          if (this.api_key.length < 36 || this.api_key.length > 36) {
            integ_errors.push("Mediroutes API key must be 36 characters long.");
          }
        }
        if (!this.client_id) {
          integ_errors.push("Client ID is required.");
        }
        if (!this.client_passphrase) {
          integ_errors.push("Client passphrase is required.");
        }

        if (!integ_errors.length) {
          this.$store.commit("setIsLoading", true);
          let integ_data = {
            name: this.integration_name,
            api_key: this.api_key,
            client_id: this.client_id,
            client_passphrase: this.client_passphrase,
            auth_address: this.auth_address,
            created_by: this.selected_user.id,
          };

          await axios
            .post("/api/v1/add-integration/", integ_data)
            .then((response) => {
              this.$store.commit("setIsLoading", false);
              generateToast("Successfully added integration", "is-success");
              this.api_key = ""
              this.client_id = ""
              this.client_passphrase = ""
              this.selected_user = ""
              this.funding_sources = response.data.message.Data
              console.log(response)

            })
            .catch((error) => {
              console.log(error);
              generateToast(`You cannot add the same integration more than once: ${error}`, "is-warning");
              this.$store.commit("setIsLoading", false);
            });
        } else {
          for (var i = 0; i < integ_errors.length; i++) {
            this.modal_data.push(integ_errors[i]);
          }
          this.modal_active = true;
        }
      }
    },
    //
    async GetUsers() {
      await axios
        .get("/api/v1/user-list/")
        .then((response) => {
          let users = response.data;
          let user_arr = [];
          for (var i = 0; i < users.length; i++) {
            if (
              users[i].username != "onboard@pionir.ai" &&
              users[i].username != "cyberpunk666"
            ) {
              user_arr.push(users[i]);
            }
          }
          this.users = user_arr;
          this.users.unshift({ username: "Select User" });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //
    async CreateICN(response) {
      // Create ICN when user is created successfully
      await axios
        .post(
          "/api/v1/create-icn/",
          { new_user: response.data.id },
          this.headers
        )
        .then((response) => {
          console.log("ICN CREATED: ", response);
          if (response.status == 200) {
            generateToast("ICN created successfully", "is-success");
          } else {
            generateToast("ICN creation failed ${}", "is-danger");
          }
        })
        .catch((error) => {
          generateToast(`${error.response.data.message}`);
          console.log("Unable to generate ICN after user creation.");
        });
    },
    //
    async submitForm() {
      // Create User
      this.errors = [];

      if (this.username === "") {
        this.errors.push("The username is missing");
      }

      if (this.password1 === "") {
        this.errors.push("The password is too short");
      }

      if (this.password1 !== this.password2) {
        this.errors.push("The password are not matching");
      }

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);

        await axios
          .post("/api/v1/users/", {
            username: this.username,
            password: this.password1,
          })
          .then((response) => {
            this.user_id = response.data.id;
            this.new_user = response.data.username;
            this.CreateICN(response);
            this.GetUsers();
            console.log("USER CREATED: ", response);
            this.user_id = "";
            this.password1 = "";
            this.password2 = "";
            generateToast("User profile created successfully", "is-success");
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            } else if (error.message) {
              this.errors.push("Something went wrong. Please try again!");
            }
          });

        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>

<style scoped>
/* @import "https://www.w3schools.com/w3css/4/w3.css"; */

.content {
  width: 100%;
  top: 50px;
}

.create-user {
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
}

.modal-card-head {
  background-color: black;
  height: 40px;
  padding-top: 40px;
  border: 1px solid blue;
  color: white;
  opacity: 0.9;
}

.modal-card-foot {
  background-color: black;
  opacity: 0.9;
  border: 1px solid blue;
  color: white;
}

.modal-card-title {
  color: white;
  opacity: 0.9;
}

.modal-card-body {
  opacity: 0.9;
  background-color: black;
  border: 1px solid blue;
  border-bottom: 1px solid black;
  color: white;
}

.modal {
  top: 0px;
}
tr:hover td {
  background: blue;
}

.genset {
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;

  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
}

.toptext{
  text-align: center;

}

</style>