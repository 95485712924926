
<template>
  <div class="content" >
    <h2 style="color: white">Accounting</h2>
    <div class="columns custom-control">
      <div class="column is-4 left">
        <!-- Watching Claims: {{watching_claims}} -->

        <div>
          <small>Search Date Range</small>
          <Datepicker v-model="date" range :ShortRange="false" :enableTimePicker="false" :maxRange="90"
            @keydown.enter="searchClaims" />
          <!-- <button
            @click="searchClaims"
              style="margin-top: 5px; width: 100%"
              class="button is-dark is-small"
            >
              Search Dates</button><br /> -->
        </div>
        <br>
        <div class="payments" v-if="payments && date">
          <label for="payments">Payments in Range</label>
          <br>
          <select name="payments" class="select" v-model="selected_payment" @change="getPayments">
            <option v-for="payment in payments" :key=payment.id :value=payment>PMT#: {{ payment.payment_number }} | DATE:
              {{ payment.payment_date }} | C: {{ payment.complete }}</option>
          </select>

        </div>

        <div>

          <button v-if="date" @click="getPayments" class="button is-success is-small" style="margin:5px;">Search
            Payments</button>
          <button v-if="date && payments" @click="searchPayment" class="button is-info is-small"
            style="margin:5px;">Search
            Selected Payment</button>
        </div>


      </div>

      <div class="column is-4 middle">
        <div class="upload-form" style="display: inline-block;">
          <small>Upload EDI 835</small><br />
          <FileReader @load="file_data = $event"></FileReader>
          <button @click="dataToBackend" class="button is-dark is-small">
            Upload
          </button>
          
        </div>
      </div>

      <div class="column is-4 right">
        <!-- <small>NOTE: If the amount doesn't match, check notifications, it is likely PNR doesnt have a record of a claim.</small> -->
        <div class="measures">
          <div v-if="searched_payment_number" class="payment-number">
            Payment #: {{ searched_payment_number }}
          </div>

          <!-- Payment Total -->
          <div v-if="payment_total" class="payment-total">
            Payment Total: ${{ payment_total }}
          </div>

          <!-- Claims Total -->
          <div v-if="payment_total" class="payment-total">
            Selected Total: ${{ selection_total }}
          </div>

          <!-- Difference Total -->
          <div v-if="payment_total" class="payment-total">
            Difference: ${{ difference }}
          </div>
        </div>
        <!-- <button class="button is-small" @click="magicSecret()">Magic Secret button</button> -->
        <button @click="compare" class="button is-info is-small" style="margin: 5px;">Compare</button>
        <button @click="resetBalanceAdjustments" class="button is-danger is-small" style="margin:5px; margin-top:5px;">Reset BA</button>
        <br>
        
        <div class="compare_bool">
          <small>Create compare file from form data?</small>
          <input type="checkbox" v-model="compare_bool" style="margin: 5px;">
        </div>



        <!-- <button @click="createUpdateInvoice()" class="button is-info is-small">Submit</button> &zwnj; -->
        <!-- <select class="select" style="width : 230px;">
          <option value:searched_payment_number> {{ this.searched_payment_number }}</option>
        </select> -->
        <br>

        <button @click="downloadFile()" class="button is-success is-small">Download</button> &zwnj;
        <button @click="quickbooksFile()" class="button is-dark is-small">Create File</button>

      </div>

      <!-- EDI UPLOAD FORM -->
      <div class="column right"></div>
    </div>

   

    <!-- WATCHING CLAIMS -->

    <!-- Tab links -->
<div class="tab" style="margin-top: 10px;">
  <button class="tablinks" @click="openCity(event, 'main')" id="defaultOpen"><span style="color:rgb(255, 255, 255);">Main </span></button>
  <button class="tablinks" @click="openCity(event, 'forensics')"> <span style="color:rgb(255, 255, 255);">Claim Forensics</span></button>
</div>

<!-- Tab content -->
<!-- THIS IS THE MAIN TAB -->
<div id="main" class="tabcontent" >
  <div v-if="claims" class="columns is-multiline">
      <div class="column is-2 table-style" style="text-align:center;">

        <!-- <select v-model="claim_id" class="select small-input" style="text-align: center;">
            <option v-for="claim in claims" :key="claim.id" :value="claim.id">{{ claim.id }}</option>
          </select> -->
        <div>
          <small v-if="selected_claim"> Selected Claim: {{ selected_claim }}</small>
          <small v-else class="none-selected">None Selected</small>
        </div>

        <div>
          <input type="number" v-model="searchTerm" @input="filterClaims" placeholder="Search claims...">
          <ul v-if="filteredClaims.length > 0">
            <li class="button is-primary is-small" v-for="claim in filteredClaims" :key="claim.id"
              @click="selectClaim(claim)">Confirm: {{ claim.id }}</li>
          </ul>
        </div>


        <div>&zwj;</div>

        <div>
          <small>Claim Total</small> <br>
          <input v-model="claim_total_adj" type="text" class="input small-input"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">

        </div>

        <div>&zwj;</div>

        <div>

          <small>Paid Total</small><br>
          <input v-model="paid_total_adj" type="text" class="input small-input"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">

        </div>

        <!-- <div>&zwj;</div>
          <small >Balance</small>
          <input v-model="balance_total_adj" type="text" class="input small-input" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"> -->

        <button @click="claimAdjustment()" class="button is-warning is-small" style="margin-top: 10px;">Submit
          Changes</button>

          <br>
        <br>
      
          <div class="add_records">
        <small>Add claim records</small> &zwj;&zwj;
        <select v-model="add_records">
          <option value='1'>1</option>
          <option value='2'>2</option>
          <option value='3'>3</option>
          <option value='4'>4</option>
          <option value='5'>5</option>
          <option value='6'>6</option>
          <option value='7'>7</option>
          <option value='8'>8</option>
          <option value='9'>9</option>
          <option value='10'>10</option>
        </select>
        <br>
        <br>

        <small>Names</small>
        <div>
          <input v-model="add_record_names" type="text">
        </div>

        <br>

        
        <small>Paid</small>
        <div>
          <input v-model="add_record_paid" type="text">
        </div>


      </div>
        </div>

      


      <div class="column">
        <small><b>Selected Payment </b>Claims</small>
        <div class="table-container">
          <table class="table table-style">
            <thead>
              <tr style="font-size: 0.8em">
                <th width="0.5%">
                  <input type="checkbox" @click="selectAll" @change="calculateClaimsTotal" v-model="allSelected" />
                </th>
                <th >CID</th>
                <th >Member</th>
                <th >Insurance ID</th>
                <th >Service Date</th>
                <th >Claim Total</th>
                <th >Paid</th>
                <th >Balance</th>
                <!-- <th >QBID</th> -->
                <!-- <th width="1%">Watching</th> -->
                <th >Status</th>



              </tr>
            </thead>

            <tbody>
              <tr v-for="claim in claims" :key="claim.id">
                <td>
                  <input type="checkbox" :value="claim.id" v-model="checkbox_claim_id" @click="selectIndividualCheck"
                    @change="calculateClaimsTotal" />
                </td>

                <td style="width: 20px;" @click="addToSearch(claim)">{{ claim.id }}</td>

                <!-- Member name -->
                <td class="truncate">
                  {{ claim.subscriber_last_name }},
                  {{ claim.subscriber_first_name }}
                </td>

                <td>{{ claim.subscriber_billing_id }}</td>

                <!-- Service date -->
                <td style="width: 200px;">{{ claim.encounter_start_date_of_service }}</td>

                <!-- Submitted amount -->
                <td @click="addToSubmitAmount(claim.submitted_amount)">${{ claim.submitted_amount.toLocaleString() }}</td>

                <!-- Paid amount -->
                <td @click="addToPaidAmount(claim.paid_amount)">${{ claim.paid_amount.toLocaleString() }}</td>

                <!-- Remaining balance -->
                <td @click="addToBalance(claim.submitted_amount - claim.paid_amount)">${{ (claim.submitted_amount -
                  claim.paid_amount).toFixed(2) }}</td>

                <!-- <td v-if="claim.qb_invoice_id"> <span style="color: #00ff00;"> {{ claim.qb_invoice_id }} </span></td>
                <td v-else> <span style="color: orange;"> --- </span></td> -->

                <!-- <td>{{ claim.watching }}</td> -->

                <!-- Claim status -->
                <td v-if="claim.complete" style="color: #00ff00">Paid</td>
                <td v-if="!claim.complete && claim.investigate" style="color: purple">
                  Investigate
                </td>
                <td v-if="!claim.complete && claim.short" style="color: orange">
                  Short
                </td>
                <td v-if="!claim.complete && claim.overpaid" style="color: yellow">
                  Overpaid
                </td>
                <td v-if="!claim.complete && claim.denied" style="color: red">
                  Denied
                </td>
                <td v-if="!claim.complete && !claim.billed">Not Billed</td>
                <td v-if="!claim.complete &&
                  claim.billed &&
                  !claim.paid_amount &&
                  !claim.denied
                  " style="color: yellow">
                  In Process
                </td>

                <!-- <td v-if="claim.watching && !claim.complete">
                  <a
                    @click="toggleResub(claim.id)"
                    class="button is-primary is-small"
                    style="height: 20px"
                    >Watching</a
                  >
                </td>
                <td v-else-if="claim.complete">--</td>
                <td v-else>
                  <a
                    @click="toggleResub(claim.id)"
                    class="button is-dark is-small"
                      style="height: 20px"
                      >Watch</a
                    >
                  </td> -->


              </tr>
            </tbody>
          </table>



        </div>

      </div>


      




    </div>

  <div class="columns is-multiline">

<!-- Payment Table -->
<!-- <div v-if="payments" class=" column payment-table">
  <small>Payments In Date Range</small>
  <table class="table table-style is-fullwidth">
    <tbody>
      <tr style="font-size: 0.8em">
        <th width="3%">Payment Number</th>
        <th width="3%">Payment Amount</th>
        <th width="1%">Payer</th>
        <th width="3%">Payment Date</th>
        <th width="1%">TP</th>
        <th width="1%">Complete</th>

      </tr>

      <tr v-for="payment in payments" :key="payment.id">
        <td width="1%"> {{ payment.payment_number }}</td>
        <td width="1%">${{ payment.payment_amount.toLocaleString() }}</td>
        <td width="1%" class="truncate2">{{ payment.payer }}</td>
        <td width="2%">{{ payment.payment_date }}</td>
        <td width="1%">{{ payment.organization_name }}</td>
        <td v-if="!payment.complete" width="1%" style="color: red;">No</td>
        <td v-else width="1%" style="color: #42f554;">Yes</td>
      </tr>

    </tbody>
  </table>
</div> -->


<div class=" column payment-table is-4">
  <small><b>Selected Payment</b> Credit Memos</small>
  <table class="table table-style is-fullwidth">
    <tbody>
      <tr style="font-size: 0.8em">
        <th width="3%">Amount to Credit</th>

      </tr>

      <tr v-for="pa in provider_adjustment" :key="pa.id">
        <td width="100%"> ${{ pa.amount }}</td>
      </tr>

    </tbody>
  </table>
</div>

</div>
</div>

<!-- THIS IS THE forensics TAB -->
<div id="forensics" class="tabcontent">
      <!-- CLAIM FORENSICS TOOL -->
      <div class="column">
        <span style="font-size: 1.5em;"><b>Claim Forensics </b>Tool</span>
        <br>
        <span v-if="date">

          <div class="search-container">

          
        
        <div style="padding:5px;">
<!-- Status Selection -->
<small>Select Status &zwj;</small>
        <select class="select" v-model="status_selection" style="height: 20px; margin: 3px; text-align: center;">
          <option value="all" selected>All</option>
          <option value="paid">Paid</option>
          <option value="denied">Denied</option>
          <option value="short">Short</option>
          <option value="in_process">In Process</option>
          <option value="not_billed">Not Billed</option>
        </select>

        </div>
        
        <!-- Name search -->
  
                <!-- <small >Search Members by <span style="color: yellow; ">Last Name</span> </small>  -->
                <div class="dropdown" style="margin-right: 90px;">
                <Dropdown
                  :options="members"
                  v-on:selected="validateSelection"
                  v-on:filter="getDropdownValues"
                  :disabled="false"
                  name="zipcode"
                  :maxItem="10000000"
                  placeholder="Please select an option">
              </Dropdown>
            </div>
            
              <!-- {{ queried_member }} -->

             
            <button @click="getForensicsClaims" class="button is-info is-small" style=" margin: 3px; float: right;">Search</button>
            <button @click="downloadForensicsFile" class="button is-success is-small" style=" margin: 3px; float: right;">Export Excel</button>
    
   
            </div>

          


        
        
        <!-- TABLE -->
        <div class="table-container">
          <table class="table table-style">
            <thead>
              <tr style="font-size: 0.8em">
                <th width="0.5%">
                  <input type="checkbox" @click="forensicSelectAll" @change="calculateForensicsClaimsTotal" v-model="forensic_all_selected" />
                </th>
                <th width="1%">CID</th>
                <th width="5%" >Member</th>
                <th width="5%">Insurance ID</th>
                <th width="5%">Service Date</th>
                <th width="5%">Claim Total</th>
                <th width="5%" >Paid</th>
                <th width="5%">Balance</th>
                <th width="5%" >Status</th>
                <th width="5%" ># Resubs</th>
                <th width="5%">Last Update</th>

              </tr>
            </thead>

            <tbody>
              <tr  v-for="claim in forensics_claims" :key="claim.id">
                <td>
                  <input type="checkbox" :value="claim.id" v-model="forensics_checkbox_claim_id" @click="selectIndividualForensicsCheck"
                    @change="calculateForensicsClaimsTotal" />
                </td>

                <td style="width: 20px;" @click="addToSearch(claim)">{{ claim.id }}</td>

                <!-- Member name -->
                <td class="truncate">
                  {{ claim.subscriber_last_name }},
                  {{ claim.subscriber_first_name }}
                </td>

                <td>{{ claim.subscriber_billing_id }}</td>

                <!-- Service date -->
                <td style="width: 200px;">{{ claim.encounter_start_date_of_service }}</td>

                <!-- Submitted amount -->
                <td @click="addToSubmitAmount(claim.submitted_amount)">${{ claim.submitted_amount.toLocaleString() }}</td>

                <!-- Paid amount -->
                <td @click="addToPaidAmount(claim.paid_amount)">${{ claim.paid_amount.toLocaleString() }}</td>

                <!-- Remaining balance -->
                <td @click="addToBalance(claim.submitted_amount - claim.paid_amount)">${{ (claim.submitted_amount -
                  claim.paid_amount).toFixed(2) }}</td>

                <!-- <td v-if="claim.qb_invoice_id"> <span style="color: #00ff00;"> {{ claim.qb_invoice_id }} </span></td>
                <td v-else> <span style="color: orange;"> --- </span></td> -->

                <!-- <td>{{ claim.watching }}</td> -->

                <!-- Claim status -->
                <td v-if="claim.complete" style="color: #00ff00">Paid</td>
                <td v-if="!claim.complete && claim.investigate" style="color: purple">
                  Investigate
                </td>
                <td v-if="!claim.complete && claim.short" style="color: orange">
                  Short
                </td>
                <td v-if="!claim.complete && claim.overpaid" style="color: yellow">
                  Overpaid
                </td>
                <td v-if="!claim.complete && claim.denied" style="color: red">
                  Denied
                </td>
                <td v-if="!claim.complete && !claim.billed">Not Billed</td>
                <td v-if="!claim.complete &&
                  claim.billed &&
                  !claim.paid_amount &&
                  !claim.denied
                  " style="color: yellow">
                  In Process
                </td>

                <td v-if="claim.times_resubmitted > -1">{{ claim.times_resubmitted }}</td>
                <td v-else>No record</td>

                <td>{{ claim.last_updated.substring(0, 10) }}</td>

                

              </tr>
            </tbody>
          </table>



        </div>

        <div class="amounts-display">
          <small> <b>Paid Total =</b>  ${{ forensics_selection_total }}</small> 
          <small v-if="status_selection=='short'" style="margin-left: 15px;"> <b>Short =</b> ${{ forensics_difference }}</small>
          <small v-if="status_selection=='denied'" style="margin-left: 15px;"> <b>Denied Total =</b> ${{ forensics_difference }}</small>
          <small v-if="status_selection=='in_process'" style="margin-left: 15px;"> <b>Pending =</b> ${{ forensics_difference }}</small>
          <small v-if="status_selection=='not_billed'" style="margin-left: 15px;"> <b>Billable =</b> ${{ forensics_difference }}</small>

        </div>


      </span>
      <span v-else>
        <small>Please select a date range for your forensics session</small>
      </span>

        



      </div>




</div>



    <div class="columns">
      <div class="column payment-table" id="balance_adj_debug">
        <!-- <button class="button" @click="getBalanceAdjDebug()">Get BAD</button> -->
        <pre class="json-format" v-for="record in balance_adj_debug"
          :key="record.id">Payment ID: {{ record.payment_id }} ID:{{ record.id }} <br><span style="color: green; font-weight: bold;">Record Created: {{ record.created_at }} <br></span>{{ JSON.stringify(record.payload, null, 2) }}</pre>
      </div>
      <div class="column"></div>
    </div>


  </div>


</template>

<script>
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
import { generateToast } from ".../../../common/toast.js";
import FileReader from "@/components/FileReader.vue";
import { convertDates } from ".../../../common/converters.js";
import Dropdown from "@/components/Dropdown.vue";

export default {
  components: { Datepicker, FileReader, Dropdown },

  name: "Accounting",
  data() {
    return {
      member_search_query : null,
      members :  [],
      forensics_difference : null,
      forensic_all_selected : false,
      forensics_selection_total : null,
      forensics_claims : null,
      status_selection : null,
      compare_bool : false,
      balance_adj_debug: null,
      provider_adjustment: null,
      searched_payment_number: null,
      watching_claims: null,
      difference: null,
      selection_total: null,
      claim_totals: null,
      remit_advice: null,
      claim_advice: null,
      selected_payment: null,
      payments: null,
      file_data: "",
      claims: null,
      rides: [],
      services: [],
      service_claim: [],
      search_term: "",
      search_query: null,
      queried_member : null,
      date: "",
      busy: false,
      allSelected: false,
      total_value: 0,
      payment_total: null,
      checkbox_claim_id: [],
      forensics_checkbox_claim_id: [],
      claims_total_value: null,
      organization_name: null,
      claim_total_adj: null,
      paid_total_adj: null,
      // balance_total_adj : null,
      claim_id: null,
      searchTerm: '',
      selected_claim: null,
      filteredClaims: [],
      add_records : null,
      add_record_names : '',
      add_record_paid : '',

      headers: {
        headers: {
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: `Token ${this.$store.state.token}`,
        },
      },
    };
  },

  mounted() {
    document.getElementById("defaultOpen").click();
  },

  created() {
    document.title = "Accounting";
    this.getMembers();
  },

  methods: {
    //

    getDropdownValues(value){
        // gets the value from the dropdown
        const billing_id = value.split("-")[1]
        this.member_search_query = billing_id
        this.memberSearch()
        
      },

      
//
      async memberSearch() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/member-search/?search=${this.member_search_query}&ordering=last_name`
        )
        .then((response) => {
          console.log('Member Search: ', response)
          this.queried_member = response.data[0];
          this.first_name = this.queried_member.first_name
          this.last_name = this.queried_member.last_name
          this.funding_source_name = this.queried_member.funding_source_name
          this.funding_source_id = this.queried_member.funding_source_id
          this.gender = this.queried_member.gender
          this.diag_code = this.queried_member.diag_code
          this.date_of_birth = this.queried_member.date_of_birth


        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
//

async getMembers() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/all-members/")
        .then((response) => {
          this.members = response.data;
          this.members.unshift({"first_name" : "NONE", "last_name" : "SELECT", "billing_id" : "NO_MEMBER_SELECTED"})
          console.log("Members: ", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },

//

    //
    async downloadForensicsFile() {
      //  Download searched_payment_number
      console.log('Forensics Checkboxs: ', this.forensics_checkbox_claim_id)
      if (this.forensics_checkbox_claim_id) {
        this.$store.commit("setIsLoading", true)

        await axios
          .post('/api/v1/download-forensics-file/', { 'selected_claims': this.forensics_checkbox_claim_id }, this.headers)
          .then((response) => {
            if (response.status == 200){
            
            const start_date = convertDates(this.date[0])
            const end_date = convertDates(this.date[1])
            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.status_selection.toUpperCase()}_${start_date}_THRU_${end_date}.csv`);
            document.body.appendChild(link);
            link.click();
            this.$store.commit("setIsLoading", false)
      }
    })
          .catch((err) => {
            console.log(err)
            generateToast(`${err.response.data.message}`, "is-warning")
            this.$store.commit("setIsLoading", false)
          })

      } else {
        generateToast("Please select claims to write...", "is-warning")
      }
    },

    //
    async getForensicsClaims(){
      // Get the list of claims for the given date range and status selection
      // Reset for new data
      this.forensics_checkbox_claim_id = []

      if (this.date && this.date.length == 2) {
        this.$store.commit("setIsLoading", true);

        let start_date = convertDates(this.date[0]);
        let end_date = convertDates(this.date[1]);

        if (this.queried_member){
          var queried_member = this.queried_member.billing_id
        } 
        const url = `/api/v1/forensics-claims-by-date/?min_date=${start_date}&max_date=${end_date}&status=${this.status_selection}&member=${queried_member}`
     await axios
      
      .get(url)
      .then((response) => {
          console.log("Forensics Response: ", response)
          this.forensics_claims = response.data
          this.$store.commit("setIsLoading", false)
          this.calculateForensicsClaimsTotal() // Get the sum total for all claims selected
          console.log(url.toString())
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
      }
    },

    //

  openCity(evt, cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    // evt.currentTarget.className += " active";
  },




    //

    

    async resetBalanceAdjustments(){
      if (this.searched_payment_number) {
      var x = confirm("Are you sure you want to reset all Balance Adjustments for this payment?")
      if(x) {
        // const password = "cyberpunk"
        // var y = prompt("Please enter password to continue...")
        // if (y == password){
      this.$store.commit("setIsLoading", true)

      await axios
        .post("/api/v1/reset-balance-adjustments/", {'payment_number' : this.searched_payment_number}, this.headers)
        .then((response) => {
          console.log("Compare Response: ", response)
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          generateToast(`${error.data.message}`)
          this.$store.commit("setIsLoading", false)
        });
      
    }
  } else {
    generateToast("You must search for a payment first...", "is-warning")
  }
    },

    async compare() {
      this.$store.commit("setIsLoading", true)

      await axios
        .post("/api/v1/compare/", {'payment_number' : this.searched_payment_number}, this.headers)
        .then((response) => {
          console.log("Compare Response: ", response)
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          generateToast(`${error.data.message}`)
          this.$store.commit("setIsLoading", false)
        });
    },


    addToSearch(claim) {
      // Automatically adds the claim id to the search box to minimize typing
      this.searchTerm = claim.id
      this.selectClaim(claim)

    },

    addToSubmitAmount(value) {
      this.claim_total_adj = value
    },
    addToPaidAmount(value) {
      this.paid_total_adj = value
    },
    // addToBalance(value){
    //   this.balance_total_adj = value.toFixed(2)
    // },

    // START OF THE CLAIM ID SEARCH FUNCTIONALITY
    filterClaims() {
      if (typeof this.searchTerm === 'number') {
        this.filteredClaims = this.claims.filter(claim => claim.id === this.searchTerm);
      } else {
        this.filteredClaims = [];
      }
    },
    selectClaim(claim) {
      this.searchTerm = claim.id;
      this.selected_claim = claim.id
      this.filteredClaims = [];
      this.$emit('claim-selected', claim.id);
    },
    // END OF THE CLAIM ID SEARCH FUNCTIONALITY

    async claimAdjustment() {
      // adjusts individual claims to make payments balance properly in case of a failure in the processing algorithm
      var x = confirm(`Are you sure you want to adjust claim ${this.selected_claim}? If you do, please review your changes one more time.`)
      if (x) {
        if (this.claim_total_adj && this.paid_total_adj && this.selected_claim) {
          this.$store.commit("setIsLoading", true)
          let data = { "claim_total_adj": this.claim_total_adj, "paid_total_adj": this.paid_total_adj, "claim_id": this.selected_claim }
          await axios
            .post("/api/v1/claim-adjustment/", data, this.headers)
            .then((response) => {
              console.log("Claim Adjustment Response: ", response)

              this.selected_claim = null // reset so it doesn't stay set when you work on another claim next.
              this.searchPayment()
              generateToast(`Successfully updated claim ${claim_id}!`, "is-success")
              this.$store.commit("setIsLoading", false)
            })
            .catch((error) => {
              console.log(error)
              generateToast(`${error.data.message}`)
              this.$store.commit("setIsLoading", false)
            });

        } else {
          generateToast("Please make sure you fill out all the adjustment fields before continuing... They must at least have a zero.", "is-warning")
        }

      }
    },
    //


    async getOrganization() {
      await axios
        .post('/api/v1/getOrg/', { 'payment_number': this.searched_payment_number }, this.headers)
        .then((response) => {
          this.organization_name = response.data.org_name;
          console.log('org name', response)
        }).catch((err) => {
          generateToast(`${err}`, "is-danger")
        })
    },

    async downloadFile() {
      //  Download searched_payment_number
      if (this.searched_payment_number) {
        this.$store.commit("setIsLoading", true)

        await axios
          .post('/api/v1/download-file/', { 'payment_number': this.searched_payment_number }, this.headers)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.organization_name}_${this.selected_payment.payment_date}_${this.searched_payment_number}.csv`);
            document.body.appendChild(link);
            link.click();
            this.$store.commit("setIsLoading", false)
          })
          .catch((err) => {
            console.log(err)
            generateToast(`${err.response.data.message}`)
            this.$store.commit("setIsLoading", false)
          })

      } else {
        generateToast("Please select a payment.", "is-warning")
      }
    },

    async getBalanceAdjDebug() {
      this.$store.commit("setIsLoading", true)

      await axios
        .get(`/api/v1/balance-adj-debug/?payment_number=${this.selected_payment.payment_number}`,)
        .then((response) => {
          console.log("BAD Response: ", response)
          this.balance_adj_debug = response.data
          console.log('BAD payload: ', response.data)
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          generateToast(`${error.data.message}`)
          this.$store.commit("setIsLoading", false)
        });

    },

    async quickbooksFile() {
      // Generates a quickbooks file in the backend that can be retrieved and uploaded manually to quickbooks for user
      var x = confirm('Are you sure you want to create a new quickbooks file, you can only do this once per payment')
      if (x) {
        if (this.checkbox_claim_id.length > 0) {
          console.log("SPN: ", this.searched_payment_number)

          this.$store.commit("setIsLoading", true)
          
          var add_data = null
          if(this.add_records){
            add_data = {"number_of_rows" : this.add_records, "add_record_names" : this.add_record_names, "add_record_paid" : this.add_record_paid}
          }

          const data = { "selected_claims": this.checkbox_claim_id, "payment_number": this.searched_payment_number, "add_records" : add_data }

          await axios
            .post("/api/v1/quickbooks-file/", data, this.headers)
            .then((response) => {
              console.log("QB File Response: ", response)
              generateToast(`${response.data.message}`, "is-success")
              this.$store.commit("setIsLoading", false)
            })
            .catch((error) => {
              console.log(error)
              generateToast(`${error.response.data.message}`, "is-danger")
              this.$store.commit("setIsLoading", false)
            });
        }
        else {
          generateToast("Please select all, or individual claims you want to add to file.", "is-warning")
        }
      }
    },
    async getProviderAdjustment() {
      this.$store.commit("setIsLoading", true)
      this.provider_adjustment = null;

      await axios
        .get(`/api/v1/get-provider-adjustment/${this.selected_payment.id}/`)
        .then((response) => {
          console.log("PA Response: ", response)
          if (response.data.length > 0) {
            this.provider_adjustment = response.data
          }
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
    },
    watchingRevenueOutstanding() {
      let total = 0;
      for (let i = 0; i < this.watching_claims.length; i++) {
        total += this.watching_claims[i].submitted_amount - this.watching_claims[i].paid_amount;
      }
      return total.toFixed(2)
    },

    async getWatchingClaims() {
      // Gets claims with a watching status for selected date range

      if (this.date && this.date.length == 2) {
        this.$store.commit("setIsLoading", true);

        let start_date = convertDates(this.date[0]);
        let end_date = convertDates(this.date[1]);

        let url = `/api/v1/get-watching-claims/?min_date=${start_date}&max_date=${end_date}`;
        console.log(url);

        await axios
          .get(url)
          .then((response) => {
            console.log("Watching Response: ", response)
            this.watching_claims = response.data
            this.$store.commit("setIsLoading", false)
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });
      } else {
        console.log('Date format in getWatchingClaims doesnt match expected.')
      }
    },

    async paymentComplete() {
      // Check if the selected payment is complete
      this.$store.commit("setIsLoading", true)

      await axios
        .post("/api/v1/complete-payment/", { "payment_id": this.selected_payment.id }, this.headers)
        .then((response) => {
          console.log("Complete Response: ", response)
          for (var i = 0; i < this.payments.length; i++) {
            if (this.payments[i].id == response.data.payment_id) {
              this.payments[i].complete = response.data.message;
            }
          }
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });

    },
    //
    async toggleResub(claim_id) {
      this.$store.commit("setIsLoading", true);

      await axios
        .post("/api/v1/toggle-resub/", { claim_id: claim_id }, this.headers)
        .then((response) => {
          let toggle_resub = response.data.resub;
          this.claims.find((x) => x.id === claim_id).resub = toggle_resub;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    // 
    async calculateClaimsTotal() {
      if (this.checkbox_claim_id) {
        this.$store.commit("setIsLoading", true)

        // Check if the user is trying to view the payment total or the forensics

        await axios
          .post("/api/v1/calculate-claims-total/",
            {
              "checkbox_claim_id": this.checkbox_claim_id,
              "payment_claims": this.claims,
              "payment_total": this.payment_total,
              "payment_id": this.selected_payment.id

            }, this.headers)
          .then((response) => {
            console.log("Calculate Claims Total Response: ", response)
            this.selection_total = response.data.message.selected_total;
            this.difference = response.data.message.difference;
            this.$store.commit("setIsLoading", false)
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });

        // let val = 0
        // for (var i = 0; i < this.checkbox_claim_id.length; i++) {
        //   for (var j = 0; j < this.claims.length; j++ ) {
        //     if (this.claims[j].id == this.checkbox_claim_id[i]) {
        //       val += this.claims[j].paid_amount;
        //     }
        //   }
        // }
        // this.claims_total_value = val;
        // this.difference = this.payment_total - val;
      } else {
        console.log("calculateClaimsTotal() =>  There are no claims to calculate the value of.")
      }
    },

    async calculateForensicsClaimsTotal() {
      if (this.forensics_checkbox_claim_id) {
        this.$store.commit("setIsLoading", true)

        // Reset for new data
        this.forensics_difference=0.0
        this.forensics_selection_total=0.0

        // console.log("Forensics Diff: ", this.forensics_difference)
        // console.log("Forensics Selection Total: ", this.forensics_selection_total)

        // Check if the user is trying to view the payment total or the forensics
        

        await axios
          .post("/api/v1/calculate-forensics-claims-total/",
            {
              "forensics_checkbox_claim_id": this.forensics_checkbox_claim_id,

            }, this.headers)
          .then((response) => {

            

            console.log("Forensics Calculate Claims Total Response: ", response)
            this.forensics_selection_total = response.data.message.selected_total
            this.forensics_difference = response.data.message.difference
            this.$store.commit("setIsLoading", false)

        //     console.log("Forensics Diff AFTER: ", this.forensics_difference)
        // console.log("Forensics Selection Total AFRTER: ", this.forensics_selection_total)
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });

      } else {
        console.log("calculateClaimsTotal() =>  There are no claims to calculate the value of.")
      }
    },

    //
    async getPaymentTotal() {
      if (this.date && this.payments) {
        this.$store.commit("setIsLoading", true)

        await axios
          .get(`/api/v1/get-payment-total/${this.selected_payment.id}/`)
          .then((response) => {
            console.log("Payment Total Response: ", response)
            this.payment_total = response.data[0].payment_amount
            this.$store.commit("setIsLoading", false)

            this.calculateClaimsTotal();
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit("setIsLoading", false)
          });
      } else {
        console.log('Cannot retrieve a payment amount for this record.')
      }
    },
    //

    async logout() {
      await axios
        .post("/api/v1/token/logout/")
        .then((response) => {
          console.log("Logged out");
          this.$store.commit("purgeNotifications");
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });

      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      this.$store.commit("removeToken");

      this.$router.push("/login");
    },


    //
    async toggleResub(claim_id) {
      this.$store.commit("setIsLoading", true);

      await axios
        .post("/api/v1/toggle-resub/", { claim_id: claim_id }, this.headers)
        .then((response) => {
          let toggle_resub = response.data.resub;
          this.claims.find((x) => x.id === claim_id).resub = toggle_resub;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    assignClaimTotals() {
      // Uses calculations made in the backend to assign claim total values to existing claim objects
      for (var i = 0; i < this.claims.length; i++) {
        for (var j = 0; j < this.claim_totals.length; j++) {
          if (this.claims[i].id == this.claim_totals[j]["claim_id"]) {
            this.claims[i] = Object.assign(this.claims[i], {
              calculated_value: this.claim_totals[j]["charge"],
            });
          }
        }
      }
    },
    //

    cleanString(dirty) {
      let final_cleaned = [];
      if (typeof dirty.isProxy) {
        console.log("Dirty: ", Object.assign({}, dirty));
        let clean = Object.assign({}, dirty);
        for (const [key, value] of Object.entries(clean)) {
          console.log(`${key}: ${value}`);
          final_cleaned.push(value);
        }
      }
      return final_cleaned.join();
    },
    //
    async searchPayment() {
      // Returns claims associated with payment
      if (this.selected_payment) {

        await axios
          .get(`/api/v1/search-payment/${this.selected_payment.id}/`)
          .then((response) => {
            console.log("Payment Claims Response: ", response);
            if (response.status == 200) {
              this.claims = response.data;
              this.$store.commit("setIsLoading", false);
              this.searched_payment_number = this.selected_payment.payment_number

              // Get payment total
              this.getPaymentTotal();
              this.paymentComplete();
              this.getProviderAdjustment();
              this.getOrganization(); // for the file download
            } else {
              generateToast(`Unknown response: ${response.status}`, "is-warning");
              this.$store.commit("setIsLoading", false);
            }
          })
          .catch((error) => {
            console.log(error);
            generateToast(`${error}`, "is-danger");
            this.$store.commit("setIsLoading", false);
          });
      }

      else {
        generateToast('Please select a payment before continuing.', "is-warning");
        console.log('Please select a payment before continuing.')
      }
    },
    //
    async getPayments() {
      if (this.date) {
        if (this.date && this.date.length == 2) {
          this.$store.commit("setIsLoading", true);
          console.log('test')

          let start_date = convertDates(this.date[0]);
          let end_date = convertDates(this.date[1]);
          let url = `/api/v1/search-payment-date/?min_date=${start_date}&max_date=${end_date}`;

          await axios
            .get(url)
            .then((response) => {
              console.log("Payemnts Response: ", response);
              if (response.data.length > 0) {
                // generateToast(
                //   "Payments exist for this date range, select from the payments dropdown menu.",
                //   "is-success"
                // );
                this.payments = response.data;
                this.getWatchingClaims();

              } else {
                generateToast(
                  "There are no payments in the selected date range.",
                  "is-warning"
                );
                this.getWatchingClaims();

              }
              this.$store.commit("setIsLoading", false);
            })
            .catch((error) => {
              console.log(error);
              generateToast(`${error}`, "is-danger");
              this.$store.commit("setIsLoading", false);
            });
        } else {
          generateToast(
            "Please select a date range.",
            "is-warning"
          );
          this.$store.commit("setIsLoading", false);
        }
      } else {
        generateToast(
          "Please select a date range. You may only have one date selected.",
          "is-warning"
        );
        this.$store.commit("setIsLoading", false);
      }
    },
    onSubmit() {
      console.log(this.form);
      // post the form to the server
    },

    async deleteClaims() {
      if (!this.busy) {
        this.busy = true;
        var x = confirm(
          "This action cannot be undone, are you sure you want to do this?"
        );
        if (x) {
          this.$store.commit("setIsLoading", true);
          if (this.checkbox_claim_id.length > 0) {
            await axios
              .post(
                "/api/v1/delete-claims/",
                this.checkbox_claim_id,
                this.headers
              )
              .then((response) => {
                console.log("Delete Claims Response: ", response);
                generateToast(`${response.data.message}`, "is-success");
                this.$store.commit("setIsLoading", false);
                this.busy = false;
                setTimeout(this.$router.go(), 3000);
              })
              .catch((error) => {
                console.log(error);
                this.$store.commit("setIsLoading", false);
                this.busy = false;
              });
          } else {
            generateToast("Please select a claim to delete.", "is-warning");
            this.$store.commit("setIsLoading", false);
            this.busy = false;
          }
        } else {
          this.busy = false;
        }
      } else {
        generateToast("System is busy, please wait...", "is-warning");
        this.busy = false;
      }
    },

    modifierSet(modifier_set) {
      let x = JSON.stringify(modifier_set)
        .replace("{", "")
        .replace("}", "")
        .replace(/[,]/g, "--")
        .replace(/["']/g, "")
        .split(":")
        .slice(1)
        .toString();

      if (x.length > 2) {
        x = x + " : ";
      }
      return x;
    },

    //
    searchClaims() {
      if (!this.busy) {
        this.busy = true;
        this.toggle = true;

        if (this.date && this.date.length == 2) {
          this.$store.commit("setIsLoading", true);

          let start_date = convertDates(this.date[0]);
          let end_date = convertDates(this.date[1]);

          let url = `/api/v1/claim-status-by-date/?min_date=${start_date}&max_date=${end_date}`;
          console.log(url);
          axios
            .get(url)
            .then((response) => {
              this.claims = response.data;
              console.log(response.data);
              this.$store.commit("setIsLoading", false);
              this.busy = false;

              // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
              this.service_claim = [];
              for (var i = 0; i < response.data.length; i++) {
                this.getAllServices(response.data[i].id);
                console.log(this.service_claim);
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.commit("setIsLoading", false);
              this.busy = false;
            });
        } else {
          generateToast("Please select dates.", "is-warning");
          this.$store.commit("setIsLoading", false);
        }

      } else {
        generateToast("System is busy, please wait...", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
    },
    //
    async getClaimAdvice(claim_id) {
      await axios
        .post("/api/v1/get-claim-advice/", { claim_id: claim_id }, this.headers)
        .then((response) => {
          if (response.status == 200) {
            let remit_advice = response.data.message.remit_advice;
            let claim_advice = response.data.message.claim_adj;

            if (Object.keys(remit_advice).length > 0) {
              this.remit_advice = response.data.message.remit_advice;
            }
            if (Object.keys(claim_advice).length > 0) {
              this.claim_advice = response.data.message.claim_adj;
            }
            this.$store.commit("setIsLoading", false);
          } else {
            this.remit_advice = null;
            this.claim_advice = null;
            this.$store.commit("setIsLoading", false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    async reviewClaim(claim_id) {
      this.toggle = false;
      console.log(claim_id);
      this.remit_advice = null;
      this.claim_advice = null;
      await this.getServices(claim_id);
      await this.getRides(claim_id);
      await this.getClaimAdvice(claim_id);
    },
    //
    async getRides(claim_id) {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/claim-rides/?claim_id=${claim_id}`)
        .then((response) => {
          console.log("Rides Response: ", response);
          this.rides = response.data;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    async dataToBackend() {

      let x = confirm("Are you sure you want to update claim records?");
      if (x) {
        this.busy = true;
        this.$store.commit("setIsLoading", true);

        await axios
          .post(
            "/api/v1/upload-claim-status/",
            { data: this.file_data, compare : this.compare_bool },

            this.headers
          )
          .then((response) => {
            console.log("Manual Claim Response: ", response);
            generateToast("Success!", "is-success");
            this.claims = response.data;
            this.$store.commit("setIsLoading", false);
            this.file_data = "";
            this.getPayments();
            this.busy = false;
            location.reload() // refreshes the page so the seach works properly
          })
          .catch((error) => {
            console.log(error);
            generateToast(error.response.data.message, "is-danger");
            this.$store.commit("setIsLoading", false);
            this.busy = false;
          });
      } else {
        this.$store.commit("setIsLoading", false);
        this.busy = false;
        generateToast(
          "Please make sure you have both dates for range.",
          "is-warning"
        );
      }


    },
    //
    async getLatestClaims() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/latest_claims/")
        .then((response) => {
          console.log("Latest Claims Response: ", response);
          this.claims = response.data;
          this.$store.commit("setIsLoading", false);

          // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
          this.service_claim = [];
          for (var i = 0; i < response.data.length; i++) {
            this.getAllServices(response.data[i].id);
            console.log(this.service_claim);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },



    //
    async syncClaimStatus() {
      this.$store.commit("setIsLoading", true);
      let data = { ids: this.checkbox_claim_id };
      await axios
        .post("/api/v1/sync-claims/", data, this.headers)
        .then((response) => {
          console.log("Sync Claims Response: ", response);
          this.claims = response.data;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    async searchByName() {
      if (!this.busy) {

        this.busy = true;
        this.toggle = true;
        this.$store.commit("setIsLoading", true);

        axios
          .get(
            `/api/v1/claims-by-name/?search=${this.search_term}`
          )
          .then((response) => {
            console.log("Search By Name Response: ", response);
            this.claims = response.data;
            this.$store.commit("setIsLoading", false);
            this.busy = false;

            // Get all services for selection so getTotal can be used in template to return claim-service charge amounts
            this.service_claim = [];
            for (var i = 0; i < response.data.length; i++) {
              this.getAllServices(response.data[i].id);
              console.log(this.service_claim);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });

      } else {
        generateToast("System is busy, please wait...", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
    },

    //

    async getServices(claim_id) {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
        .then((response) => {
          console.log("Services Response: ", response);
          this.services = response.data;

          var count = 0.0;
          for (var i = 0; i < response.data.length; i++) {
            count += response.data[i].charge;
          }
          this.total_value = count;

          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },
    //
    getTotal(claim_id) {
      // Loop to find a matching claim id in service_claim, if match found, return value.
      for (var i = 0; i < this.service_claim.length; i++) {
        if (this.service_claim[i]["claim_id"] == claim_id) {
          return this.service_claim[i]["total"].toFixed(2);
        }
      }
    },
    //
    getAllServices(claim_id) {
      // Gets services and organizes them for getTotal to call in the template and retrieve total for each claim
      this.$store.commit("setIsLoading", true);

      axios
        .get(`/api/v1/services-by-claim/?claim_num=${claim_id}`)
        .then((response) => {
          var count = 0.0;
          for (var i = 0; i < response.data.length; i++) {
            count += response.data[i].charge;
          }
          this.$store.commit("setIsLoading", false);

          this.service_claim.push({ claim_id: claim_id, total: count });
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
        });
    },

    //
    selectAll() {
      this.allSelected = !this.allSelected; // Set the opposite bool at start, but let default be false
      this.checkbox_claim_id = [];

      if (this.allSelected) {
        for (const r in this.claims) {
          var claim_id = this.claims[r].id;
          this.checkbox_claim_id.push(claim_id);
        }
      } else {
        this.checkbox_claim_id = [];
      }
    },

    //
    forensicSelectAll() {
      this.forensic_all_selected = !this.forensic_all_selected; // Set the opposite bool at start, but let default be false
      this.forensics_checkbox_claim_id = [];

      if (this.forensic_all_selected) {
        for (const r in this.forensics_claims) {
          var claim_id = this.forensics_claims[r].id;
          this.forensics_checkbox_claim_id.push(claim_id);

        }
      } else {
        this.forensics_checkbox_claim_id = [];
      }
    },
    //

    selectIndividualCheck() {
      this.allSelected = false;
    },

    selectIndividualForensicsCheck() {
      this.forensic_all_selected = false;
      this.calculateIndividualForensicsClaimsTotal() // Get the sum total for all claims selected

    },



    
  },
  //
};
</script>

<style scoped>
.controls {
  width: 80%;
  margin: 0 auto;
}

.content {
  width: 100%;
  top: 50px;
}

.table-container {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 0.9em;
}

.select-control {
  height: 42px;
}

.custom-select {
  height: 38px;
  width: 100%;
  margin-right: 5px;
  border: 1px solid #ddd;
}

.really-small {
  font-size: 0.7em;
}

.custom-control {
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
  height: 275px;
  /* margin-bottom: 15px; */
  padding: 10px;
  text-align: center;
}

.table-style {
  /* From https://css.glass */
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
  overflow: auto;
  max-height: 500px;
  font-size: 0.8em;
}

table td {
  text-align: center;
}

table tr {
  text-align: center;
}

table th {
  color: #fff;
  background-color: midnightblue;
  position: sticky;
  top: 0;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate2 {
  width: 25px;
  font-size: .8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-form {
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  width: 250px;
}

/* Tooltip container */
/* .tooltip-patch {
  margin-top: -10px; 
  margin-left: 5px;


} */

.middle-btn {
  text-align: center;

  margin-top: 5px;
  margin-bottom: 5px;
}

.review {
  padding: 10px;
  font-size: 0.7em;
  color: white;
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
}

.content table th {
  color: white;
}

tr:hover td {
  background: blue;
}

.claim-advice {
  min-height: 100px;
  max-height: 250px;
  width: 100%;
}

.col-style {
  padding: 10px;
  max-height: 200px;
  text-align: center;
  overflow-y: auto;
}

.measures {
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
  color: #43eb34;
  padding: 3px;
}

td {
  font-size: 0.8em;
}

.json-format {
  text-align: left;
  opacity: 0.8;
  background-color: black;
  color: #00ff1a;
}

.small-input {
  height: 25px;
  width: 150px;

}

.top-spacer {
  margin-top: 10px;
}

.none-selected {
  color: white;
  background-color: rgb(31, 31, 31);
  border: 1px solid red;
  border-radius: 3px;
  width: 250px;
  height: 150px;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #0033ff;
  background-color: #000000;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: rgb(0, 16, 123);
  color : white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #0033ff;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #0033ff;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 20px 12px;
  border: 1px solid #0033ff;
  border-top: none;
  
}

.amounts-display {
  background-color: #0033ff;
  color: white;
  padding: 5px;
  border: 1px solid #6e8bff;
  border-radius: 3px;
}

.search-container{
  /* display: flex; */
  border: 1px solid rgb(13, 0, 255);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.dropdown{
  margin-left: 2px;
  margin-right: 5px;
}
</style>