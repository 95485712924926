<template>
  <div class="content">
    <h3>Purge notifications once resolved or they will remain here and confuse you later.</h3>
      <h2 style="text-align: center; color:white;">Resolution Center</h2>
    <div class="columns is-centered is-multiline">
      <div class="column is-7  custom-column">
        <table class="table table-style is-fullwidth">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Description</th>
              <th>MR CUSTOMER ID</th>

              <th>&zwnj;</th>
     
            </tr>
          </thead>

          <tbody >
            <tr v-for="member in members" :key="member.id">
              <td >{{ member.first_name }} </td>
              <td >{{ member.last_name }} </td>
              <td width="25%">{{member.desc}}</td>
              <td>{{member.member_id}}</td>
              <!-- <td >{{ member.member_id }} </td> -->

              <!-- <td>
                <a
                  @click="createMember(member.id)"
                  href="#"
                  class="button is-success is-small"
                  >Create</a>
              </td> -->
              <td>
                <a
                  @click="purgeUnregistered(member.id)"
                  href="#"
                  class="button is-danger is-small"
                  >Purge</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {

  name: "Member",
  data() {
    return {
      members: [],
      search_query: null,
      stored_funding_sources : []
    };
  },

  mounted() {
    this.getUnregMembers();

  },

  created() {
    document.title = "Members";
  },

  methods: {

    createMember(id){
        let x = confirm('Before you create this member, make sure to set the member to INACTIVE in Mediroutes, or you will have a duplicate, are you ready to continue?')
        if(x){
            this.purgeUnregistered(id);
            this.$router.push('/forms/create-member/')
        }
    },

    async purgeUnregistered(id) {
        let x = confirm('If you don\'t create this member, or deactivate this member in Mediroutes, you might see it here again. Are you sure you want to delete this notification?')
        
    if (x) {
        this.$store.commit("setIsLoading", true)
     await axios
      .delete(`/api/v1/purge-unreg/${id}/`)
      .then((response) => {
          console.log("Purge Response: ", response)
          this.$store.commit("setIsLoading", false)
          this.$router.go();
      })
      .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });
}
this.$store.commit("setIsLoading", false)
    },

    async getUnregMembers() {
      // this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/unregistered-member/")
        .then((response) => {
          this.members = response.data;
          this.members = this.members;
          console.log("Members: ", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      // this.$store.commit("setIsLoading", false);
    },
//
  },
};
</script>

<style scoped>
.content {
    margin-top : 50px;
  width: 100%;
}

.custom-column{
  height: 800px;
  width: 100%;
  overflow: auto;
}

.table-style {
  font-size:  .8em;
  padding: 5px;
/* From https://css.glass */
background: rgba(103, 103, 103, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(103, 103, 103, 0.3);
  overflow: auto;
  max-height: 40%;
}

.member-search {
  display: flex;
  width: 50%;
  margin: 0 auto;
}

.search {
  margin: 10px;
  text-align: center;
}

.page-title {
  width: 10%;
  display: flex;
  margin: 0 auto;
  text-align: center;
}

.page-title .members-title {
  padding-top: 2px;
}

table td {
  text-align: center;
}

table tr {
  text-align: center;
}

.edit-btn {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* Tooltip container */
.tooltip {
    margin-top: 3.3%;
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: grey;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  opacity: 0.8;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>