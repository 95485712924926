<template>
  <div class="content">
    <div :class="[modal_active ? 'modal is-active' : 'modal']">
      <div class="has-text-white has-background-black"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Errors</p>
          <button @click="resetModal" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <small style="color: #04ff00">
            Billing errors were detected, this is likely a configuration issue
            in Mediroutes.</small>
          <ul>
            <li v-for="data in modal_data" :key="data.id">
              {{ data.first_name }} {{ data.last_name }} | {{ data.dos }}
              {{ data.desc }}
            </li>
          </ul>
        </section>
        <footer class="modal-card-foot">
          <!-- <button class="button is-success">Save changes</button> -->
          <button @click="resetModal" class="button">Close</button>
        </footer>
      </div>
    </div>

    <h2 style="color: white">Billing</h2>
    <small v-if="fsloading" style="color: #0bfc03">Funding Sources are loading, please wait...</small>

    <div class="control-container">
      <div class="columns is-centered search">
        <div class="column is-4">
          <small>Select Funding Source</small>
          <select class="select custom-select" v-model="funding_source">
            <option :value="fs.funding_source_id" v-for="fs in funding_sources" :key="fs.id">
              {{ fs.name }}
            </option>
          </select>
        </div>

        <div class="column is-4">
          <small>Search Date Range</small>
          <Datepicker v-model="date" range :partialRange="false" :enableTimePicker="false" :maxRange="31"
            style="width: 275px" @keydown.enter="searchDates()" />
        </div>

        <div class="search-by-name column is-3">
          <small>Search By Name </small>
          <input type="text" class="input search-by-name" style="margin-top: 0" maxRange="25" placeholder="Search By Name"
            v-model="search_term" @keydown.enter="searchByName()" @focusout="searchByName()" />
        </div>
        <div class="tooltip column is-1">
          <span class="material-icons" style="color: grey">help</span>
          <span class="tooltiptext">Search by: First Name, Last Name, Phone, Pickup Street, Dropoff
            Street.
          </span>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="buttons columns">
        <div class="column is-narrow is-pulled-left" style="padding-left: 0">
          <button @click="searchDates()" class="button is-small is-info is-rounded">
            Search Dates
          </button>
        </div>
        <div class="column is-narrow is-pulled-left" style="padding-left: 0">
          <button @click="searchByName()" class="button is-small is-info is-rounded">
            Search Name
          </button>
        </div>

        <div class="column is-narrow is-pulled-left" style="padding-left: 0">
          <button @click="importclaims()" class="button is-small is-link is-rounded">
            Import claims
          </button>
        </div>

        <div class="column is-narrow is-pulled-left" style="padding-left: 0">
          <button @click="submitBilling()" class="button is-small is-success is-rounded">
            Bill Selected
          </button>
        </div>

        <div class="column is-6"></div>
      </div>
    </div>

    <!-- DATA TERMINAL -->

    <div class="stats" style="height: 20px; margin: 5px">
      <span v-if="checkbox_claim_id.length > 0">
        Selected: {{ checkbox_claim_id.length }}</span>
      <span style="margin-left: 20px" v-if="calculating">Calculating...</span>
      <span style="margin-left: 20px" v-else-if="checkbox_claim_id.length > 0 && !calculating">Total:
        <span style="color: #00ff11">${{ selection_total.toLocaleString() }}</span></span>
    </div>

    <div class="columns is-multiline">
      <div class="column is-7">
        <div class="table-container">
          <table class="table table-style is-fullwidth">
            <thead>
              <tr style="font-size: 0.8em">
                <th width="3%">
                  <input type="checkbox" @change="selectionTotal()" @click="selectAll" v-model="allSelected" />
                </th>
                <th width="1%">Claim ID</th>
                <th width="5%">Member Name</th>
                <th width="5%">Service Date</th>
                <th width="5%">Pickup Address</th>
                <th width="1%">Pickup Mod</th>
                <th width="5%">Dropoff Address</th>
                <th width="1%">Dropoff Mod</th>
                <th width="5%">Charge</th>
                <th width="5%">Status</th>
                <th width="5%">Services</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="claim in claims" :key="claim.id">
                <!-- CHECKBOX -->
                <td>
                  <input type="checkbox" :value="claim.id" v-model="checkbox_claim_id" @change="selectionTotal()"
                    @click="selectIndividualCheck()" />
                </td>

                <!-- CLAIM ID -->
                <td>{{ claim.id }}</td>

                <!-- NAME -->
                <td class="truncate">
                  
                  {{ claim.subscriber_last_name }}
                  {{ claim.subscriber_first_name }}
                </td>

                <!-- DATE OF SERVICE -->
                <td class="truncate">
                  {{ claim.encounter_start_date_of_service }}
                </td>

                <!-- PICKUP ADDRESS -->
                <td v-if="claim.ride_data" class="truncate">{{ claim.ride_data['pickup_street'] }} {{
                  claim.ride_data['pickup_city'] }}</td>
                <td v-else>*</td>
                <!-- PICKUP MOD -->
                <td v-if="claim.ride_data" class="truncate">
                  <select v-model="claim.selectedPickupMod"
                    @change="updateMod(claim.id, claim.selectedPickupMod, 'pickup')">
                    <option :value="claim.ride_data['pickup_modifier']" disabled>{{ claim.ride_data['pickup_modifier'] }}
                    </option>
                    <option v-for="(value, key) in lookup" :key="key" :value="value">{{ value }}</option>
                  </select>
                  {{ claim.ride_data['pickup_modifier'] }}
                </td>
                <td v-else>*</td>



                <!-- DROPOFF ADDRESS -->
                <td v-if="claim.ride_data" class="truncate">{{ claim.ride_data['dropoff_street'] }} {{
                  claim.ride_data['dropoff_city'] }}</td>
                <td v-else>*</td>
                <!-- DROPOFF MOD -->
                <td v-if="claim.ride_data" class="truncate">
                  <select v-model="claim.selectedDropoffMod"
                    @change="updateMod(claim.id, claim.selectedDropoffMod, 'dropoff')">
                    <option value="" disabled selected>{{ claim.ride_data['dropoff_modifier'] }}</option>
                    <option v-for="(value, key) in lookup" :key="key" :value="value">{{ value }}</option>
                  </select>
                  {{ claim.ride_data['dropoff_modifier'] }}
                </td>

                <td v-else>*</td>





                <!-- CHARGE AMOUNT -->
                <td>${{ claim.calculated_value }}</td>

                <!-- STATUS -->
                <td v-if="!claim.billed" class="truncate">Ready</td>
                <td v-else class="truncate">
                  <span style="font-weight: bold; color: #00ff11">Billed</span>
                </td>
                
                <!-- SERVICES BUTTON -->
                <td> <button class="button is-dark is-small" @click="getServices(claim.id)">Services</button></td>
              </tr>
            </tbody>
          </table>

          
        </div>


      </div>

      <!-- SERVICES EDITOR -->
      <div class="column is-5">
        <div class="table-container">
          <table class="table table-style is-fullwidth">
            <thead>
              <tr style="font-size: 0.8em">
                <th width="1%">Claim ID</th>
                <th width="5%">Code</th>
                <th width="5%">Code Class</th>
                <th width="5%">Units</th>
                <th width="5%">Rate</th>
                <th width="5%">Charge</th>
                <th width="5%">Modifier Set</th>
                <th width="5%">Location Mod</th>
                <th width="5%">&zwnj;</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="service in services" :key="service.id">

                <!-- CLAIM ID -->
                <td>{{ service.claim }}</td>

                <!-- CODE -->
                <td class="truncate">
                  {{ service.code }}
                </td>

                <!-- CODE CLASS -->
                <td class="truncate">
                  {{ service.code_class }}
                </td>

                <!-- UNITS -->
                <td class="truncate">
                  {{ service.units }}
                </td>

                 <!-- RATE -->
                 <td class="truncate">
                  {{ service.rate }}
                </td>


                <!-- CHARGE -->
                <td class="truncate">
                  {{ service.charge }}
                </td>

                <!-- MODIFIER SET -->
                <td class="truncate">
                  {{ service.modifier_set }}
                </td>

                <!-- LOCATION MOD -->
                <td v-if="service" class="truncate">
                  <input type="text" v-model="service.location_mod" @input="convertToUppercase" style="width: 50px; text-align: center;">
                </td>

                <td>
                  <button @click="updateServices(service.id, service.location_mod)">Update</button>
                </td>


              </tr>
            </tbody>
          </table>

          
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { CSRF_TOKEN } from "../../../common/csrf_token.js";
import { convertDates } from ".../../../common/converters.js";
import { generateToast } from ".../../../common/toast.js";

export default {
  components: { Datepicker },

  name: "Billing",
  data() {
    return {
      services : null,
      claims: [
        {
          selectedPickupMod: '',
          selectedDropoffMod: ''
        },
      ],
      lookup: {
        0: 'D',
        1: 'E',
        2: 'H',
        3: 'J',
        4: 'N',
        5: 'P',
        6: 'R'
      },
      modal_active: false,
      modal_data: [],
      claim_totals: [],
      fsloading: false,
      search_term: "",
      claims: [],
      rides: [],
      ride_data: [],
      company_meta: [],
      search_query: null,
      funding_sources: [],
      funding_source: null,
      rate_classes: "",
      date: "",
      busy: false,
      allSelected: false,
      checkbox_claim_id: [],
      selection_total: 0,
      calculating: false,
      count: 0,
      session_valid: null,
      headers: {
        headers: {
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: `Token ${this.$store.state.token}`,
        },
      },
    };
  },

  mounted() {
    this.getFundingSource();
    this.getCompanyMeta();
    this.getLatestClaims();
  },

  created() {
    document.title = "Bill";
  },

  methods: {

    convertToUppercase() {
      this.inputText = this.services.location_mod.toUpperCase();
    },

    async updateServices(service_id, location_mod) {
      // value is the mod letter, category is whether pickup or dropoff mod being changed
      this.$store.commit("setIsLoading", true)

      await axios
        .post("/api/v1/update-service/", { "service_id": service_id, "location_mod" : location_mod},this.headers)
        .then((response) => {
          console.log("X Response: ", response)
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });



    },


    async getServices(claim_id) {
      // value is the mod letter, category is whether pickup or dropoff mod being changed
      this.$store.commit("setIsLoading", true)

      await axios
        .post("/api/v1/get-billing-services-for-update/", { "claim_id": claim_id,},this.headers)
        .then((response) => {
          console.log("X Response: ", response)
          this.services = response.data.services
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });



    },

    async updateMod(claim_id, value, category) {
      // USED FOR UPDATING RIDE MODELS ONLY NOT SERVICES
      // value is the mod letter, category is whether pickup or dropoff mod being changed
      this.$store.commit("setIsLoading", true)

      await axios
        .post("/api/v1/save-updated-mod/", { "claim_id": claim_id, "value": value, "category": category }, this.headers)
        .then((response) => {
          console.log("X Response: ", response)
          this.$store.commit("setIsLoading", false)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });



    },

    //

    async getRideData() {
      this.$store.commit("setIsLoading", true);

      try {
        const response = await axios.post("/api/v1/get-ride-data/", { "claims": this.claims }, this.headers);
        console.log("X Response: ", response);

        this.rides = JSON.parse(response.data.data);

        // For each claim, check if there is a matching ride and add it under 'ride_data' key
        for (let i = 0; i < this.claims.length; i++) {
          const currentClaim = this.claims[i];

          for (let j = 0; j < this.rides.length; j++) {
            const currentRide = this.rides[j];

            if (currentClaim.id === currentRide.claim) {
              // Add ride_data key to the claim and assign the ride object
              currentClaim.ride_data = currentRide;
              break; // Break the inner loop once a match is found
            }
          }
        }

        console.log(this.claims);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        console.log(error);
        this.$store.commit("setIsLoading", false);
      }
    },

    //
    async qb_login() {
      // Get new access token for system
      this.$store.commit("setIsLoading", true)

      await axios
        .get("/api/v1/qb-auth-url/")
        .then((response) => {
          if (response.status == 200) {
            this.$store.commit("setIsLoading", false)
            window.open(response.data.message, "_blank");
            window.close()
          }

        })
        .catch((error) => {
          console.log(error)
          this.$store.commit("setIsLoading", false)
        });

    },



    async logout() {
      await axios
        .post("/api/v1/token/logout/")
        .then((response) => {
          console.log("Logged out");
          this.$store.commit("purgeNotifications");
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });

      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      this.$store.commit("removeToken");

      this.$router.push("/login");
    },
    //
    async session_validation() {
      // Get session ID from DB and match with the one saved in localStorage
      const session_id = sessionStorage.getItem("session_id");
      await axios
        .post(
          "/api/v1/validate-session/",
          { session_id: session_id },
          this.headers
        )
        .then((response) => {
          console.log("Session Validation: ", response);
          const session_valid = response.data.message;
          if (!session_valid) {
            this.session_valid = false;
            this.$store.state.session_valid = false;
            this.$router.push("/");
          } else {
            this.session_valid = true;
          }
        });
    },
    //
    assignClaimTotals() {
      // Uses calculations made in the backend to assign claim total values to existing claim objects
      for (var i = 0; i < this.claims.length; i++) {
        for (var j = 0; j < this.claim_totals.length; j++) {
          if (this.claims[i].id == this.claim_totals[j]["claim_id"]) {
            this.claims[i] = Object.assign(this.claims[i], {
              calculated_value: this.claim_totals[j]["charge"],
            });
          }
        }
      }
    },
    //
    async selectionTotal() {
      // Sends claim ID's to the backend and returns total selected claim value + individual claim totals
      if (this.checkbox_claim_id.length > 0) {
        this.$store.commit("setIsLoading", true);
        this.calculating = true;

        await axios
          .post(
            "/api/v1/calculate-claim-selection/",
            { checkbox_claim_id: this.checkbox_claim_id },
            this.headers
          )
          .then((response) => {
            console.log("Calculation Response: ", response);
            this.selection_total = response.data.message.grand_total;
            this.claim_totals = response.data.message.claim_totals;
            this.assignClaimTotals();
            this.$store.commit("setIsLoading", false);
            this.calculating = false;
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
            this.calculating = false;
          });
      }
    },

    //
    resetModal() {
      this.modal_data = [];
      this.modal_active = false;
    },
    //
    async getRides(claim_id) {
      if (this.session_valid) {
        this.$store.commit("setIsLoading", true);

        await axios
          .get(`/api/v1/claim-rides/?claim_id=${claim_id}`)
          .then((response) => {
            console.log("Rides Response: ", response);
            this.rides = response.data;
            this.$store.commit("setIsLoading", false);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setIsLoading", false);
          });
      }
    },
    //
    async getLatestClaims() {
      await axios
        .get("/api/v1/latest_claims/")
        .then((response) => {
          console.log("Latest Claims Response: ", response);
          this.claims = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //
    searchDates() {
      if (this.funding_source) {
        if (!this.busy) {
          this.busy = true;

          if (this.date && this.date.length == 2) {
            this.$store.commit("setIsLoading", true);

            let start_date = convertDates(this.date[0]);
            let end_date = convertDates(this.date[1]);

            let url = `/api/v1/claims-by-date/?min_date=${start_date}&max_date=${end_date}&funding_source_id=${this.funding_source}`;
            console.log(url);
            axios
              .get(url)
              .then((response) => {
                this.claims = response.data;
                this.getRideData()
                console.log(response.data);
                this.$store.commit("setIsLoading", false);
                this.busy = false;
              })
              .catch((error) => {
                console.log(error);
                this.$store.commit("setIsLoading", false);
                this.busy = false;
              });
          } else {
            generateToast("Please select dates.", "is-warning");
            this.$store.commit("setIsLoading", false);
          }
        } else {
          generateToast("System is busy, please wait...", "is-warning");
          this.$store.commit("setIsLoading", false);
        }
      } else {
        generateToast("Please select a funding source.", "is-warning");
        this.$store.commit("setIsLoading", false);
      }
    },
    //


    async searchByName() {
      if (this.funding_source) {
        if (!this.busy) {
          this.busy = true;
          this.$store.commit("setIsLoading", true);

          axios
            .get(
              `/api/v1/claims-by-name/?search=${this.search_term}&funding_source_id=${this.funding_source}`
            )
            .then((response) => {
              console.log("Search By Name Response: ", response);
              this.claims = response.data;
              this.$store.commit("setIsLoading", false);
              this.busy = false;
            })
            .catch((error) => {
              console.log(error);
              this.$store.commit("setIsLoading", false);
            });
        } else {
          generateToast("Please select a funding source.", "is-warning");
          this.$store.commit("setIsLoading", false);
          this.busy = false;
        }
      } else {
        generateToast("Please select a funding source.", "is-warning");
        this.$store.commit("setIsLoading", false);
        this.busy = false;
      }
    },

    //
    async importclaims() {
      let x = confirm("Are you sure you want to import claims?");
      if (x) {
        if (this.funding_source) {
          if (this.date.length > 1) {
            console.log("Funding source: " + this.funding_source);
            if (!this.busy) {
              this.busy = true;
              this.modal_data = []; // empty modal data for next import
              this.$store.commit("setIsLoading", true);
              if (this.date && this.funding_source) {
                var start_date = convertDates(this.date[0]);
                var end_date = convertDates(this.date[1]);

                let dates = { start_date: start_date, end_date: end_date };
                console.log(dates);
                generateToast(
                  "Please wait, this could take a while...",
                  "is-success"
                );
                axios
                  .post(
                    "/api/v1/import-rides/",
                    {
                      user_id: localStorage.getItem("userid"),
                      date_range: dates,
                      funding_source_id: this.funding_source,
                    },
                    this.headers
                  )
                  .then((response) => {
                    console.log("Import claims Response: ", response);

                    if (response.status == 201) {
                      generateToast(
                        "Successfully Imported claims",
                        "is-success"
                      );
                      this.$router.go();
                    } else {
                      generateToast(
                        `Mediroutes: ${response.data.message}`,
                        "is-danger"
                      );
                    }
                    this.busy = false;
                    this.$store.commit("setIsLoading", false);
                  })
                  .catch((error) => {
                    if (error.response.status == 418) {
                      console.log(error.response);
                      for (
                        var i = 0;
                        i < error.response.data.message.length;
                        i++
                      ) {
                        this.modal_data.push(error.response.data.message[i]);
                        this.modal_active = true;
                        this.busy = false;
                      }
                    } else {
                      generateToast(
                        `${error.response.status}: ${error.response.data.message}`,
                        "is-error"
                      );
                      console.log(error);
                      this.busy = false;
                    }
                    this.$store.commit("setIsLoading", false);
                  });
              }
            } else {
              generateToast(
                "System is busy processing claims... Please wait.",
                "is-warning"
              );
            }
          } else {
            generateToast("Please select a date range", "is-warning");
          }
        } else {
          generateToast("Please select a funding source.", "is-warning");
        }
      }
    },
    //
    async getFundingSource() {
      this.$store.commit("setIsLoading", true);
      this.fsloading = true;

      await axios
        .get("/api/v1/get-funding-sources/")
        .then((response) => {
          console.log("FS Response: ", response);
          this.funding_sources = response.data.content.Data;
          this.$store.commit("setIsLoading", false);
          this.fsloading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setIsLoading", false);
          this.fsloading = false;
        });
    },
    //
    async submitBilling() {
      await axios
        .get("/api/v1/verify-auth/")
        .then((response) => {
          let check = confirm(
            "Are you sure you want to bill the selected claims?"
          );
          if (check) {
            if (!this.busy) {
              this.$store.commit("setIsLoading", true);
              let billedclaimselected = false;

              for (var i = 0; i < this.claims.length; i++) {
                if (
                  this.checkbox_claim_id.includes(this.claims[i].id) &&
                  this.claims[i].billed == true
                ) {
                  billedclaimselected = true;
                }
              }
              if (billedclaimselected) {
                alert(
                  "You have selected a claim that is already billed, you cannot rebill an already billed claim."
                );
                this.$store.commit("setIsLoading", false);
                return false;
              }
              if (!billedclaimselected) {
                if (!this.busy) {
                  this.busy = true;

                  if (this.checkbox_claim_id.length != 0) {
                    axios
                      .post(
                        "/api/v1/submit-billing/",
                        this.checkbox_claim_id,
                        this.headers
                      )
                      .then((response) => {
                        if (response.status == 200) {
                          this.allSelected = false;
                          this.checkbox_claim_id = [];
                          generateToast("Success!", "is-success");
                          this.busy = false;
                          this.$store.commit("setIsLoading", false);
                          this.$router.push('/dashboard/bill/');
                        }
                      })
                      .catch((error) => {
                        if (error.response.status == 418) {
                          for (
                            var i = 0;
                            i < error.response.data.message.length;
                            i++
                          ) {
                            this.modal_data.push(
                              error.response.data.message[i]
                            );
                            this.modal_active = true;
                            this.busy = false;
                          }
                        } else {
                          generateToast(
                            `${error.response.status}: ${error.response.data.message}`,
                            "is-warning"
                          );
                          console.log(error);
                          this.busy = false;
                        }
                        this.$store.commit("setIsLoading", false);
                      });
                  } else {
                    generateToast(
                      "Please make a selection before continuing",
                      "is-warning"
                    );
                    this.busy = false;
                    this.$store.commit("setIsLoading", false);
                  }
                } else {
                  generateToast("System is busy... Please wait.", "is-warning");
                }
              }
            } else {
              generateToast("System is busy... Please wait.", "is-warning");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data.message);
          generateToast(error.response.data.message, "is-danger");
          this.$store.commit("setIsLoading", false);
          this.logout();
        });
    },
    //
    async getCompanyMeta() {
      axios
        .get("/api/v1/company_meta/")
        .then((response) => {
          this.company_meta = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });

      // this.$store.commit("setIsLoading", false);
    },
    //
    selectAll() {
      this.allSelected = !this.allSelected; // Set the opposite bool at start, but let default be false
      this.checkbox_claim_id = [];

      if (this.allSelected) {
        for (const r in this.claims) {
          var claim_id = this.claims[r].id;
          this.checkbox_claim_id.push(claim_id);
        }
      } else {
        this.checkbox_claim_id = [];
      }
    },

    //
    selectIndividualCheck() {
      this.allSelected = false;
    },
  },
  //
};
</script>

<style scoped>
.search-by-name {
  height: 38px;
  width: 280px;
}

.search {
  display: flex;
}

.content {
  width: 100%;
  top: 50px;
}

.table-container {
  width: 100%;
  height: 600px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 0.9em;
}

.select-control {
  height: 42px;
}

.custom-select {
  height: 38px;
  width: 280px;
  margin-right: 5px;
  border: 1px solid #ddd;
}

.wait-select {
  text-align: center;
  height: 20px;
  width: 50px;
  margin-right: 5px;
  border: 1px solid #ddd;
}

.really-small {
  font-size: 0.7em;
}

.update_or_skip {
  display: block;
  text-align: center;
  width: 180px;
  padding: 15px;
}

.rate-select {
  text-align: center;
  height: 20px;
  width: 70px;
  border: 1px solid #ddd;
}

.custom-control {
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
}

.table-style {
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
  overflow: auto;
  max-height: 500px;
  font-size: 0.8em;
}

table td {
  text-align: center;
}

table tr {
  text-align: center;
}

.edit-btn {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* Tooltip container */
.tooltip {
  padding-top: 30px;
  padding-left: 8px;
  position: relative;
  display: inline-block;
}

.tooltiptext {
  font-size: 0.7em;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: grey;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  opacity: 0.8;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  left: -80px;
  top: 60px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.review {
  padding: 8px;
  font-size: 0.9em;
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
}

.control-container {
  padding: 30px;
  /* From https://css.glass */
  background: rgba(103, 103, 103, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(103, 103, 103, 0.3);
}

.modal-card-head {
  background-color: black;
  height: 40px;
  padding-top: 40px;
  border: 1px solid blue;
  color: white;
  opacity: 0.9;
}

.modal-card-foot {
  background-color: black;
  opacity: 0.9;
  border: 1px solid blue;
  color: white;
}

.modal-card-title {
  color: white;
  opacity: 0.9;
}

.modal-card-body {
  opacity: 0.9;
  background-color: black;
  border: 1px solid blue;
  border-bottom: 1px solid black;
  color: white;
}

.modal {
  margin-top: 50px;
}

tr:hover td {
  background: blue;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
  /* or any other value based on your preference */
}
</style>